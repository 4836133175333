import styled, { css } from 'react-emotion';
import { spacings, brand, Text, Button } from '@tbh/ui-kit';
import { DEFAULT_COLORS } from '../../../../constants/themes';
import { FONT_SIZE_12, moreThan2minColor, lessThan2MinColor, FONT_SIZE_10 } from '../../../../store/application/applicationConstants';
import { coutryImageObject } from '../../Racing/CountryImage/CountryImage';

export const StyledNJRaceHeaderBar = styled('div')`
	${(props) => css`
		min-height: 65px; // To Match the height of a regular header bar
		border-radius: 4px 4px 0px 0px;
		position: relative;
		overflow: hidden;
		background-color: ${brand(props).primary_color ? brand(props).primary_color : DEFAULT_COLORS.df_primary_color};
		width: 100%;
	`};
`;

export const StyledNJRaceHeaderBar__Heading = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__Heading;

		padding: ${spacings(props).tight}px;
		width: 100%;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		align-items: center;

		font-size: ${FONT_SIZE_12};
	`};
`;

export const StyledRaceHeaderBar__Heading_raceNumber = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__Heading_raceNumber;

		flex: 0 0 10%;
		max-width: 10%;
	`};
`;

export const StyledRaceHeaderBar__Heading_flagIcon = styled('div')`
  ${(props) => css`
    label: RaceHeaderBar__Heading_flagIcon;

    // Flexbox container to align items vertically
    display: flex;
    flex-direction: column;
    justify-content: center; // Align items centrally vertically
    align-items: center; // Align items centrally horizontally

    flex: 0 0 10%;
    max-width: 10%;
    padding-left: 4px;

    div {
      margin-bottom: 4px; // Adds space between the RacingIcon and CountryImage
    }
  `};
`;


export const StyledRaceHeaderBar__Heading_More = styled('div')`
  ${(props) => css`
    label: RaceHeaderBar__Heading_More;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-left: 2px;
    min-height: 5px; 
  `};
`;

export const StyledNJRaceHeaderBar__Metting__Name = styled('div')`
  ${(props) => css`
    label: StyledNJRaceHeaderBar__Metting__Name;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${spacings(props).tight}px;
    padding-right: 50px; // Space for button
  `};
`;

export const StyledNJRaceHeaderBar__Metting__NameLoading = styled('div')`
	${(props) => css`
		label: StyledNJRaceHeaderBar__Metting__NameLoading;
		display: flex;
		margin-top: ${spacings(props).tight}px;
		width: ${props.width};
		height: ${props.height ? props.height : '10px'};
		animation: changeColor 2s infinite;
		margin-left: 5px;
	`};
`;

export const StyledNJRaceHeaderBar__Race__Details = styled('div')`
	${(props) => css`
		label: StyledNJRaceHeaderBar__Race__Details;
		display: flex;
	`};
`;
export const StyledNJRaceHeaderBar__Flage__Details = styled('div')`
	${(props) => css`
		label: StyledNJRaceHeaderBar__Flage__Details;
		display: flex;
		margin-top: ${spacings(props).tight}px;
	`};
`;
export const StyledNextToJumpCardItem__TimeToGoBUtton = styled(Button)(
	(props) => css`
		label: StyledNextToJumpCardItem__TimeToGoBUtton;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    font-size: ${FONT_SIZE_12};
    height: 25px;
    padding: 0 8px;
    background: ${props.lessThan2Min === 'less2Min' ? lessThan2MinColor : moreThan2minColor};
    border-color: ${props.lessThan2Min === 'less2Min' ? lessThan2MinColor : moreThan2minColor};
    color: #ffffff;
    font-weight: bold;
    border-radius: 3px !important;
    white-space: nowrap;

		${props.lessThan2Min === 'less2Min' &&
		css`
				&& {
					background-color: ${lessThan2MinColor};
					border: 1px solid ${lessThan2MinColor};
					padding: 0px 5px 0px 5px;
					border-radius: 3px;
				}
			`};
	`,
);

export const StyledNextToJumpCardItem__TimeToGoBUttonLoading = styled(Button)(
	(props) => css`
		  label: StyledNextToJumpCardItem__TimeToGoBUtton;
  
		  font-size: ${FONT_SIZE_12};
		  display: block;
		  margin-top: ${spacings(props).tight}px;
		  // background: ${brand(props).rch_4};
		  // border-color: ${brand(props).rch_4};
		  background: ${moreThan2minColor};
		  border: none!important;;
		  size:small;
		  top: 20px;
		  width:25px;
		  height:15px;
		  margin-top:-5px;
		  position: absolute;
		  color:#ffffff;
		  font-weight: bold;
		  animation: buttonLoading 2s infinite;
		  @keyframes buttonLoading {
				0% {
					background-color: rgba(55,155,	55, 0.2);
				}
				25% {
					background-color: rgba(55	,155,	55, 0.6);
				}
				50% {
					background-color: rgba(55	,155,	55, 0.8);
				}
				75% {
					background-color: rgba(55	,155,	55, 0.6);
				}
				100% {
					background-color: rgba(55	,155,	55, 0.4);
				}
			}
		  ${props.lessThan2Min === 'less2Min' &&
		css`
					&& {
						background-color: ${lessThan2MinColor};
						border: 1px solid ${lessThan2MinColor};
						padding: 0px 5px 0px 5px;
						border-radius: 3px;
					}
				`};
	  `,
);

/////////////////////////////////////////////////////////////

export const StyledCarouselItem__Image = styled(Image)(
	(props) => css`
		label: CarouselItem__Image;

		margin: 0 0 ${spacings(props).compact}px 0;
		pointer-events: none;
		max-width: none;

		${props.center &&
		css`
				margin-left: auto;
				margin-right: auto;
			`};
	`,
);

export const StyledCarouselItem__Label = styled(Text)(
	(props) => css`
		label: CarouselItem__Label;

		display: block;
		margin-bottom: ${spacings(props).tight}px;
	`,
);

export const StyledUpCarouselItem = styled(Text)(
	(props) => css`
		label: CarouselItem;

		display: block;
		cursor: pointer;
		width: 100%;
	`,
);

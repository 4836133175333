import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { typography, spacings } from '@tbh/ui-kit';

import {
	RACING_THOROUGHBRED_CODE,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
} from '../../../../../common/constants/Racing';
import RacingTypeFilterSelectionBlack from '../../../../../components/features/Racing/RacingTypeSelection/RacingTypeFilterSelectionBlack'; //@HW add new 12Dec2019
import { modeChangeOverSize } from '../../../../../pages/GRSNewRacing/NewRacingHome/NewRacingHomePage';

const StyledGRSRaceNextJumpFilter = styled('div')(
	(props) => css`
		label: GRSRaceNextJumpFilterV3;

		color: ${typography(props).color_3};

		list-style: none;
		width: fit-content !important;
		margin: 0px;

		${props.mode === 'horizontal'
			? css`
					// flex: 1 0 100%;
					display: flex;
					margin-left: ${spacings(props).tight}px;
			  `
			: css`
					display: block;
					// flex: 0 0 100%;
					// overflow: hidden;
					margin-bottom: 0px;
			  `};
	`,
);

const RaceNextJumpFilter = (props) => {
	const {
		t,
		className,
		onFilterItemClick,
		types,
		mode,
		mobile,
		size,
		resetFilterClick,
		showAllButton,
		setSelectedType,
		selectedType,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	const isActive = (types = [], type) => {
		if (mobile) {
			return type === selectedType;
		}

		if (types.length === 0) {
			return true;
		}

		return types.includes(type);
	};

	const handleSelectType = (type) => {
		if (size.width < modeChangeOverSize && !showAllButton) {
			if (setSelectedType) setSelectedType(type);
			onFilterItemClick(type, true);
			return;
		}
		onFilterItemClick(type);
	};

	useEffect(() => {
		if (mobile && !selectedType) {
			setSelectedType(RACING_THOROUGHBRED_CODE);
		}
	}, [mobile, selectedType]);

	return (
		<RacingTypeFilterSelectionBlack
			className={componentClasses}
			raceTypes={types}
			handleSelectType={handleSelectType}
			activeGallops={isActive(types, RACING_THOROUGHBRED_CODE)}
			activeGreyhounds={isActive(types, RACING_GREYHOUNDS_CODE)}
			activeHarness={isActive(types, RACING_HARNESS_CODE)}
			activeAll={
				isActive(types, RACING_THOROUGHBRED_CODE) &&
				isActive(types, RACING_GREYHOUNDS_CODE) &&
				isActive(types, RACING_HARNESS_CODE)
			}
			resetFilterClick={resetFilterClick}
			showAllButton={showAllButton}
			size={size}
			selectedType={selectedType}
		/>
	);
};

RaceNextJumpFilter.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Action to handle when a filter is clicked on */
	onFilterItemClick: PropTypes.func.isRequired,

	/** Which mode should be rendered */
	mode: PropTypes.oneOf(['horizontal', 'vertical']),

	/** List of different race types */
	raceTypes: PropTypes.array,

	types: PropTypes.array,

	/** Extra classes */
	className: PropTypes.string,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	resetFilterClick: PropTypes.func,

	showAllButton: PropTypes.bool,
};

RaceNextJumpFilter.defaultProps = {
	className: null,
	mode: 'horizontal',
	raceTypes: [],
	types: [],
	resetFilterClick: () => undefined,
	showAllButton: false,
};

export default withNamespaces()(RaceNextJumpFilter);

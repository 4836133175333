import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { withNamespaces } from 'react-i18next';
import { SizeMe } from 'react-sizeme';
// Containers
import { createErrorBoundary } from '../../../containers/ErrorBoundary/ErrorBoundaryActions';
import Route from '../../../components/controllers/Route/Route';
import * as WHPS from './WelcomePage.styled';
// Components
import { LoadingMask } from '@tbh/ui-kit';
// Action
import { buildRacingWelcomeRouteFromState } from '../../../routes/Welcome';
import { LoadingSpanner } from '../../../components/features/Application/LoadingSpinner/LoadingSpinner';
import DocumentTitle from '../../../components/controllers/Meta/DocumentTitle';
import DescriptionMeta from '../../../components/controllers/Meta/DescriptionMeta';
import { serverDateTime } from '../../../../src/legacy/core/format';
import { fetchActiveBets } from '../../../store/entities/actions/BetActions';
import {
	navigateToNextToJumpRaceWithType,
	navigateToGRSRacingHome,
} from '../../../store/GRSracingHome/GRSracingHomeActions';
import { routeTo } from '../../../store/application/applicationActions';
import BannersCarousel from '../../../components/features/Application/BannersCarousel/BannersCarousel';
import { trackTypeSelection } from '../../../store/GRSracingHome/GRSracingTrackingActions';
import { FEATURE_RACING_BASE_URL, FEATURE_RACING_SUB_APP } from '../../../pages/FeatureRacing/FeatureConstants';
import { RACING_NEW_BASE_URL } from '../../../pages/GRSNewRacing/RacingNewConstrants';
import { BETBUILDER_SUB_APP, BETBUILDER_BASE_URL } from '../../../pages/BetBuilder/BetBuilderConstants';
import { SPORTS_SUB_APP, SPORTS_BASE_URL } from '../../../pages/Sports/SportsConstants';
import { PROMOTIONS_BASE_URL, PROMOTIONS_SUB_APP } from '../../../routers/PromotionsRouterConstants';
import { navigateToRacingHome } from './../../../store/GRSracingHome/GRSracingHomeActions';
import { fetchAllSports } from '../../../store/entities/actions/SportsActions';
import { SliderComponentContainer } from '../../../components/features/Application/PageComponentContainer/SliderComponentContainer';
import { get } from '../../../common/Ajax';

class WelcomeHome extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,
		/** Function to mark that the container has stopped loading */
		//setContainerHasLoaded: PropTypes.func.isRequired,
		/** Current registration step */
		currentRoute: PropTypes.string,
		/** Show routing and meta components from the page */
		useRouting: PropTypes.bool,

		/** Loading mask to cover both meetings and races lists. */
		loadingContainer: PropTypes.bool,

		/** Brand name */
		brandName: PropTypes.string,

		/** Undefined for today's races. */
		selectedDate: PropTypes.instanceOf(moment),

		/** Watch for selected meeting to either render races list or meeting. */
		selectedMeeting: PropTypes.number,

		/** Watch for authenticatedUser to fetch their bets. */
		authenticatedUser: PropTypes.number,

		/** ac; data */
		acl: PropTypes.shape(),

		/** Is the app loaded on a phone type device */
		isPhoneDevice: PropTypes.bool,

		/** Attempt to fetch the logged in users active bets */
		fetchActiveBets: PropTypes.func.isRequired,

		/** Only render the compeition components */
		competitionOnly: PropTypes.bool,

		/** If the Next to Jump Races should be displayed or not */
		showNtjRaces: PropTypes.bool,

		sportItems: PropTypes.array,

		features: PropTypes.array,

		navigateToBetBuilder: PropTypes.func.isRequired,

		navigateToGRSRacingHome: PropTypes.func.isRequired,

		// navigateToGallops: PropTypes.func,

		// navigateToHarness: PropTypes.func,

		// navigateToGreyhounds: PropTypes.func,

		navigateToGRSFeature: PropTypes.func,

		navigateToSports: PropTypes.func,

		selectedPageKey: PropTypes.string.isRequired,

		upcomingRaces: PropTypes.array,

		navigateToTodayRacing: PropTypes.func,

		navigateToPromos: PropTypes.func,
	};

	static defaultProps = {
		brandName: '',
		currentRoute: null,
		useRouting: true,
		showNtjRaces: true,

		selectedDate: undefined,
		selectedMeeting: undefined,
		isPhoneDevice: false,
		acl: {},
		authenticatedUser: null,
		competitionOnly: false,
		sportItems: [],
		features: [],
		// navigateToGallops: () => undefined,
		// navigateToHarness: () => undefined,
		// navigateToGreyhounds: () => undefined,
		navigateToGRSFeature: () => undefined,
		navigateToSports: () => undefined,
		navigateToTodayRacing: () => undefined,
		upcomingRaces: [],
		navigateToPromos: () => undefined,
		loadingContainer: false,
	};

	constructor(props) {
		super(props);
		this.state = {
			loadingMask: false,
			isMobile: props.isPhoneDevice,
			showMe: true,
			banners: [], //
		};
	}

	async componentDidMount() {
		if (this.props.authenticatedUser) {
			this.props.fetchActiveBets();
		}

		// set timeout of 8 mim to call upcoming and nextToJump data
		setTimeout(() => {}, 8 * 60 * 1000);

		await this.getBanners();
	}

	componentDidUpdate(prevProps) {
		if (this.props.authenticatedUser && prevProps.authenticatedUser !== this.props.authenticatedUser) {
			this.props.fetchActiveBets();
		}
	}

	getBanners = async () => {
		this.setState({ loadingMask: true });
		try {
			const {
				data: { data },
			} = await get('/banners'); // Updated API endpoint
			this.setState({
				banners: [...data.map((banner) => ({ image: banner.imageurl, link: banner.clickurl }))],
			});
		} catch (error) {
			// Handle error as needed
		}

		this.setState({ loadingMask: false });
	};

	render() {
		const {
			t,
			currentRoute,
			useRouting,
			loadingContainer,
			brandName,
			showNtjRaces,
			featureRacesEnabled,
			taglines,
		} = this.props;
		const { isMobile, banners, loadingMask } = this.state;

		return (
			<div>
				<LoadingSpanner style={{ display: loadingContainer ? 'block' : 'none' }} />
				<SizeMe>
					{({ size }) => (
						<WHPS.StyledWelcomeLandingHomePage>
							{/* set page title and meta data*/}
							{useRouting && (
								<React.Fragment>
									<Route route={currentRoute} />
									<DocumentTitle>{`${t('WelcomePage_Title')}${
										brandName ? ` ${t('at')} ${brandName}` : ''
									}`}</DocumentTitle>
									<DescriptionMeta>
										{`${t('WelcomePage__Description')}${brandName ? ` ${t('with')} ${brandName}` : ''}. `}
									</DescriptionMeta>
								</React.Fragment>
							)}

							<SliderComponentContainer>
								{/* banner section */}
								<LoadingMask loading={loadingMask} />
								<BannersCarousel size={size} Items={banners} taglines={taglines} />
							</SliderComponentContainer>

							{showNtjRaces && (
								<WHPS.StyledWelcomePage__UpcomingRaces
									size={size}
									isRacingHome={false}
									hideRaceNextJumpFilter={Boolean(isMobile)}
								/>
							)}

							{featureRacesEnabled && <WHPS.StyledWelcomePage__FeatureRaces size={size} isRacingHome={false} />}
						</WHPS.StyledWelcomeLandingHomePage>
					)}
				</SizeMe>
			</div>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		taglines: state.acl.taglines,
		currentRoute: buildRacingWelcomeRouteFromState(state),
		isFullWidth: state.featureToggles.features.fullWidth.enabled,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
		showNtjRaces: state.featureToggles.features.ntjRaces.enabled,
		featureRacesEnabled: state.featureToggles.features.featuredEvents
			? state.featureToggles.features.featuredEvents.enabled
			: false,
		selectedPageKey: state.application.selectedPage,
		visibleSportWithName: state.application.visibleSportWithSportId,
		useRouting:
			typeof ownProps.useRouting === 'undefined'
				? state.featureToggles.features.urlRouting.enabled
				: ownProps.useRouting,
	};
};
/**
 * Map dispatch methods to props
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => {
	return {
		fetchActiveBets: () => dispatch(fetchActiveBets()),

		navigateToRaceWithType: (selectedType, selectedDate, raceId, meetingId) => {
			dispatch(navigateToNextToJumpRaceWithType(selectedType, selectedDate, raceId, meetingId));
		},

		navigateToGRSRacingHome: (selectedType, label, isRacingHome) => {
			dispatch(trackTypeSelection(label, isRacingHome));
			dispatch(navigateToGRSRacingHome(selectedType));
		},

		navigateToBetBuilder: (meetingId) => {
			dispatch(
				routeTo('/' + BETBUILDER_BASE_URL + '/' + serverDateTime(new Date()).format('YYYY-MM-DD') + '/' + meetingId),
			);
			App.startSubApp(BETBUILDER_SUB_APP);
		},
		fetchSports: () => dispatch(fetchAllSports()),
		navigateToTodayRacing: () => {
			dispatch(routeTo('/' + RACING_NEW_BASE_URL));
			dispatch(navigateToRacingHome(new Date()));
		},

		navigateToGRSFeature: () => {
			dispatch(routeTo('/' + FEATURE_RACING_BASE_URL));
			App.startSubApp(FEATURE_RACING_SUB_APP);
		},

		navigateToSports: () => {
			dispatch(routeTo('/' + SPORTS_BASE_URL));
			App.startSubApp(SPORTS_SUB_APP);
		},

		navigateToPromos: () => {
			dispatch(routeTo('/' + PROMOTIONS_BASE_URL));
			App.startSubApp(PROMOTIONS_SUB_APP);
		},
	};
};

export default withNamespaces()(createErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(WelcomeHome)));

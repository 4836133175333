import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PusherSubscriberAuth from '../../../controllers/Pusher/PusherSubscriberAuth';
import PusherSubscribe from '../../../controllers/Pusher/PusherSubscribe';

class PusherSubscriberPresenceUpdate extends Component {
	render() {
		const { user } = this.props;

		if (!user) return null;

		// Determine channel for all subscriptions
		const channel = 'presence-updates';

		return (
			<Fragment>
				<PusherSubscribe channel={channel} event={'UpdateCreated'} />
			</Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.application.authenticatedUser,
	// entities: state.entities
});

const mapDispatchToProps = (dispatch) => ({
	// updateBetStatus: (id, status, enWhattities) => updateBetStatus(id, status, entities)
});

export default connect(mapStateToProps, mapDispatchToProps)(PusherSubscriberPresenceUpdate);

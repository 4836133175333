import styled, { css } from 'react-emotion';
import { brand, typography } from '@tbh/ui-kit';
import { COLORS, WEIGHTS, TYPE_SCALE } from '../../../constants/themes';

export const Card = styled.div`
  border-radius: 4px;
  display: block;
  box-shadow: 2px 4px 12px hsl(0deg 0% 0% / 0.1);
  margin-bottom: 8px; // Reduced from default
`;

export const CardHeader = styled('div')(
  (props) => css`
    background-color: ${brand(props).tertiary_color ? brand(props).tertiary_color : '#161A1D'};
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px; // Reduced vertical padding
    min-height: 48px; // Ensure consistent header height
  `,
);

export const CardBody = styled.div`
  background-color: ${COLORS.white};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  text-align: left;
  padding: 8px; // Reduced from 12px
  
  // Add gap control for direct children
  > * + * {
    margin-top: 8px;
  }
`;

export const CardTitle = styled('h4')(
  (props) => css`
    color: ${COLORS.white};
    font-weight: ${WEIGHTS.bold};
    font-size: ${TYPE_SCALE.header4};
    font-family: ${typography(props).base_font};
    text-transform: uppercase;
    margin: 0; // Remove default margin
    line-height: 1.2; // Tighter line height
  `,
);

// New styled components for optimized spacing

export const CardGrid = styled.div`
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr;
  
  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const RaceListItem = styled.div`
  padding: 8px;
  border-bottom: 1px solid ${COLORS.border};
  
  &:last-child {
    border-bottom: none;
  }
  
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RaceInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const RaceTime = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: ${COLORS.textSecondary};
  font-size: ${TYPE_SCALE.body2};
`;
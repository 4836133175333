import React from 'react';
import { connect } from 'react-redux';
import { routeTo } from '../../store/application/applicationActions';
import styled, { css } from 'react-emotion';
import { GiHamburgerMenu } from 'react-icons/gi';
import { COLORS, TYPE_SCALE, WEIGHTS } from '../../constants/themes';
import { LEGAL_BASE_URL } from '../../pages/LegalPage/LegalConstants';
import { typography } from '@tbh/ui-kit';
// import { getRule } from '../../store/legalRules/legalRulesAction';

const ToggleMenuWrapper = styled('div')`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const ToggleMenuButton = styled('button')`
	display: inline-block;
	width: 100%;
	text-align: center;
	color: ${COLORS.gray[500]};
	padding: 8px 16px;
	border: 1px solid #dfdfdf;
	border-bottom: 2px solid #dfdfdf;
	border-radius: 8px;
	background-color: ${COLORS.white};

	&:hover {
		color: ${COLORS.white};
		border: 1px solid ${COLORS.gray[300]};
		border-bottom: 2px solid ${COLORS.gray[300]};
		background-color: ${COLORS.gray[400]};
	}
`;

const ToggleMenuContent = styled('ul')`
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	list-style: none;
	width: 100%;
	padding: 16px;
	border: 1px solid #dfdfdf;
	background-color: ${COLORS.white};
	border-radius: 8px;

`;

const ToggleMenuContentItem = styled('li')`
	padding-top: 10px;
	padding-bottom: 10px;
`;

const ToggleMenuLink = styled('button')(
	(props) => css`
		display: block;
		width: 100%;
		text-align: center;
		color: ${props.selected ? COLORS.red[300] : COLORS.gray[600]};
		font-weight: ${WEIGHTS.medium};
		font-size: ${TYPE_SCALE.header5};
		font-family: ${typography(props).base_font};
		border: 0;
		background: none;

		&:hover {
			color: ${COLORS.red[300]} !important;
		}
	`
);

class SiteLinkMenuToggle extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isToggleMenu: true,
			selectedRule: null,
		};

		this.handleChangeToggle = this.handleChangeToggle.bind(this);
		this.handleGoToRule = this.handleGoToRule.bind(this);
	}

	handleGoToRule(rule) {
		this.setState({ selectedRule: rule });
		this.props.goToRule(rule);
	}

	handleChangeToggle() {
		this.setState((prevState) => ({
			isToggleMenu: !prevState.isToggleMenu,
		}));
	}

	render() {
		const { isToggleMenu, selectedRule } = this.state;
		const { selectedPage, legalRules } = this.props;
		return (
			<ToggleMenuWrapper>
				<ToggleMenuButton onClick={this.handleChangeToggle}>
					<GiHamburgerMenu size={22} />
				</ToggleMenuButton>
				{isToggleMenu && (
					
					<ToggleMenuContent>
						{legalRules.map((rule) => (
							<ToggleMenuContentItem key={rule.alias}>
								<ToggleMenuLink
									selected={selectedRule === rule.alias}
									onClick={() => this.handleGoToRule(rule.alias)}
								>
									{rule.title}
								</ToggleMenuLink>
							</ToggleMenuContentItem>
						))}
					</ToggleMenuContent>
				)}
			</ToggleMenuWrapper>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		goToRule: (rule) => {
			dispatch(routeTo('/' + LEGAL_BASE_URL + '/' + rule));
			//console.log('Dispatching route change for rule:', rule);
			// dispatch(getRule(rule))
		},
	};
};

export default connect(null, mapDispatchToProps)(SiteLinkMenuToggle);

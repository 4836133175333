import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  PUSHER_EVENT_UPDATE,
  PUSHER_EVENT_ODDS_UPDATE,
  PUSHER_EVENT_DERIVATIVE_ODDS_UPDATE,
  PUSHER_EVENT_DERIVATIVE_MARKET_UPDATE,
  PUSHER_TOP_SELECTION_UPDATE,
  PUSHER_EVENT_HOT_SELECTION_UPDATE,
  PUSHER_SELECTION_UPDATE
} from "../../../../common/constants/Racing";
import {
  updatePrices,
  buildPriceUpdates,
  buildPriceUpdateliability
} from "../../../../store/entities/actions/PriceActions";
import {
  mergeRace,
  mergeDerivativeSelection,
  mergeSelection
} from "../../../../store/entities/actions/RaceActions";

import PusherSubscribe from "../../../controllers/Pusher/PusherSubscribe";

/**
 * Pusher Subscriber Container for Race.
 * Channel: race_<raceId>
 */

class PusherSubscriberRaceChannel extends Component {
  static propTypes = {
    /** The race ID to update */
    raceId: PropTypes.number.isRequired,

    /** Action to update the selected race */
    updateRace: PropTypes.func,

    /** Action to update the prices in the race */
    updatePrices: PropTypes.func,

    /** Action to update the prices for a derivative selection */
    updateDerivativeSelection: PropTypes.func
  };

  static defaultProps = {
    updateRace: () => {},
    updatePrices: () => {},
    updateDerivativeSelection: () => {}
  };

  /**
   * Handles pushed data to update race
   * @param data
   */
  handlePusherRaceUpdate = data => {
    this.props.updateRace(data);
  };

  /**
   * Handles pushed data to update odds.
   * @param data
   */
  handlePusherOddsUpdate = data => {
    /**
     * Separate Price entities to be updated,
     * otherwise Race can lose selections and selections can lose price.
     */
    const prices = buildPriceUpdates(data.selections);
    for (const selection of data.selections) {
      let newSelection = {};
      for (const key in selection) {
        if (key == "selection_fluctuations" || key == "selection_status") {
          if (selection[key] && selection[key].length > 0) {
            newSelection[key] = selection[key];
          }
        } else {
          newSelection[key] = selection[key];
        }
      }

      newSelection.prices =
        typeof selection.prices === "object"
          ? [selection.prices]
          : selection.prices;
      this.props.updateSelection(newSelection);
    }
    this.props.updatePrices(prices);
  };

  /**
   * Handles pushed data to update odds.
   * @param data
   */
  handlePusherOddsGridUpdate = data => {
    /**
     * Separate Price entities to be updated,
     * otherwise Race can lose selections and selections can lose price.
     */
    const prices = buildPriceUpdateliability(data.selections);
    for (const selection in data.selections) {
      let newSelection = {};
      for (const key in selection) {
        if (key == "selection_fluctuations" || key == "selection_status") {
          if (selection[key] && selection[key].length > 0) {
            newSelection[key] = selection[key];
          }
        } else {
          newSelection[key] = selection[key];
        }
      }
      this.props.updateSelection(newSelection);
    }

    this.props.updatePrices(prices);
  };
  /**
   * Handles pushed hot selection id data to update.
   * @param data
   * @add by @HW 25June2020
   */
  handlePusherTopSelectionsOddsUpdate = data => {
    this.props.updateRace(data);
  };

  /**
   * Handles pushed data to update the price for a single derivative selection.
   * @param data
   */
  handlePusherDerivativeOddsUpdate = data => {
    this.props.updateDerivativeSelection(data.derivative_selection);
  };

  /**
   * Handles pushed data to update derivative markets.
   * @param data
   */
  handlePusherDerivativeMarketUpdate = data => {
    const race = {
      id: this.props.raceId,
      derivative_markets: data.derivative_markets,
      derivatives_enabled: data.derivatives_enabled
    };
    this.props.updateRace(race);
  };

  handleHotSelectionsUpdate = data => {
    this.props.updateRace(data);
  };

  handleSelectionUpdate = data => {
    const selection = {};
    for (const key in data.selection) {
      if (key == "selection_fluctuations" || key == "selection_status") {
        if (data.selection[key] && data.selection[key].length > 0) {
          selection[key] = data.selection[key];
        }
      } else {
        selection[key] = data.selection[key];
      }
    }

    this.props.updateSelection(selection);
  };

  render() {
    const { raceId } = this.props;

    // Determine channel for all subscriptions
    const channel = `race_${raceId}`;
    return (
      <Fragment>
        <PusherSubscribe
          key={PUSHER_EVENT_UPDATE}
          channel={channel}
          event={PUSHER_EVENT_UPDATE}
          onUpdate={this.handlePusherRaceUpdate}
        />
        <PusherSubscribe
          key={PUSHER_EVENT_HOT_SELECTION_UPDATE}
          channel={channel}
          event={PUSHER_EVENT_HOT_SELECTION_UPDATE}
          onUpdate={this.handleHotSelectionsUpdate}
        />
        <PusherSubscribe
          key={PUSHER_EVENT_ODDS_UPDATE}
          channel={channel}
          event={PUSHER_EVENT_ODDS_UPDATE}
          onUpdate={this.handlePusherOddsUpdate}
        />
        {/* hot selection update */}
        <PusherSubscribe
          key={PUSHER_TOP_SELECTION_UPDATE}
          channel={channel}
          event={PUSHER_TOP_SELECTION_UPDATE}
          onUpdate={this.handlePusherTopSelectionsOddsUpdate}
        />
        <PusherSubscribe
          key={PUSHER_SELECTION_UPDATE}
          channel={channel}
          event={PUSHER_SELECTION_UPDATE}
          onUpdate={this.handleSelectionUpdate}
        />
        {/*<PusherSubscribe
					key={PUSHER_EVENT_DERIVATIVE_MARKET_UPDATE}
					channel={channel}
					event={PUSHER_EVENT_DERIVATIVE_MARKET_UPDATE}
					onUpdate={this.handlePusherDerivativeMarketUpdate}
				/>
				<PusherSubscribe
					key={PUSHER_EVENT_DERIVATIVE_ODDS_UPDATE}
					channel={channel}
					event={PUSHER_EVENT_DERIVATIVE_ODDS_UPDATE}
					onUpdate={this.handlePusherDerivativeOddsUpdate}
				/>*/}
      </Fragment>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  updateRace: race => dispatch(mergeRace(race)),
  updatePrices: prices => dispatch(updatePrices(prices)),
  updateSelection: selection => dispatch(mergeSelection(selection)),
  updateDerivativeSelection: selection =>
    dispatch(mergeDerivativeSelection(selection))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PusherSubscriberRaceChannel);

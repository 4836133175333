import React from 'react';
import PropTypes from 'prop-types';

// Centralized flag mapping
const FLAG_URLS = {
	AU: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/AUS.svg',
	NZ: 'https://grsresource.s3.ap-southeast-2.amazonaws.com/site-resources/images/NZ.png',
	UK: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/GB.svg',
	HK: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/HK.svg',
	FR: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/FR.svg',
	SGP: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SGP.svg',
	SG: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SGP.svg',
	JPN: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/JPN.svg',
	JP: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/JPN.svg',
	US: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/USA.svg',
	SAF: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SAF.svg',
	ZA: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/SAF.svg',
	KR: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/KOR.svg',
	MYS: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/MYS.svg',
	MY: 'https://resource3.s3-ap-southeast-2.amazonaws.com/img/flags/MYS.svg'
};

// Country name mapping
const COUNTRY_NAMES = {
	AU: 'Australia',
	NZ: 'New Zealand',
	UK: 'United Kingdom',
	HK: 'Hong Kong',
	FR: 'France',
	SGP: 'Singapore',
	SG: 'Singapore',
	JPN: 'Japan',
	JP: 'Japan',
	US: 'United States',
	SAF: 'South Africa',
	ZA: 'South Africa',
	KR: 'South Korea',
	MYS: 'Malaysia',
	MY: 'Malaysia'
};

// Code normalization mapping
const CODE_ALIASES = {
	AUS: 'AU',
	NZL: 'NZ',
	GBR: 'UK',
	GB: 'UK',
	HKG: 'HK',
	FRA: 'FR',
	SGP: 'SGP',
	SG: 'SGP',
	JPN: 'JP',
	USA: 'US',
	ZAF: 'ZA',
	SAF: 'ZA',
	KOR: 'KR',
	MYS: 'MY'
};

const CountryImage = ({ country, className }) => {
	// Normalize country code
	const normalizedCode = CODE_ALIASES[country] || country;

	// Error tracking
	const [hasError, setHasError] = React.useState(false);

	if (!country || !FLAG_URLS[normalizedCode]) {
		console.warn(`Invalid country code provided: ${country}`);
		return null;
	}

	if (hasError) {
		console.warn(`Failed to load flag for country: ${country}`);
		return null;
	}

	return (
		<img
			src={FLAG_URLS[normalizedCode]}
			className={className}
			alt={COUNTRY_NAMES[normalizedCode] || normalizedCode}
			onError={() => setHasError(true)}
			data-testid={`flag-${normalizedCode.toLowerCase()}`}
		/>
	);
};

CountryImage.propTypes = {
	country: PropTypes.string.isRequired,
	className: PropTypes.string
};

CountryImage.defaultProps = {
	className: ''// empty string as default value
};
// For backwards compatibility
export const countryImageObject = FLAG_URLS;

// Export utilities for testing
export const utils = {
	FLAG_URLS,
	COUNTRY_NAMES,
	CODE_ALIASES
};

export default CountryImage;

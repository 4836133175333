import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { media, spacings, brand, Button, Checkbox, HeaderBar, Icon, PlotElements, Text } from '@tbh/ui-kit';
import {
    RACING_THOROUGHBRED_CODE,
    RACING_GREYHOUNDS_CODE,
    RACING_HARNESS_CODE,
    RACING_TYPES_LOOKUP,
} from '../../../../common/constants/Racing';
import BetBulderRaceCardContainer from '../../../../containers/Racing/BetBulderRaceCardContainer/BetBulderRaceCardContainer';
import BetBuilderRaceCardFilter from '../RaceBetBuilder/BetBuilderRaceCardFilter/BetBuilderRaceCardFilter';
//import {betBuilderMeetingSelectionsList, betBuilderSelectionsCount, betBuilderMeetingSelectionsCount} from '../../../../store/entities/selectors/MeetingSelectors';
import BetBuilderCarousel from '../../Carousel/BetBuilderCarousel';
import BetBuilderCarouselItem from '../BetBuilderCarouselItem/BetBuilderCarouselItem';

import { betBuilderMeetingSelectionsList, betBuilderMeetingSelectionsCount } from '../../../../pages/BetBuilder/BetBuilderHome/BetBuilderSelectors';

import { countdown } from '../../../../legacy/core/format';
import { FONT_SIZE_10, FONT_SIZE_12, FONT_SIZE_15 } from '../../../../store/application/applicationConstants';
import CountryImage from '../CountryImage/CountryImage';

// Size at which the mode should change over
const modeChangeOverSize = 751;

const StyledRaceBetBuilder__Carousel = styled(BetBuilderCarousel)(
    (props) => css`
		label: RaceBetBuilder__Carousel;
		flex: 1;
		height: auto;
		overflow: hidden;
	`,
    (props) =>
        media(
            css`

			`,
            modeChangeOverSize,
        ),
);

const StyledRaceBetBuilder__CarouselItem = styled(BetBuilderCarouselItem)(
    (props) => css`
		label: RaceBetBuilder__CarouselItem;
		min-height:100px;
		width: auto;
		box-sizing: border-box;
		padding-top:8px;
        border-radius:8px 8px 0px 0px;
        background-color: ${brand(props).color_2_dark} !important;
	`,
);

const StyledBetBuilderRaceItemWrapper = styled('div')(
    (props) => css`
        label: BetBuilderRaceItemWrapper;

        margin-left: 3px;
        position: relative;

        display: flex;
        flex-direction: column;
        // flex: 1 0 0;
        height: auto;
        border-radius: 1px;
		box-sizing: border-box;
		padding: ${spacings(props).tight}px;
        max-width: 235px;
		`,
    media(
        (props) =>
            css`
				display: flex;

				flex-direction: column;
				// flex: 1 0 0;
				height: auto;
				border-radius: 1px;
				box-sizing: border-box;
                max-width: 245px;
			`,
        767,
    )


);

const StyledRaceBetBuilder = styled('div')(
    (props) => css` 
    label: RaceBetBuilder;

	display: flex;
    display: none;
    margin-top: 16px;

    ${props.show &&
        css`
            display: block;
            display: flex;
        `};
    `,
);

const StyledBetBuilderRaceItemWrapper_body_content = styled('div')(
    (props) => css`
	label: BetBuilderRaceItemWrapper_body_content;

	color:#2E2E2E;
	background-color: #f7f8f8;
	max-width:320px;
	height: auto;
    border-radius: 0px 0px 8px 8px;
    
	`,
);

const StyledBetbuilder_RaceCardContainer = styled(BetBulderRaceCardContainer)(
    (props) => css`
	 label: Betbuilder_RaceCardContainer;
	`
);


const StyledBetBuilder__Content = styled('div')`
    ${(props) =>
        css`
        label: BetBuilder__Content ;
   
        display: flex;
        // flex-flow: row wrap;
        flex-direction: column;
        padding: ${spacings(props).compact}px;
        justify-content: center;
        align-items: center;
        width: 100%;

        // background-image:
        // linear-gradient(to bottom, rgba(129, 129, 129, 0.52), rgba(210, 210, 210, 0.73)),
        // url('https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/a-california-racetrack-is-shutting-down-indefinit-2-32590-1551897611-0_dblbig.jpg');
        // background-size: cover;
        
        `};

    ${media(
            (props) =>
                css`
                display: flex;
                // flex-flow: row;
            `,
            767,
        )};    

`;

const StyleRaceBetBuilderContent_Left = styled('div')`
        ${(props) =>
        css`
                label: RaceBetBuilderContent_Left;

              
                width: 100%;
                box-sizing: border-box;
                -webkit-tap-highlight-color: transparent;
                background-color: #f7f8f8;

                box-shadow: 0px 2px 8px rgb(0 0 0 / 18%);
                transition: all 0.3s ease 0s;
                border-radius: 8px;
            `};

        ${media(
            (props) =>
                css`
                 width: 100%;
                 background-color: #f7f8f8;

                 box-shadow: 0px 2px 8px rgb(0 0 0 / 18%);
                 transition: all 0.3s ease 0s;
                `,
            767,
        )};
   
`;

const StyleRaceBetBuilderContent_Right = styled('div')`
        ${(props) =>
        css`
                label: RaceBetBuilderContent_Right;

                
                max-width:100%;
                box-sizing: border-box;

            `};

        ${media(
            (props) =>
                css`
                    width:100%;
                    
                    //padding: ${spacings(props).compact}px;
                `,
            767,
        )};
   
`;

const StyledRaceCard__BetBuilder__Card__Content__Marks__Lable = styled('div')`
      label: StyledRaceCard__BetBuilder__Card__Content__Marks__Lable;
      position: relative;
      font-size:65px;
      text-transform: uppercase;
      font-weight: bold;
      text-align: center;
`;
const StyledRaceCard__BetBuilder__Meeter = styled('div')`
    ${(props) =>
        css`
        label: StyledRaceCard__BetBuilder__Meeter;

            background-image:url('https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/ban-909970_1280.png');
            
            background-size: 100%;
            background-repeat: no-repeat;
            width: 240px;
            height: 240px;
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            ${props.showfilteredData &&
            css`
                    display:none;
                `};

        `};
`;

const StyledRaceCard__BetBuilder__Card__Content__Lable = styled('div')`
      label: StyledRaceCard__BetBuilder__Card__Content__Lable;
      position: relative;
      font-size:15px;
      text-transform: uppercase;
      font-weight: bold;
`;

const StyledRaceCard__BetBuilder__Card__Content__Button = styled('div')`
        label: StyledRaceCard__BetBuilder__Card__Content__Button;
        position: relative;

`;

const StyledShowHide__Button = styled(Button)`
    ${(props) =>
        css`
        label: show_hide_conent_buton ;
   
        display: block;
        max-height: 32px;
        
        `};

    ${media(
            (props) =>
                css`
                display: none;
            `,
            767,
        )};    

`;

const StyledBetbuilderMeetingHeaderBar = styled('div')`
	${(props) => css`
		label: BetbuilderMeetingHeaderBar;

        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 ${spacings(props).compact}px;
		//padding-top: ${spacings(props).tight}px;
        //padding-bottom: ${spacings(props).tight}px;
	`};
`;

const StyledRaceHeaderBar = styled(HeaderBar)`
${(props) => css`
    label: RaceHeaderBar;
    display: none;
	min-height: 75px; // To Match the height of a regular header bar
    background: ${brand(props).rch_5};
    padding: ${spacings(props).compact}px;
    border-radius: 8px 8px 0 0;
`};
`;


const styleFlag = css`
	width:25px;
	height:12px;
 	margin-right:8px;
`;

const StyledMeetingHeaderBar_WeatherAndStatus = styled(PlotElements)`
    // flex: 0 0 65px;
    // margin-top:-15px;

	${media(
    css`
			// flex: 0 0 145px;
		`,
    375,
)};
`;

const StyledRaceHeaderBar__WeatherAndTrack = styled('div')`
	${(props) => css`
		label: RaceHeaderBar__WeatherAndTrack;

		display: none;
		margin-left: ${spacings(props).tight}px;
	`};

	${media(
    css`
			display: block;
			max-width: 180px;
		`,
    375,
)};
`;

const StyledRaceHeaderBar__WeatherAndTrack_Item = styled(Text)`
	${(props) => css`
		label: RaceHeaderBar__WeatherAndTrack_Item;

		white-space: nowrap;
		padding-top: ${spacings(props).tight}px;
	`};
`;

const StyledRaceHeaderBar__WeatherAndTrack_ItemLabel = styled(Text)`
	${(props) => css`
		label: RaceHeaderBar__WeatherAndTrack_ItemLabel;

		display: inline-block;
		width: 15px;
		min-width: 15px;
		text-align: right;
		margin-right: ${spacings(props).tight}px;
	`};
`;


const StyledMeetingNextRace_bar = styled('div')`
	${(props) => css`
		label: MeetingNextRace_bar;

		background-color: ${brand(props).rch_4};
		display: flex;

		padding: ${spacings(props).compact}px;
		width: 100%;

		//text-align: center;
	`};
`;
const StyledMeetingNextRace_bar__RaceName = styled(Text)`
    display: none;
    font-size: ${FONT_SIZE_12};

	${media(
    css`
			display: block;
		`,
    500,
    { sizeKey: 'mediaSize' },
)};
`;
const StyledMeetingNextTime_div = styled('div')`
     display: flex;
     align-items: center;
     justify-content: space-between;
`;

const StyledFilters = styled.div`
        ${(props) => css`
            label: StyledFilters;

            display: flex;
            flex-direction: column;
            padding: ${spacings(props).compact}px;
            border-radius: 8px;
        `}`;


const StyledBBuilderMeetingHeaderBar___TimeToGoBUtton = styled(Button)(
    (props) => css`
          label: BBuilderMeetingHeaderBar___TimeToGoBUtton;
          border-radius: 4px !important;
          font-size: ${FONT_SIZE_10};
          display: block;
          margin-top: 0px;
          background: ${brand(props).rch_4};
          border-color: ${brand(props).rch_4};
          size:small;
          width:auto;
          height:24px;
         
         
        //   position: absolute;
          color:#ffffff;
          font-weight: bold;
          padding: 0px 8px;
  
         
          
      `,
);
class RaceBetBuilder extends React.Component {
    static propTypes = {
        /** Extra class(es) to pass through to the component */
        className: PropTypes.string,

        /** An betbuilder meeting */
        betBuilderMeeting: PropTypes.shape({
            /** The id of the meeting */
            id: PropTypes.number.isRequired,

            /** The name of the meeting */
            name: PropTypes.string.isRequired,

            /** The type of race ie. Thoroughbred, Harness, Greyhounds */
            type: PropTypes.oneOf([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]).isRequired,

            /** The starting time of the meeting */
            start_date: PropTypes.string.isRequired,

            state: PropTypes.string.isRequired,

            country: PropTypes.string.isRequired,

            status: PropTypes.string,

            races: PropTypes.arrayOf(
                PropTypes.shape({
                    /** The id of the race */
                    id: PropTypes.number.isRequired,

                    /** The name of the race */
                    name: PropTypes.string.isRequired,

                    /** The race number */
                    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

                    /** Meeting name */
                    meeting_name: PropTypes.string.isRequired,

                    /** Meeting id of this race */
                    meeting_id: PropTypes.number.isRequired,

                    /** bet builder race products object */
                    products: PropTypes.array.isRequired,

                    /** Array of objects that contain all the selection details */
                    selections: PropTypes.arrayOf(
                        PropTypes.shape({
                            /**
                             * Selections will contain all the selection details but besides 'id' they are not needed to be defined here.
                             * They are defined in the RaceSelectionsListItem
                             */
                            id: PropTypes.number.isRequired,

                            name: PropTypes.string.isRequired,

                            /** The list of prices for the selection */
                            prices: PropTypes.arrayOf(
                                PropTypes.shape({
                                    id: PropTypes.number, //@HW
                                    /** The current price for a win bet with this product */
                                    win_odds: PropTypes.number,

                                    /** The current price for a place bet */
                                    place_odds: PropTypes.number,

                                    /** The current price for a margin bet */
                                    margin_odds: PropTypes.number,

                                    /** The code for this product with this product */
                                    product_code: PropTypes.string.isRequired,

                                    /** The product id (NOTE: this is not a unique id between products) */
                                    product_id: PropTypes.number.isRequired,
                                })
                            ),
                        }),
                    ).isRequired,
                }),
            ),

        }).isRequired,

        /** The size of the component - used for media query logic */
        size: PropTypes.shape({
            width: PropTypes.number,
        }).isRequired,

        /** Action to track when a filter is applied */
        trackOnFilterClick: PropTypes.func,

        // meetingId: PropTypes.number.isRequired,
        meetingId: PropTypes.number,

        selectedBetBuilderMeeting: PropTypes.shape({
            /** The id of the meeting */
            id: PropTypes.number.isRequired,

            /** The name of the meeting */
            name: PropTypes.string.isRequired,
        }).isRequired,

        /** Navigate to the race page */
        onGoToRace: PropTypes.func,
    };

    static defaultProps = {
        className: null,
        trackOnFilterClick: () => undefined,
        onGoToRace: undefined,
        meetingId: null,
    };

    constructor(props) {
        super(props);

        this.state = {
            // initially set all the type filters active
            typeFilter: new Set([]),
            showfilteredData: false,
            showAll: false,
        };
    }


    showBetBuilderResult = () => {
        this.setState({
            showfilteredData: true,
        });
    };

    hideBetBuilderResult = () => {
        this.setState({
            showfilteredData: false,
        });
    };

    showAllRaces = () => {
        this.setState({
            showAll: !this.state.showAll,
        });
    };

    /**
   * The onClick on the Carousel item returns the race id passed in.
   * Find that id in the list of items then call the onClick handler
   *
   * @param id
   */
    handleClick = (id) => {
        let item = this.props.betBuilderMeeting.races.find((item) => item.id === id);
        this.props.onGoToRace(item.type, item.start_date, item.id, item.meeting_id);

    };
    /**
     * Handles the clicking/selecting of the type filters
     * @param type
     */
    handleOnFilterClick = (type) => {
        this.saveTypeToFilters(type);
        this.props.trackOnFilterClick(type);
    };

    /**
     * Saves the type filters to state
     * @param type
     */
    saveTypeToFilters = (type) => {
        let types = new Set(this.state.typeFilter);

        if (!type) {
            return;
        }

        if (types.has(type)) {
            types.delete(type);
        } else {
            types.add(type);
        }

        this.setState({
            typeFilter: types,
            showfilteredData: false,
        });
    };


    render() {
        const {
            className,
            size,
            betBuilderMeeting,
            meetingId,
            selectedBetBuilderMeeting,
        } = this.props;
        const componentClassesV3 = cx({
            [className]: className,
        });
        const { showfilteredData, showAll } = this.state;

        const componentClasses = cx({
            [className]: className,
        });

        //console.log(meetingId)
        const items = betBuilderMeetingSelectionsList(betBuilderMeeting, selectedBetBuilderMeeting, Array.from(this.state.typeFilter), showAll);

        const betBuilderSelectionCount = betBuilderMeetingSelectionsCount(meetingId, betBuilderMeeting, selectedBetBuilderMeeting, Array.from(this.state.typeFilter), showAll);

        return (

            <StyledBetBuilder__Content size={size}>

                <StyleRaceBetBuilderContent_Left size={size}>
                    {/* meeting header bar */}
                    {betBuilderMeeting && <StyledRaceHeaderBar
                        fluid
                        noPadding
                        className={componentClasses}
                        type="primary"
                        nodeLeft={
                            <div
                            // className={css`margin-top: -15px;`}
                            >
                                {/* <StyledMeetingHeaderBar__RaceNumber>{raceNumber}</StyledMeetingHeaderBar__RaceNumber> */}

                                <div>
                                    <Icon
                                        // className={css`margin-left: 5px;`}
                                        key="1"
                                        size="-2"
                                        icon={RACING_TYPES_LOOKUP[betBuilderMeeting.type]}
                                        push="1" />
                                </div>
                                <div >
                                    <CountryImage
                                        country={betBuilderMeeting.country}
                                        className={css`width: 25px; height: 25px;`}
                                    />
                                </div>
                            </div>
                        }
                        margin={false}>
                        <StyledBetbuilderMeetingHeaderBar>

                            <div
                                className={css`
                                display: flex; 
                                flex-direction: column;
                                gap:5px;
                                `}
                            >
                                <Text
                                    textOverflow="ellipsis"
                                    strong
                                    className={css`
                                        display: inline-block;
                                        white-space: nowrap;
                                       
                                                    `}
                                    type="alternate"
                                    size="-2"

                                >
                                    {`${betBuilderMeeting.name}`}
                                    <span className={css`font-size:${FONT_SIZE_12};padding-right:7px; padding-left:7px;font-weight: normal;`}>{`(${betBuilderMeeting.country})`}</span>

                                </Text>
                                <StyledMeetingNextTime_div>
                                    {betBuilderMeeting && betBuilderMeeting.next_race_url !== null && (
                                        <StyledMeetingNextRace_bar__RaceName mediaSize={size} textOverflow="ellipsis" size="-2" type="alternate">
                                            {'Next Race : '} {betBuilderMeeting && betBuilderMeeting.next_race_url !== null && betBuilderMeeting.next_race_number != 0 &&
                                                <a href={betBuilderMeeting.next_race_url} className={css`color:#fff; text-decoration: none; & :hover { color:#fff; text-decoration: none; }`}>{betBuilderMeeting.next_race_number}</a>
                                            }
                                        </StyledMeetingNextRace_bar__RaceName>
                                    )}

                                    {betBuilderMeeting.next_race_date !== null && betBuilderMeeting.next_race_date !== 'Invalid date' ? (
                                        <StyledMeetingNextTime_div mediaSize={size} >

                                            {/* {'Time'} | {serverDateTime(moment(betBuilderMeeting.next_race_date)).format(HOUR_MIN_AMPM)} */}
                                            <StyledBBuilderMeetingHeaderBar___TimeToGoBUtton>
                                                {`${countdown(betBuilderMeeting.next_race_date)}`}
                                            </StyledBBuilderMeetingHeaderBar___TimeToGoBUtton>
                                        </StyledMeetingNextTime_div>
                                    ) : (
                                        (<StyledMeetingNextTime_div mediaSize={size} >
                                            {/* {'Time'} | <span className={css`color:#fff;`}>{('Closed')} </span> */}
                                            <StyledBBuilderMeetingHeaderBar___TimeToGoBUtton>
                                                {'closed'}
                                            </StyledBBuilderMeetingHeaderBar___TimeToGoBUtton>
                                        </StyledMeetingNextTime_div>)
                                    )}
                                </StyledMeetingNextTime_div>
                            </div>


                            <StyledMeetingHeaderBar_WeatherAndStatus size={size} align="end" alignElements="middle" >
                                <StyledRaceHeaderBar__WeatherAndTrack size={size}>
                                    <StyledRaceHeaderBar__WeatherAndTrack_Item size="-3" ellipsis type="alternate">
                                        <StyledRaceHeaderBar__WeatherAndTrack_ItemLabel size="-3" tag="span" type="alternate">
                                            {betBuilderMeeting.weather && 'W'}{betBuilderMeeting.weather && ':'}
                                        </StyledRaceHeaderBar__WeatherAndTrack_ItemLabel>
                                        {betBuilderMeeting.weather}
                                        <span className={css`padding-left:3px;`} />
                                        {betBuilderMeeting.weather !== null ? (betBuilderMeeting.weather.indexOf('MOSTLY SUNNY') === 0 ? (
                                            <img src="https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/sun.png" alt="Sun"
                                                className={css`width:15px;height:15px; margin-left:3px;`} />) : betBuilderMeeting.weather.indexOf('CLOUDS') === 0 ? (
                                                    <img src="https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/cloud.png"
                                                        alt="Cloud"
                                                        className={css`width:15px;height:15px; margin-left:3px;`} />) : betBuilderMeeting.weather.indexOf('SNOW') === 0 ? (
                                                            <img src="https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/snow.png"
                                                                alt="Snow" className={css`width:15px;height:15px; margin-left:3px;`} />) : betBuilderMeeting.weather.indexOf('RAIN') === 0 ?
                                            (<img src="https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/rain.png"
                                                alt="Rain" className={css`width:15px;height:15px; margin-left:3px;`} />) : betBuilderMeeting.weather.indexOf('CLEAR') === 0 ?
                                                (<img src="https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/cloud.png"
                                                    alt="Cloud" className={css`width:15px;height:15px; margin-left:3px;`} />) : '') : ''}
                                        {/* {betBuilderMeeting.weather == null ? null : betBuilderMeeting.weather.indexOf('MOSTLY SUNNY')  === -1 ? (<img src="https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/sun.png" alt="Sun" className={css`width:15px;height:15px; margin-left:3px;`}/>) : betBuilderMeeting.weather.indexOf('CLOUDY') !== -1 ? (<img src="https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/cloud.png" alt="Cloud" className={css`width:15px;height:15px; margin-left:3px;`}/>) : betBuilderMeeting.weather.indexOf('SHOWERS') !== -1 ? (<img src="https://grsresource.s3-ap-southeast-2.amazonaws.com/site-resources/images/snow.png" alt="Cloud" className={css`width:15px;height:15px; margin-left:3px;`}/>) : ''} */}
                                    </StyledRaceHeaderBar__WeatherAndTrack_Item>

                                    <StyledRaceHeaderBar__WeatherAndTrack_Item size="-3" ellipsis type="alternate">
                                        <StyledRaceHeaderBar__WeatherAndTrack_ItemLabel size="-3" tag="span" type="alternate">
                                            {betBuilderMeeting.track && 'T'} {betBuilderMeeting.track && ':'}
                                        </StyledRaceHeaderBar__WeatherAndTrack_ItemLabel>
                                        {betBuilderMeeting.track}
                                    </StyledRaceHeaderBar__WeatherAndTrack_Item>

                                    {betBuilderMeeting.rail_position !== null ?
                                        <StyledRaceHeaderBar__WeatherAndTrack_Item size="-4" ellipsis type="alternate">
                                            <StyledRaceHeaderBar__WeatherAndTrack_ItemLabel size="-4" tag="span" type="alternate">
                                                {'R/P'}
                                            </StyledRaceHeaderBar__WeatherAndTrack_ItemLabel>
                                            {betBuilderMeeting.rail_position}
                                        </StyledRaceHeaderBar__WeatherAndTrack_Item>
                                        : null}

                                </StyledRaceHeaderBar__WeatherAndTrack>

                            </StyledMeetingHeaderBar_WeatherAndStatus>
                        </StyledBetbuilderMeetingHeaderBar>

                    </StyledRaceHeaderBar>}


                    <StyledFilters>


                        <div className={css`display:flex; width: 100%; justify-content: space-between;padding-top: 10px;`}>
                            <div className={css`felx: 0 0 50%; max-width: 50%;`}>
                                <h5 className={css`color:#005395;text-align: left; font-weight: bold;font-size:${FONT_SIZE_15}; `}>Meeting Filter</h5></div>

                            <div className={css`felx: 0 0 50%; max-width: 50%;float: right;  margin-right: 10px; font-size:${FONT_SIZE_15};`}>

                                <Checkbox
                                    name="showAllRaces"
                                    value="showAllRaces"
                                    action={this.showAllRaces}
                                    checked={showAll}
                                    pushRight="1"
                                    inline
                                    secondary
                                /> <b>Count all races</b>
                            </div>
                        </div>


                        <BetBuilderRaceCardFilter
                            mode={size.width > modeChangeOverSize ? 'vertical' : 'horizontal'}
                            onFilterItemClick={this.handleOnFilterClick}
                            types={Array.from(this.state.typeFilter)}
                            size={size}
                        />


                        <StyledShowHide__Button size={'medium'} type="danger" className="btn btn-danger btn-circle btn-sm" action={showfilteredData == true ? this.hideBetBuilderResult : this.showBetBuilderResult}>
                            {showfilteredData && showfilteredData == true ? (
                                <i className="fa fa-arrow-circle-down" aria-hidden="true"></i>
                            ) : (
                                <i className="fa fa-arrow-circle-up" aria-hidden="true"></i>
                            )}

                        </StyledShowHide__Button>
                    </StyledFilters>
                </StyleRaceBetBuilderContent_Left>

                <StyleRaceBetBuilderContent_Right size={size}>


                    <StyledRaceCard__BetBuilder__Meeter size={size} showfilteredData={showfilteredData}>

                        <StyledRaceCard__BetBuilder__Card__Content__Marks__Lable size={size}>
                            <span className={css`color:#000;`}>{betBuilderSelectionCount}</span>
                        </StyledRaceCard__BetBuilder__Card__Content__Marks__Lable>
                        <StyledRaceCard__BetBuilder__Card__Content__Lable size={size}>
                            <span className={css`color:#000;`}>{'matching results'}</span>
                        </StyledRaceCard__BetBuilder__Card__Content__Lable>

                        <StyledRaceCard__BetBuilder__Card__Content__Button>
                            <Button
                                type="primary"
                                action={this.showBetBuilderResult}
                                className={css`font-size:10px;padding: 10px;text-align: center;text-decoration: none;`}
                                size={'medium'}
                            >
                                <span>{'SHOW SELECTIONS'}</span>
                            </Button>
                        </StyledRaceCard__BetBuilder__Card__Content__Button>

                    </StyledRaceCard__BetBuilder__Meeter>

                    <StyledRaceBetBuilder className={componentClassesV3} show={showfilteredData} size={size}>

                        {/* filtered data is there then showing relevent data */}
                        {items.length > 0 && (
                            <StyledRaceBetBuilder__Carousel size={size} nextButton prevButton freeMode buttonStyle="1">
                                {items.map((race) => (
                                    <StyledBetBuilderRaceItemWrapper key={race.id} size={size}>
                                        {/* bet  builder header */}
                                        <StyledRaceBetBuilder__CarouselItem
                                            className={css`min-height: 0; padding: 0;`}
                                            id={race.id}
                                            raceNumber={race.number}
                                            type={race.type}
                                            meetingName={race.meeting_name}
                                            startTime={race.start_date}
                                            meetingState={race.state}
                                            raceName={race.name}
                                            raceDistance={race.distance}
                                            start_datetime={race.start_date}
                                            size={size}
                                            action={this.handleClick}
                                            status={race.status}
                                        />


                                        <StyledBetBuilderRaceItemWrapper_body_content>
                                            <StyledBetbuilder_RaceCardContainer
                                                id={race.id}
                                                race={race}
                                                raceId={race.id}
                                                meetingId={race.meeting_id}
                                                meetingName={race.meeting_name}
                                                selections={race.selections}
                                            />
                                        </StyledBetBuilderRaceItemWrapper_body_content>
                                    </StyledBetBuilderRaceItemWrapper>

                                ),
                                )}
                            </StyledRaceBetBuilder__Carousel>
                        )
                        }
                    </StyledRaceBetBuilder>

                </StyleRaceBetBuilderContent_Right>
            </StyledBetBuilder__Content>
        );
    }
}

export default RaceBetBuilder;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { Text } from '@tbh/ui-kit';

import { spacings, brand, mediaMax, ui, typography } from '@tbh/ui-kit';

import Format from '../../../../legacy/core/format';

import { navigateToNextToJumpRaceWithType } from '../../../../store/GRSracingHome/GRSracingHomeActions';
import {
	fetchTransactions,
	fetchBonusTransactions,
	fetchStatistics,
} from '../../../../store/entities/actions/UserActions';
import { cancelWithdrawal } from '../../../../store/entities/actions/WithdrawalActions';
import { fetchActiveBets } from '../../../../store/entities/actions/BetActions';
import { isBetReferred } from '../../../Betting/bettingMemoizedSelectors';
import { navigate, routeTo } from '../../../../store/application/applicationActions';
import { CacheOut } from '../../../../store/CacheOut/CacheOutAction';
import { fetchSportsByCompetitionId } from '../../../../store/entities/actions/SportsActions';
import { fetchCombinedMarketsGroupsWithSelections } from '../../../../store/entities/actions/MarketActions';
import {
	formatAndAddSportSelectionToBetPrompt,
	formatAndAddSingleSelectionToBetPrompt,
	formatAndAddExoticSelectionToBetPrompt,
	formatAndAddDerivativeSelectionToBetPrompt,
	updateBetPromptDetails,
	resetBetPromptTitle,
} from '../../../../store/betPrompt/betPromptActions';
import { fetchMeetingsAndRacesWithSelectionsForRace } from '../../../../store/entities/actions/MeetingActions';
import { trackGaEvent } from '../../../../store/trackingPixels/trackingActions';
//import { buildRouteToRace } from '../../../../routes/Racing';
import { buildWithDateGRSRouteToRace } from '../../../../routes/RacingNew'; // add racing-v3 route
import { buildSportRouteForObject } from '../../../../routes/Sports';
import { buildRouteToTournament } from '../../../../routes/Tournaments';
import { triggerEventMessage } from '../../../../common/actions/widgetActions';
import * as userTransactionsDataTableActions from '../../../../store/dataPaginator/userTransactionsDataPaginatorActions';

import { DATA_PAGINATOR_USER_TRANSACTIONS } from '../../../../store/dataPaginator/dataPaginatorReducerNames';
import { TABLET_MAX_WIDTH } from '../../../../common/constants/Breakpoints';
import {
	BET_TYPE_DERIVATIVE,
	DEFAULT_BET_PROMPT_TITLE,
	PRODUCT_TYPE_BOOST,
} from '../../../../store/entities/constants/BetConstants';
import {
	USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS,
	USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
	USER_ACCOUNT_ALL_TRANSACTIONS_URL,
	USER_ACCOUNT_BETS_TRANSACTIONS_URL,
	USER_ACCOUNT_TOURNAMENTS_TRANSACTIONS_URL,
	USER_ACCOUNT_BET_WINNING_TRANSACTIONS_URL,
	USER_ACCOUNT_BET_LOSING_TRANSACTIONS_URL,
	USER_ACCOUNT_BET_REFUNDED_TRANSACTIONS_URL,
	USER_ACCOUNT_DEPOSITS_TRANSACTIONS_URL,
	USER_ACCOUNT_WITHDRAWALS_TRANSACTIONS_URL,
	// USER_ACCOUNT_TODAY_TRANSACTIONS_URL,
	USER_ACCOUNT_OPEN_UNTIL_TODAY_TRANSACTIONS_URL,
} from '../../../../pages/UserAccount/UserAccountPageConstants';
import { RACING_BET_TYPE_MARGIN, RACING_BET_TYPE_WIN } from '../../../../common/constants/Racing';

import DepositContainer from '../../../Deposit/DepositContainer/DepositContainer';
import BetPromptContainer from '../../../Betting/BetPromptContainer/BetPromptContainer';
import DataTableContainer from '../../../Generic/DataTableContainer/DataTableContainer';

import { Notification, Select, SelectOption, StatisticGroup, HeaderBar, Tabs, TabsItem } from '@tbh/ui-kit';
import AccountTransactions from '../../../../components/features/UserAccount/AccountTransactions';
import Modal from '../../../../components/controllers/Modal/Modal';
import ModalHeader from '../../../../components/features/Application/ModalHeader/ModalHeader';
import ModalFooter from '../../../../components/features/Application/ModalFooter/ModalFooter';
import BrandContactPhone from '../../../../components/ui-components/BrandContactPhone/BrandContactPhone';

// Context
// import AclContext from '../../../../contexts/AclContext';
import { serverDateTime } from '../../../../legacy/core/format';
import { createErrorBoundary } from '../../../ErrorBoundary/ErrorBoundaryActions';
import {
	USER_ACCOUNT_BONUS_BET_TRANSACTION_URL,
	USER_ACCOUNT_TRANSACTIONS_URL,
} from '../../../../pages/UserAccountV2/UserAccountPageConstants';
import { StyledSectionTitle } from '../Components';
import { FAMILYS, DEFAULT_COLORS } from '../../../../constants/themes';
import { openBetExport } from '../../../../store/betExport/betExportAction';
import TransactionsDetails from '../../../../components/features/UserAccount/AccountTransactions/TransactionsDetails';
import moment from 'moment';

const StyledUserTransactionsContainer = styled('div')(
	(props) => css`
		label: UserTransactionsContainer;

		flex: auto;
		padding-bottom: ${spacings(props).cozy}px;
		padding: 0px ${spacings(props).cozy}px;
	`,
);

const StyledUserTransactionsContainer__HeaderAccount = styled(HeaderBar)(
	(props) => css`
		label: UserTransactionsContainer__HeaderAccount;

		margin-bottom: ${spacings(props).cozy}px;
		font-size: 15px;
	`,
	mediaMax(
		css`
			display: none;
		`,
		TABLET_MAX_WIDTH,
	),
);

const StyledUserTransactionsContainer__HeaderTransactions = styled(HeaderBar)(
	(props) => css`
		label: UserTransactionsContainer__HeaderTransactions;

		margin-bottom: ${spacings(props).cozy}px;
		font-size: 15px;
	`,
	mediaMax(
		css`
			display: none;
		`,
		TABLET_MAX_WIDTH,
	),
);

const StyledUserTransactionsContainer__ErrorContainer = styled(Notification)(
	(props) => css`
		label: UserTransactionsContainer__ErrorContainer;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledUserTransactionsContainer__FiltersDropdown = styled(Select)(
	(props) => css`
		label: UserTransactionsContainer__FiltersDropdown;

		margin-bottom: ${spacings(props).cozy}px;
		padding: 0 ${spacings(props).compact}px;
		width: 100%;
		font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary};
	`,
	(props) =>
		mediaMax(
			css`
				margin: ${spacings(props).cozy}px;
			`,
			TABLET_MAX_WIDTH,
			{ sizeKey: 'mediaSize' },
		),
);

const StyledUserTransactionsContainer__FiltersTabs = styled(Tabs)(
	(props) => css`
		label: UserTransactionsContainer__FiltersTabs;

		margin-bottom: ${spacings(props).cozy}px;
		align-items: baseline;
		transition: 0.3s;
		& > div:hover {
			background-color: #850000;
			color: white;
		}
	`,
	(props) =>
		mediaMax(
			css`
				margin: ${spacings(props).cozy}px;
				align-items: baseline;
			`,
			TABLET_MAX_WIDTH,
		),
);

const StyledUserTransactionsContainer__StatisticsGroup = styled(StatisticGroup)(
	(props) => css`
		label: UserTransactionsContainer__StatisticsGroup;
		padding: ${spacings(props).cozy}px 0;
		// margin-bottom: 16px;
		max-width: 100%;
		font-size: 13px;

		border: 1px solid ${ui(props).color_4};
		border-top: none;
		border-radius: 4px;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		text-align: center;

		& > div {
			padding: 0;
		}

		& > div > :first-child {
			display: none;
		}

		& > div > :last-child {
			padding: 0;
		}
		@media (min-width: 968px) {
			margin-bottom: 10px;
		}
	`,
	// mediaMax(
	// 	css`
	// 		display: none;
	// 	`,
	// 	TABLET_MAX_WIDTH,
	// ),
);

const FilterSelection_Wrapper = styled('div')(
	(props) => css`
		display: flex;
		align-items: center;
		justify-content: flex-end;
	`,
);

const FilterSelection_SelectMenu = styled(SelectOption)(
	(props) => css`
		padding: 10px 22px !important;
		border: 1px solid;
		font-family: ${typography(props).base_font ? typography(props).base_font : FAMILYS.primary} !important;

		&:active {
			background-color: red;
		}
	`,
);

const StyledStaticHeader = styled('div')(
	(props) => css`
		label: UserTransactionsContainer__StaticHeader;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		padding: 24px;
		height: 30px;
		color: #ffffff;
		font-weight: 600;
		background-color: ${brand(props).tertiary_color ? brand(props).tertiary_color : DEFAULT_COLORS.df_tertiary_color};
		border-radius: 4px 4px 0 0;
		margin-top: ${spacings(props).cozy}px;
	`,
);

const OverflowTableView = styled('div')`
	overflow: auto;
`;

class UserTransactionsContainer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** The size of the parent component - used for media query logic */
		parentSize: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** Set the data for the data table */
		setDataTable: PropTypes.func.isRequired,

		/** Set the loading state on the data table */
		setLoading: PropTypes.func.isRequired,

		/** The data set we are loading */
		transactionType: PropTypes.oneOf([
			USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS,
			USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
		]).isRequired,

		/** Action to fetch the active bets for the user */
		fetchActiveBets: PropTypes.func.isRequired,

		/** Action to fetch the user statistics*/
		fetchStatistics: PropTypes.func.isRequired,

		/** Action to fetch the account transactions */
		fetchTransactions: PropTypes.func.isRequired,

		/** Action to fetch the bonus transactions */
		fetchBonusTransactions: PropTypes.func.isRequired,

		/** Action to update the route based on the transactions filter */
		setTransactionFilter: PropTypes.func.isRequired,

		/** Actions all necessary for adding a bet to the betslip */
		resetBetPromptTitle: PropTypes.func.isRequired,
		updateBetPromptDetails: PropTypes.func.isRequired,
		fetchMeetingsAndRacesWithSelectionsForRace: PropTypes.func.isRequired,
		fetchSportsByCompetitionId: PropTypes.func.isRequired,
		fetchCombinedMarketsGroupsWithSelections: PropTypes.func.isRequired,
		formatAndAddSportSelectionToBetPrompt: PropTypes.func.isRequired,
		formatAndAddSingleSelectionToBetPrompt: PropTypes.func.isRequired,
		formatAndAddExoticSelectionToBetPrompt: PropTypes.func.isRequired,
		formatAndAddDerivativeSelectionToBetPrompt: PropTypes.func.isRequired,

		/** Navigate to the selected page */
		navigate: PropTypes.func.isRequired,

		/** List of custom filters to use in account transactions */
		accountTransactionFilters: PropTypes.array,

		/** Whether any bets are currently referred, for the rebet */
		betReferred: PropTypes.bool,

		/** Context name for prefixing custom events to be fired through middleware */
		eventContext: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),

		/** Current transaction filter */
		filter: PropTypes.string,

		/** Don't load and hide the account statistics */
		hideStatistics: PropTypes.bool,

		/** Action to track GA Events */
		trackGaEvent: PropTypes.func,

		/** Bet prompt data */
		betPrompt: PropTypes.shape({
			/** Bet prompt title, for the modal */
			title: PropTypes.string,
		}),

		/** Extra classes */
		className: PropTypes.string,

		/** If the Deposits and Withdrawals feature is enabled or not */
		isDepositsAndWithdrawalsEnabled: PropTypes.bool,

		/** If the Tournaments feature is enabled or not */
		isTournamentsEnabled: PropTypes.bool,

		/** Display the header */
		showHeader: PropTypes.bool,

		navigateToRaceWithType: PropTypes.func.isRequired,
	};

	static defaultProps = {
		eventContext: '',
		filter: USER_ACCOUNT_ALL_TRANSACTIONS_URL,
		hideStatistics: false,
		trackGaEvent: () => undefined,
		betPrompt: {
			title: DEFAULT_BET_PROMPT_TITLE,
		},
		betReferred: false,
		className: '',
		isDepositsAndWithdrawalsEnabled: true,
		isTournamentsEnabled: true,
		showHeader: true,
		accountTransactionFilters: null,
	};

	constructor(props) {
		super(props);
		this.loadData = this.loadData.bind(this);
		this.selectRef = React.createRef();

		// Our default account transaction filters
		const defaultFilters = [
			{
				id: USER_ACCOUNT_BETS_TRANSACTIONS_URL,
				title: this.props.t('Bets'),
				longTitle: this.props.t('UserTransactionsContainer__FilterBets'),
				trackingTitle: 'Bets',
				container: {
					[USER_ACCOUNT_TRANSACTIONS_URL]: true,
					[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS]: true,
				},
			},
			{
				id: USER_ACCOUNT_OPEN_UNTIL_TODAY_TRANSACTIONS_URL,
				title: this.props.t(' Open Bets '),
				longTitle: this.props.t('UserTransactionsContainer__FilterOpenBets'),
				trackingTitle: 'Open Bets',
				container: {
					[USER_ACCOUNT_TRANSACTIONS_URL]: true,
					[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS]: false,
				},
			},
			{
				id: USER_ACCOUNT_BET_WINNING_TRANSACTIONS_URL,
				title: this.props.t('UserTransactionsContainer__FilterWinning'),
				longTitle: this.props.t('UserTransactionsContainer__FilterWinning'),
				trackingTitle: 'Winning Bets',
				container: {
					[USER_ACCOUNT_TRANSACTIONS_URL]: true,
					[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS]: false,
				},
			},
			{
				id: USER_ACCOUNT_BET_LOSING_TRANSACTIONS_URL,
				title: this.props.t('UserTransactionsContainer__FilterLosing'),
				longTitle: this.props.t('UserTransactionsContainer__FilterLosing'),
				trackingTitle: 'Losing Bets',
				container: {
					[USER_ACCOUNT_TRANSACTIONS_URL]: true,
					[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS]: true,
				},
			},
			{
				id: USER_ACCOUNT_BET_REFUNDED_TRANSACTIONS_URL,
				title: this.props.t('UserTransactionsContainer__FilterRefunded'),
				longTitle: this.props.t('UserTransactionsContainer__FilterRefunded'),
				trackingTitle: 'Refunded Bets',
				container: {
					[USER_ACCOUNT_TRANSACTIONS_URL]: true,
					[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS]: true,
				},
			},
			{
				id: USER_ACCOUNT_DEPOSITS_TRANSACTIONS_URL,
				title: this.props.t('Deposits'),
				longTitle: this.props.t('Deposits'),
				trackingTitle: 'Deposits',
				externalFilter: true,
				container: {
					[USER_ACCOUNT_TRANSACTIONS_URL]: true,
					[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS]: false,
				},
			},
			{
				id: USER_ACCOUNT_WITHDRAWALS_TRANSACTIONS_URL,
				title: this.props.t('Withdrawals'),
				longTitle: this.props.t('Withdrawals'),
				trackingTitle: 'Withdrawals',
				externalFilter: true,
				container: {
					[USER_ACCOUNT_TRANSACTIONS_URL]: true,
					[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS]: false,
				},
			},
			{
				id: USER_ACCOUNT_ALL_TRANSACTIONS_URL,
				title: this.props.t('All Transactions'),
				longTitle: this.props.t('UserTransactionsContainer__FilterAll'),
				trackingTitle: 'All Bets',
				container: {
					[USER_ACCOUNT_TRANSACTIONS_URL]: true,
					[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS]: true,
				},
			},
			// {
			// 	id: USER_ACCOUNT_TODAY_TRANSACTIONS_URL,
			// 	title: this.props.t('Today'),
			// 	longTitle: this.props.t('UserTransactionsContainer__FilterTodayBets'),
			// 	trackingTitle: 'Today Bets',
			// 	container: {
			// 		[USER_ACCOUNT_TRANSACTIONS_URL]: true,
			// 		[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS]: false,
			// 	},
			// },
			// {
			// 	id: USER_ACCOUNT_TOURNAMENTS_TRANSACTIONS_URL,
			// 	title: this.props.t('Tournaments'),
			// 	longTitle: this.props.t('UserTransactionsContainer__FilterTournaments'),
			// 	trackingTitle: 'Tournaments',
			// 	container: {
			// 		[USER_ACCOUNT_TRANSACTIONS_URL]: true,
			// 		[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS]: true,
			// 	},
			// },
		];
		let filters = defaultFilters;

		// If custom filters are provided we need to use them
		if (props.accountTransactionFilters) {
			// Loop through our list of custom filters
			filters = props.accountTransactionFilters.reduce((acc, customFilter) => {
				// Find the matching default filter in the list provided by the acl
				const defaultFilter = defaultFilters.find((filter) => filter.id === customFilter.id);
				if (defaultFilter) {
					// If a match is found merge in the custom filter and ensure we use it
					acc.push({
						...defaultFilter,
						...customFilter,
					});
				} else {
					// Otherwise add the custom filter
					acc.push({
						id: customFilter.id,
						title: customFilter.title,
						longTitle: customFilter.title,
						trackingTitle: customFilter.title,
						container: {
							[USER_ACCOUNT_SUBMENU_ACCOUNT_TRANSACTIONS]: true,
							[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS]: true,
						},
						...customFilter,
					});
				}

				return acc;
			}, []);
		}

		this.state = {
			filters,
			errors: null,
			showBetPrompt: false,
			showQuickDepositPrompt: false,
			iserror: null,
			isSucess: null,
			isMultiBet: false,
			betVisibility: {},
			titles: {
				[USER_ACCOUNT_TRANSACTIONS_URL]: this.props.t('Account'),
				[USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS]: this.props.t('BonusBet'),
			},
			headers: [
				{ value: this.props.t('Date'), colSpan: '2' },
				{ value: this.props.t('Description'), colSpan: '4' },
				{ value: this.props.t('Amount') },
				{ value: this.props.t('Balance') },
			],
			filter: props.filter || USER_ACCOUNT_ALL_TRANSACTIONS_URL,
			statistics: [
				{
					header: this.props.t('Bets'),
					label: this.props.t('Today'),
					key: 'todays_bets',
				},
				{
					header: this.props.t('UserTransactionsContainer__OpenBets'),
					label: this.props.t('Today'),
					key: 'open_bets',
				},
				// {
				// 	header: this.props.t('Rebuy_plural'),
				// 	label: this.props.t('UserTransactionsContainer__Last', { days: 7 }),
				// 	key: 'rebuys',
				// },
				{
					header: this.props.t('Deposits'),
					label: this.props.t('UserTransactionsContainer__Last', { days: 30 }),
					key: USER_ACCOUNT_DEPOSITS_TRANSACTIONS_URL,
				},
				{
					header: this.props.t('Bets'),
					label: this.props.t('UserTransactionsContainer__Last', { days: 30 }),
					key: 'recent_bets',
				},
				// {
				// 	header: this.props.t('Daily Bail Out Limit'),
				// 	label: this.props.t('Today'),
				// 	key: 'cache_out',
				// },
				// {
				// 	header: this.props.t('Daily Price bump Limit'),
				// 	label: this.props.t('Today'),
				// 	key: 'price_bump',
				// },
			],
			form: {
				days: 'today',
				startDate: serverDateTime(moment()).format('YYYY-MM-DD'),
				endDate: serverDateTime(moment().subtract('today', 'days')).format('YYYY-MM-DD'),
			},
		};

		this.handleHasMultiBet = this.handleHasMultiBet.bind(this);
		this.handleBetsListVisibility = this.handleBetsListVisibility.bind(this);
	}

	componentDidMount() {
		if (this.props.currentRequest) return;

		this.props.fetchActiveBets();
		if (!this.props.hideStatistics) {
			this.loadStatisticsData();
		}
		this.loadData();
		//console.log('selectorr', this.selectRef.current);
	}

	

	componentDidUpdate(prevProps) {
		// Check if we are changing from Account Transactions to Bonus Bet Transactions or vice versa
		if (this.props.transactionType !== prevProps.transactionType) {
			this.changeFilter(USER_ACCOUNT_ALL_TRANSACTIONS_URL, true);
		}
	}

	componentWillUnmount() {
		this.props.setDataTable();
	}
	handleHasMultiBet() {
		this.setState((prevState) => ({
			isMultiBet: !prevState.isMultiBet,
		}));
	}

	setForm = (form) => {
		this.setState({ form });
	}; 
	handleBetsListVisibility(betId) {
		this.setState((prevState) => ({
			betVisibility: {
				...prevState.betVisibility,
				[betId]: !prevState.betVisibility[betId],
			},
		}));
	}
	/**
	 * Load the Statistics Data for larger screens
	 */
	loadStatisticsData = () => {
		this.props
			.fetchStatistics()
			.then((response) => {
				// console.log('responce',response);
				if (response) {
					const res_data = {
						...response.data.data,
					};
					// Update the statistics in our state with their corresponding values
					const statistics = this.state.statistics.map((statistic) => {
						statistic.value = res_data[statistic.key] || 0;

						return statistic;
					});
					// console.log('Updated statistics transaction: ', this.state.statistics);
					this.setState({ statistics });
				}
			})
			.catch((response) => {
				
				this.setState({
					errors:
						response.response && response.response.data
							? [Format.errorString(response.response.data.errors)]
							: [this.props.t('UserTransactionsContainer__UnableToLoadDashboard')],
				});
			});
	};

	/**
	 * Load the relevant Transaction data from the server
	 *
	 * @param {number} page - The page number to fetch.
	 * @param {string} transactionType - The type of transactions to fetch.
	 * @param {string} date - The date filter (today, week, month, range).
	 * @param {string} startDate - The start date for the range filter (if date is 'range').
	 * @param {string} endDate - The end date for the range filter (if date is 'range').
	 */
	loadData = (
		page = 1,
		transactionType = this.props.transactionType,
		date = this.state.form.days,
		startDate = this.state.form.startDate,
		endDate = this.state.form.endDate,
	) => {
		this.props.setLoading(true);
		this.props.setRequest('transactions');

		const action =
			transactionType === USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS
				? this.props.fetchBonusTransactions
				: this.props.fetchTransactions;

		action(page, this.state.filter, date, startDate, endDate)
			.then((response) => {
				if (window.location.pathname.includes(USER_ACCOUNT_BONUS_BET_TRANSACTION_URL)) {
					return;
				}
				// Update the data table
				const { current_page, data, last_page, per_page, total } = response.data;
				this.props.setDataTable({
					data,
					total,
					filteredData: data,
					headers: this.state.headers,
					totalPages: last_page,
					page: current_page,
					size: per_page,
				});
			})
			.catch((response) => {
				if (window.location.pathname.includes(USER_ACCOUNT_BONUS_BET_TRANSACTION_URL)) {
					return;
				}
				// Reset the data table if there is an issue
				this.props.setDataTable({
					data: [],
					total: 0,
					filteredData: [],
					headers: this.state.headers,
					totalPages: 0,
					page: 0,
					size: 0,
				});

				if (response.response && response.response.data && response.response.data.errors) {
					this.setState({
						errors: response.response.data.errors,
					});
				} else {
					this.setState({
						errors: [this.props.t('UserTransactionsContainer__UnableToLoadTransactions')],
					});
				}
			})
			.finally(() => {
				if (window.location.pathname.includes(USER_ACCOUNT_BONUS_BET_TRANSACTION_URL)) {
					return;
				}
				this.props.setRequest(null);
				this.props.setLoading(false);
			});
	};

	/**
	 * When one of the filters is changed, reload the filtered data and set the page to 1.
	 * A force option is supplied in the instance that the Transaction group filter is modified.
	 *
	 * @param filter
	 * @param force
	 */
	changeFilter = (filter = USER_ACCOUNT_ALL_TRANSACTIONS_URL, force = false) => {
		// Only load data if the filter was changed, or we are forcing a load
		if (force || filter !== this.state.filter) {
			this.setState({ filter, errors: null }, () => {
				this.loadData();

				// Find the tracking title for the applied filter
				const filterGroup = this.state.filters.find((f) => f.id === filter);
				if (filterGroup) {
					this.sendTrackingAndUrlFragment('Filter', filterGroup.trackingTitle, filter);
				}
			});
		}
	};

	/**
	 * Event handler for the dropdown version of the filters
	 *
	 * @param name
	 * @param filter
	 */
	changeDropdownFilter = (name, filter = USER_ACCOUNT_ALL_TRANSACTIONS_URL) => {
		this.changeFilter(filter);
	};

	/**
	 * Handle pagination across the data table
	 *
	 * @param newPage
	 // * @param size
	 // * @param data
	 */
	handlePagination = (newPage) => {
		this.loadData(newPage);
	};

	/**
	 * Hide the bet prompt modal
	 */
	handleHideBetPrompt = () => {
		this.setState({ showBetPrompt: false });
	};

	/**
	 * Show the deposit container modal
	 */
	handleShowQuickDepositPrompt = () => {
		this.setState({ showQuickDepositPrompt: true });
	};

	/**
	 * Hide the deposit container modal
	 */
	handleHideQuickDepositPrompt = () => {
		this.props.resetBetPromptTitle();
		this.setState({ showQuickDepositPrompt: false });
	};

	/**
	 * Handle a rebet event for the account transaction data
	 *
	 * @param event
	 * @param bet
	 */
	handleRebet = (event, bet) => {
		this.props.setLoading(true);
		if (bet.is_exotic) {
			this.openExoticsRebet(bet);
		} else if (bet.bet_type === 'sport') {
			this.openSportsRebet(bet);
		} else {
			this.openRacingRebet(bet);
		}
	};

	handleCacheOut = (e, bet) => {
		this.props.setLoading(true);

		const betSelectionType = bet.bet_selections.length > 0 ? bet.bet_selections[0].event_type : null;
		const betSelectionDate =
			bet.bet_selections.length > 0 ? serverDateTime(bet.bet_selections[0].date).format('YYYY-MM-DD') : null;
		const meetingId = bet.bet_selections.length > 0 ? bet.bet_selections[0].competition_id : null;
		const raceId = bet.bet_selections.length > 0 ? bet.bet_selections[0].event_id : null;
		//console.log('--betSelection--');
		//console.log(betSelectionType+'--'+betSelectionDate+ '--'+raceId+'--'+meetingId);

		this.props
			.CacheOut(bet)
			.then((response) => {
				if (response.status === 200) {
					this.props.setLoading(false);
					this.setState({ isSucess: 'success' });

					//this.props.RedirectMain();
					betSelectionType && betSelectionDate && raceId && meetingId
						? this.props.navigateToRaceWithType(betSelectionType, betSelectionDate, raceId, meetingId)
						: this.props.RedirectMain();
				}
			})
			.catch((error) => {
				this.setState({ iserror: error });
				this.props.setLoading(false);
			});
	};
	cancelWithdrawal = (withdrawal_id) => {
		this.props.setLoading(true);
		this.props
			.cancelWithdrawal(withdrawal_id)
			.then((response) => {
				if (response.status === 200) {
					this.loadData();
					this.props.setLoading(false);
					//this.setState({ isSucess: 'success' });

					//this.props.RedirectMain();
				}
			})
			.catch((error) => {
				this.setState({ iserror: error.message });
				this.props.setLoading(false);
			});
	};

	getDefaultBetPromptState = () => {
		return {
			betConfirmed: false,
			betRejected: false,
			betRejectedReason: null,
			betRejectedComment: null,
			betOdds: null,
			betsPlaced: [],
			title: DEFAULT_BET_PROMPT_TITLE,
		};
	};

	openExoticsRebet = (bet) => {
		const firstSelection = bet.bet_selections[0];
		const exoticDetails = {
			id: bet.bet_type,
			isBoxed: bet.boxed_flag,
			productId: bet.product_id,
			race: firstSelection.competition_name + ' Race #' + firstSelection.race_number,
			title: Format.forHumans(bet.bet_type),
		};

		// Counting to correctly do exotics
		let count = 1;
		let selections = [];
		let filteredString = bet.selection_string.replace(/[^,/]+/g, '');

		// Generate correct selections data
		bet.bet_selections.forEach((selection) => {
			selections.push({
				id: selection.selection_id,
				number: selection.selection_number,
				position: String(count),
			});

			// Remove first character from filtered string
			if (filteredString.startsWith('/')) {
				count++;
			}
			filteredString = filteredString.slice(1);
		});

		this.openRacingRebet(bet, exoticDetails, selections);
	};

	openSportsRebet = (bet) => {
		const {
			transactionType,
			fetchSportsByCompetitionId,
			fetchCombinedMarketsGroupsWithSelections,
			formatAndAddSportSelectionToBetPrompt,
			updateBetPromptDetails,
		} = this.props;

		// Default flags to keep the bet slip in-sync
		const details = {
			...this.getDefaultBetPromptState(),
			stake: bet.amount,
			useBonusBets: transactionType === USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
		};

		const selection = bet.bet_selections[0];

		Promise.all([
			fetchSportsByCompetitionId(selection.competition_id),
			fetchCombinedMarketsGroupsWithSelections(selection.event_id),
		]).then(() => {
			formatAndAddSportSelectionToBetPrompt(
				selection.selection_id,
				selection.market_id,
				selection.base_odds || selection.odds,
				bet.product_id,
				bet.product_type,
			).then(() => {
				triggerEventMessage(this.props.eventContext, 'selectionAddedToBetPrompt');
			});

			updateBetPromptDetails(details);

			this.props.setLoading(false);
			this.setState({ showBetPrompt: true });
		});
	};

	openRacingRebet = (bet, exoticDetails = {}, exoticSelections = []) => {
		const {
			transactionType,
			fetchMeetingsAndRacesWithSelectionsForRace,
			formatAndAddSingleSelectionToBetPrompt,
			formatAndAddExoticSelectionToBetPrompt,
			formatAndAddDerivativeSelectionToBetPrompt,
			updateBetPromptDetails,
		} = this.props;

		const selection = bet.bet_selections[0];
		const betType = bet.bet_type === RACING_BET_TYPE_MARGIN ? RACING_BET_TYPE_WIN : bet.bet_type;

		// Defaults for the bet slip
		const details = {
			...this.getDefaultBetPromptState(),
			betType,
			exoticDetails,
			productId: bet.product_type === PRODUCT_TYPE_BOOST ? bet.boosted_product : bet.product_id,
			stake: 0, //bet.amount,
			useBonusBets: transactionType === USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS,
		};

		fetchMeetingsAndRacesWithSelectionsForRace(selection.competition_id, selection.event_id).then(() => {
			if (exoticDetails.id && exoticSelections.length) {
				formatAndAddExoticSelectionToBetPrompt(
					selection.event_id,
					selection.competition_id,
					exoticDetails.id,
					exoticDetails.isBoxed,
					exoticSelections,
				).then(() => {
					triggerEventMessage(this.props.eventContext, 'selectionAddedToBetPrompt');
				});
			} else if (details.betType === BET_TYPE_DERIVATIVE) {
				const derivativeBetSelection = bet.derivative_bet_selections[0];
				formatAndAddDerivativeSelectionToBetPrompt(
					derivativeBetSelection.derivative_selection_id,
					derivativeBetSelection.derivative_market_id,
				).then(() => {
					triggerEventMessage(this.props.eventContext, 'selectionAddedToBetPrompt');
				});
			} else {
				formatAndAddSingleSelectionToBetPrompt(
					selection.selection_id,
					selection.event_id,
					selection.competition_id,
					details.productId,
					details.betType,
				).then(() => {
					triggerEventMessage(this.props.eventContext, 'selectionAddedToBetPrompt');
				});
			}

			updateBetPromptDetails(details);

			this.props.setLoading(false);
			this.setState({ showBetPrompt: true });
		});
	};

	/**
	 * Get the bet prompt modal
	 *
	 * @returns {boolean|XML}
	 */
	buildBetPromptModal = () => {
		// Setup the modal configuration for the bet prompt
		const MODAL_CONFIG = {
			mobileWidth: true,
			noBorder: true,
			preventBackdropClose: true,
			hideClose: true,
			beforeModalClose: () => {
				if (this.props.betReferred) {
					return false;
				} else {
					return this.handleHideBetPrompt();
				}
			},
		};

		return (
			this.state.showBetPrompt && (
				<Modal
					open={this.state.showBetPrompt}
					store={App.store}
					config={MODAL_CONFIG}
					componentKey="account-transactions__bet-prompt"
				>
					<div>
						<ModalHeader
							onClose={MODAL_CONFIG.beforeModalClose}
							title={this.props.betPrompt.title}
							aside={<BrandContactPhone />}
						/>
						<BetPromptContainer
							betPlaced={this.loadData}
							handleCancel={this.handleHideBetPrompt}
							handleQuickDeposit={this.handleShowQuickDepositPrompt}
						/>
						<ModalFooter />
					</div>
				</Modal>
			)
		);
	};

	/**
	 * Get the quick deposit modal
	 *
	 * @returns {boolean|XML}
	 */
	buildQuickDepositModal = () => {
		// Setup the modal configuration for the bet prompt
		const MODAL_CONFIG = {
			mobileWidth: true,
			noBorder: true,
			preventBackdropClose: true,
			title: this.props.betPrompt.title,
		};

		return (
			this.state.showQuickDepositPrompt && (
				<Modal
					open={this.state.showQuickDepositPrompt}
					store={App.store}
					config={MODAL_CONFIG}
					componentKey="account-transactions__quick-deposit"
					onClose={this.handleHideQuickDepositPrompt}
				>
					<DepositContainer
						isQuickDeposit
						trackingCategory="Quick Deposit"
						handleClose={this.handleHideQuickDepositPrompt}
					/>
					<ModalFooter />
				</Modal>
			)
		);
	};

	/**
	 * Send transaction for tracking and to update the route
	 *
	 * @param action
	 * @param label
	 * @param filter
	 */
	sendTrackingAndUrlFragment = (action, label, filter) => {
		const trackingCategory =
			this.props.transactionType === USER_ACCOUNT_SUBMENU_BONUS_TRANSACTIONS ? 'BonusBetStatement' : 'AccountStatement';

		// (category, action, label, value)
		this.props.trackGaEvent(trackingCategory, action, label);
		this.props.setTransactionFilter(filter);
	};

	/**
	 * Route to the event/selection when a bet link is clicked on
	 *
	 * @param bet
	 * @param type
	 */
	handleRouteToBetSelection = (bet, type) => {
		switch (type) {
			case 'tournament':
				this.props.navigate(buildRouteToTournament(bet.ticket.tournament.id, bet.ticket.tournament.id), {
					trigger: true,
				});
				break;
			case 'bet':
				if (bet.event_type === 'sport') {
					this.props.navigate(buildSportRouteForObject(bet), { trigger: true });
				} else {
					const meeting = {
						id: bet.competition_id,
						name: bet.competition_name,
						type: bet.event_type,
						start_date: bet.date, //@HW19Nov2021
					};

					const race = {
						number: bet.race_number,
						id: bet.event_id,
					};

					// Load racing React page on race
					//this.props.navigate(buildRouteToRace(meeting, race), { trigger: true }); // comment by @HW 18May2020
					this.props.navigate(
						buildWithDateGRSRouteToRace(bet.event_type, serverDateTime(bet.date).format('YYYY-MM-DD'), meeting, race),
						{ trigger: true },
					);
				}

				break;
			default:
				throw new Error('Unrecognised resource type: ' + type, bet);
		}
	};

	render() {
		const {
			t,
			className,
			isDepositsAndWithdrawalsEnabled,
			transactionType,
			size,
			parentSize,
			isTournamentsEnabled,
			showHeader,
			cashout_limit,
			bailOutIsEnabled,
		} = this.props;
		const { errors, titles, iserror, isSucess } = this.state;
		let { filters, statistics } = this.state;

		const containerClasses = cx({
			[className]: className,
		});

		const isTabletWidth = size.width < 520;
		// size.width < TABLET_MAX_WIDTH;

		// Remove the Deposits and Withdrawals filters and statistics if the feature is disabled
		if (!isDepositsAndWithdrawalsEnabled) {
			filters = filters.filter(
				(filter) =>
					filter.id !== USER_ACCOUNT_DEPOSITS_TRANSACTIONS_URL &&
					filter.id !== USER_ACCOUNT_WITHDRAWALS_TRANSACTIONS_URL,
			);
			statistics = statistics.filter((statistic) => statistic.key !== USER_ACCOUNT_DEPOSITS_TRANSACTIONS_URL);
		}

		// Remove the Tournament filter if the feature is disabled
		if (!isTournamentsEnabled) {
			filters = filters.filter((filter) => filter.id !== USER_ACCOUNT_TOURNAMENTS_TRANSACTIONS_URL);
		}

		return (
			<StyledUserTransactionsContainer className={containerClasses}>
				{this.buildBetPromptModal()}
				{this.buildQuickDepositModal()}

				{showHeader && <StyledSectionTitle>{`${titles[transactionType]} ${t('Transactions')}`}</StyledSectionTitle>}

				{!this.props.hideStatistics && (
					<React.Fragment>
						{/* {showHeader && (
							<StyledUserTransactionsContainer__HeaderAccount type="primary" size={size}>
								<AclContext.Consumer>
									{(acl) => (acl.brandDetails && acl.brandDetails.name ? `${acl.brandDetails.name} ` : '')}
								</AclContext.Consumer>
								{t('UserTransactionsContainer__Title')}
							</StyledUserTransactionsContainer__HeaderAccount>
						)} */}
						<StyledStaticHeader>Statistics</StyledStaticHeader>
						<StyledUserTransactionsContainer__StatisticsGroup statistics={statistics} size={size} />
						<Text size="1" align="center" style={{ marginTop: 8 }}>
							{t('Transaction Details')}
						</Text>
					</React.Fragment>
				)}

				{errors && (
					<StyledUserTransactionsContainer__ErrorContainer
						size={size}
						message={errors}
						type={Notification.types.COLOUR_DANGER}
						strong
					/>
				)}

				{iserror && (
					<StyledUserTransactionsContainer__ErrorContainer
						size={size}
						message={iserror}
						type={Notification.types.COLOUR_DANGER}
						strong
					/>
				)}

				{isSucess && (
					<StyledUserTransactionsContainer__ErrorContainer
						size={size}
						message={isSucess}
						type={Notification.types.COLOUR_SUCCESS}
						strong
					/>
				)}

				{filters.length ? (
					isTabletWidth ? (
						<FilterSelection_Wrapper>
							<StyledUserTransactionsContainer__FiltersDropdown
								mediaSize={size}
								name="filters"
								onChange={this.changeDropdownFilter}
								value={this.state.filter}
							>
								{filters.map((filter) => {
									if (filter.container[transactionType]) {
										return (
											<FilterSelection_SelectMenu ref={this.selectRef} key={filter.id} value={filter.id}>
												{filter.longTitle}
											</FilterSelection_SelectMenu>
										);
									}
								})}
							</StyledUserTransactionsContainer__FiltersDropdown>
						</FilterSelection_Wrapper>
					) : (
						<StyledUserTransactionsContainer__FiltersTabs size={size} wrappingBorder justify>
							{filters.map((filter) => {
								if (filter.container[transactionType]) {
									return (
										<TabsItem
											active={this.state.filter === filter.id}
											action={this.changeFilter}
											data={filter.id}
											key={filter.id}
										>
											{filter.title}
										</TabsItem>
									);
								}
							})}
						</StyledUserTransactionsContainer__FiltersTabs>
					)
				) : null}
				{/* Hide to date filter */}

				{/* <TransactionsDetails t={this.props.t} type={this.state.filter}  loadData={this.loadData}/> */}
				<TransactionsDetails
					t={this.props.t}
					type={this.state.filter}
					loadData={this.loadData}
					context="regularTransaction"
					form={this.state.form}
					setForm={this.setForm}
				/>

				<OverflowTableView>
					<DataTableContainer
						action={this.handleRouteToBetSelection}
						changeTablePage={this.handlePagination}
						reducerName={DATA_PAGINATOR_USER_TRANSACTIONS}
					>
						<AccountTransactions
							NavigateAction={this.handleRouteToBetSelection}
							handleRebet={this.handleRebet}
							handleCacheOut={this.handleCacheOut}
							cancelWithdrawal={this.cancelWithdrawal}
							isSmallScreen={isTabletWidth}
							cashout_limit={cashout_limit}
							bailOutIsEnabled={bailOutIsEnabled}
							isMultiBet={this.state.isMultiBet}
							handleHasMultiBet={this.handleHasMultiBet}
							betVisibility={this.state.betVisibility}
							handleBetsListVisibility={this.handleBetsListVisibility}
							handleBetExport={this.props.handleBetExport}
						/>
					</DataTableContainer>
				</OverflowTableView>
			</StyledUserTransactionsContainer>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const betReferred = isBetReferred(state);
	const authenticatedUser = state.application.authenticatedUser;

	return {
		balance: state.entities.users[authenticatedUser].account_balance,
		betReferred,
		betPrompt: state.betPrompt,
		data: state[DATA_PAGINATOR_USER_TRANSACTIONS].data,
		transactionType: ownProps.transactionType,
		accountTransactionFilters: state.acl.accountTransactionFilters,
		bailOutIsEnabled: state.featureToggles.features.bailOut ? state.featureToggles.features.bailOut.enabled : false,
		cashout_limit: state.entities.users[authenticatedUser].cashout_limit,
		isDepositsAndWithdrawalsEnabled:
			state.featureToggles.features.depositsAndWithdrawals &&
			state.featureToggles.features.depositsAndWithdrawals.enabled,
		isTournamentsEnabled:
			state.featureToggles.features.tournaments && state.featureToggles.features.tournaments.enabled,
		hideStatistics:
			state.featureToggles.features.accountTransactions &&
			state.featureToggles.features.accountTransactions.value &&
			state.featureToggles.features.accountTransactions.value.hideStatistics,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		fetchStatistics,
		fetchTransactions,
		fetchBonusTransactions,
		fetchActiveBets: () => dispatch(fetchActiveBets()),
		CacheOut: (data) => dispatch(CacheOut(data)),
		cancelWithdrawal: (withdrawal_id) => dispatch(cancelWithdrawal(withdrawal_id)),
		fetchSportsByCompetitionId: (competitionId) => dispatch(fetchSportsByCompetitionId(competitionId)),
		fetchCombinedMarketsGroupsWithSelections: (competitionId) =>
			dispatch(fetchCombinedMarketsGroupsWithSelections(competitionId)),
		formatAndAddSportSelectionToBetPrompt: (selectionId, marketId, odds, productId, productType) =>
			dispatch(formatAndAddSportSelectionToBetPrompt(selectionId, marketId, odds, productId, productType)),
		formatAndAddExoticSelectionToBetPrompt: (raceId, meetingId, betType, boxed, selectedCheckboxes) =>
			dispatch(formatAndAddExoticSelectionToBetPrompt(raceId, meetingId, betType, boxed, selectedCheckboxes)),
		formatAndAddSingleSelectionToBetPrompt: (selectionId, raceId, meetingId, productId, betType) =>
			dispatch(formatAndAddSingleSelectionToBetPrompt(selectionId, raceId, meetingId, productId, betType, false)),
		formatAndAddDerivativeSelectionToBetPrompt: (derivativeId, marketId) =>
			dispatch(formatAndAddDerivativeSelectionToBetPrompt(derivativeId, marketId)),
		fetchMeetingsAndRacesWithSelectionsForRace: (meetingId, raceId) =>
			dispatch(fetchMeetingsAndRacesWithSelectionsForRace(meetingId, raceId)),
		updateBetPromptDetails: (details) => dispatch(updateBetPromptDetails({ ...details })),
		setDataTable: (data) => dispatch(userTransactionsDataTableActions.setDataTable(data)),
		setLoading: (isLoading) => dispatch(userTransactionsDataTableActions.setLoading(isLoading)),
		resetBetPromptTitle: () => dispatch(resetBetPromptTitle()),
		trackGaEvent: (category, action, label, value) => dispatch(trackGaEvent(category, action, label, value)),
		navigate: (route, opts) => {
			dispatch(navigate(route, opts));
		},
		RedirectMain: () => {
			dispatch(routeTo('/user'));
		},
		navigateToRaceWithType: (type, date, raceId, meetingId) => {
			dispatch(navigateToNextToJumpRaceWithType(type, date, raceId, meetingId));
			App.startSubApp('RacingNewPage');
		},
		handleBetExport: (bet) => {
			dispatch(openBetExport(bet));
		},
	};
};

export default withNamespaces()(
	createErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(UserTransactionsContainer)),
);

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import {
	PUSHER_EVENT_UPDATE,
	PUSHER_EVENT_ODDS_UPDATE,
	PUSHER_SELECTION_UPDATE,
} from '../../../../common/constants/Racing';
import {
	updatePrices,
	buildPriceUpdates,
	buildPriceUpdateliability,
} from '../../../../store/entities/actions/PriceActions';
import { mergeDerivativeSelection, mergeSelection } from '../../../../store/entities/actions/RaceActions';

import PusherSubscribe from '../../../controllers/Pusher/PusherSubscribe';
import { PLACE_MULTI_BET } from '../../../../store/betPlacement/betPlacementReducerNames';
import { replaceRace } from '../../../../store/betPlacement/betPlacementActions';

/**
 * Pusher Subscriber Container for Races that are in the Betslip
 * Channel: race_<raceId>
 */

class PusherSubscriberRaceBetChannel extends Component {
	handlePusherRaceUpdate = (data) => {
		if (data.id && data.status) {
			const race = { id: data.id, status: data.status };
			this.props.updateRace(race);
		}
	};

	/**
	 * Handles pushed data to update odds.
	 * @param data
	 */
	handlePusherOddsUpdate = (data) => {
		const prices = buildPriceUpdates(data.selections);
		if (data.selections) {
			data.selections.forEach(({ prices, ...selection }) => {
				this.props.updateSelection({
					prices: [prices],
					...selection,
				});
			});
		}

		this.props.updatePrices(prices);
	};

	/**
	 * Handles pushed data to update odds.
	 * @param data
	 */
	handlePusherOddsGridUpdate = (data) => {
		/**
		 * Separate Price entities to be updated,
		 * otherwise Race can lose selections and selections can lose price.
		 */
		const prices = buildPriceUpdateliability(data.selections);

		for (const selection in data.selections) {
			this.props.updateSelection(selection);
		}

		this.props.updatePrices(prices);
	};

	handleSelectionUpdate = (data) => {
		const selection = {};
		for (const key in data.selection) {
			if (key == 'selection_fluctuations') {
				// console.log('data', data);
				if (data.selection[key] && data.selection[key].length > 0) {
					selection[key] = data.selection[key];
				}
			} else {
				selection[key] = data.selection[key];
			}
		}

		this.props.updateSelection(selection);
	};

	render() {
		const { raceId } = this.props;

		// Determine channel for all subscriptions
		const channel = `race_${raceId}`;
		return (
			<Fragment>
				<PusherSubscribe
					key={PUSHER_EVENT_UPDATE}
					channel={channel}
					event={PUSHER_EVENT_UPDATE}
					onUpdate={this.handlePusherRaceUpdate}
				/>
				<PusherSubscribe
					key={PUSHER_EVENT_ODDS_UPDATE}
					channel={channel}
					event={PUSHER_EVENT_ODDS_UPDATE}
					onUpdate={this.handlePusherOddsUpdate}
				/>
				<PusherSubscribe
					key={PUSHER_SELECTION_UPDATE}
					channel={channel}
					event={PUSHER_SELECTION_UPDATE}
					onUpdate={this.handleSelectionUpdate}
				/>
			</Fragment>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
	updateRace: (race) => dispatch(replaceRace(PLACE_MULTI_BET, race)),
	updatePrices: (prices) => dispatch(updatePrices(prices)),
	updateSelection: (selection) => dispatch(mergeSelection(selection)),
	updateDerivativeSelection: (selection) => dispatch(mergeDerivativeSelection(selection)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PusherSubscriberRaceBetChannel);

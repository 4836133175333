import { brand, Table, Tabs, TabsItem, Text, ui } from '@tbh/ui-kit';
import React, { useMemo } from 'react';
import styled, { css } from 'react-emotion';
import { RaceResultSelectionDetails } from './RaceResultSelectionDetails';
import { TOTE_NAMES_BY_PRODUCT, toteProducts } from '../../../../store/entities/constants/BetConstants';
import { BREAKPOINTS } from '../../../../constants/themes';
import {
	QUADDIES_BET_TYPES,
	RACING_BET_TYPE_DAILY_DOUBLE,
	RACING_BET_TYPE_QUADDIE,
	RACING_BET_TYPE_RUNNING_DOUBLE,
	RACING_BET_TYPE_RUNNING_TREBLE,
	SELECTION_NOT_SCRATCHED_STATUS
} from '../../../../common/constants/Racing';

const StyledRaceResults = styled('div')`
	label: RaceResults;
	width: 100%;
	display: flex;
	flex-direction: column;
`;

const StyledRaceResultsTabsItem = styled(TabsItem)(
	(props) => css`
		label: RaceResultsTabsItem;

		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 34px;

		padding: 12px;
		background-color: ${props.active ? `${brand(props).rch_4} !important` : 'none'};
		${props.active && 'color:white;'}

		&:is(:first-child) {
			border-left: 1px solid ${ui(props).color_5}!important;
		}

		&:is(:last-child) {
			border-right: 1px solid ${ui(props).color_5}!important;
		}
	`
);

const StyledRaceResultTable = styled(Table)`
	label: RaceResultTable;

	width: 100%;
	margin-bottom: 0;
	border: 1px solid ${ui().color_5};
	border-radius: 0px 0px 4px 4px;
	color: black;
	min-width: 300px;
`;

const StyledRaceResultWinPlace = styled(Table.TD)`
	label: RaceResultWinPlace;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 10px;
	padding: 0px;

	@media (max-width: ${BREAKPOINTS.laptopMin}px) {
		justify-content: center;
		padding: 4px 0px;
		background: #dddddd;
		&:last-child {
			border-radius: 0px 0px 4px 4px;
		}
	}
`;

const ResultRow = styled(Table.TR)`
	&:not(:last-child) {
		border-bottom: 1px solid #666666;
	}
	@media (max-width: ${BREAKPOINTS.laptopMin}px) {
		flex-direction: column;
	}
`;

const ResultValue = styled('div')`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 12px;
	text-align: center;
	width: 70px;
	gap: 4px;
`;

const tabs = {
	results: 'Win & Place',
	exotics: 'Exotics / Multiples'
};

const RaceResultsV2 = (props) => {
	const { t, className, results = [], result_string, exoticResults = [], totes_results = [], race } = props;
	const selections = race.selections.filter(
		(selections) => selections.selection_status === SELECTION_NOT_SCRATCHED_STATUS
	);
	const substitutes = race.substitutes;
	const [activeTab, setActiveTab] = React.useState('results');

	const handleTabChange = (tab) => {
		setActiveTab(tab);
	};

	const res = [...results, ...totes_results];

	if (res.length === 0 && exoticResults.length === 0) return null;

	return (
		<StyledRaceResults>
			<Tabs padding={Tabs.paddings.SPACING_TIGHT} wrappingBorder type="secondary">
				<StyledRaceResultsTabsItem active={activeTab === 'results'} onClick={() => handleTabChange('results')}>
					{tabs.results}
				</StyledRaceResultsTabsItem>
				{exoticResults.length > 0 && (
					<StyledRaceResultsTabsItem active={activeTab === 'exotics'} onClick={() => handleTabChange('exotics')}>
						{tabs.exotics}
					</StyledRaceResultsTabsItem>
				)}
			</Tabs>

			{activeTab === 'results' && <RaceResultWinPlace results={res} selections={selections} raceType={race.type} />}
			{activeTab === 'exotics' && <RaceResultExotics exoticResults={exoticResults} />}

			<SubstituteTable substitutes={substitutes} />
		</StyledRaceResults>
	);
};

const getCodeByKeyword = (keyword, raceType) => {
	if (keyword === 'GRSF') return 'FIXED';
	if (keyword === 'OSP') return 'SP';
	if (toteProducts.includes(keyword)) return TOTE_NAMES_BY_PRODUCT[keyword][raceType];
	return keyword;
};

const sortResults = (results) => {
	return Object.keys(results)
		.sort((a, b) => {
			const [aType, aKeyword] = a.split('_');
			const [bType, bKeyword] = b.split('_');

			// Sorting logic
			if (aKeyword === bKeyword) {
				if (aType === 'win' && bType !== 'win') return -1;
				if (aType !== 'win' && bType === 'win') return 1;
			}
			if (aKeyword === 'OSP' && bKeyword !== 'OSP') return -1;
			if (aKeyword !== 'OSP' && bKeyword === 'OSP') return 1;
			if (aKeyword === 'GRSF' && bKeyword !== 'GRSF') return -1;

			if (toteProducts.includes(aKeyword) && toteProducts.includes(bKeyword)) {
				if (aType === 'win' && bType !== 'win') return -1;
				if (aType !== 'win' && bType === 'win') return 1;
			}

			if (aKeyword !== 'GRSF' && bKeyword === 'GRSF') return 1;
		})
		.reduce((sorted, key) => {
			sorted[key] = results[key];
			return sorted;
		}, {});
};

const buildResultsByPosition = (results, selections, raceType) => {
	const combinedResults = [...results];
	const allPossibleResultKeys = [...new Set(combinedResults.map((r) => `${r.bet_type}_${r.keyword}_${r.product_id}`))];

	const resultsByPosition = combinedResults.reduce((acc, result) => {
		const { position, selection_id, name, number } = result;
		const selection = selections.find((sel) => sel.id === selection_id);

		if (!selection) return acc;

		const unAvaliable =
			(selections.length >= 8 && position >= 4) ||
			(selections.length <= 7 && selections.length >= 5 && position >= 4) ||
			(selections.length < 5 && position >= 3);
		let newPosition = position;
		if (!acc[newPosition]) {
			acc[newPosition] = {
				selection: {
					selection_id,
					name,
					number,
					position,
					jockey: selection.jockey,
					trainer: selection.trainer,
					weight: selection.weight,
					f: selection.last_starts_string,
					silk: selection.silk
				},
				results: unAvaliable
					? {}
					: allPossibleResultKeys.reduce((res, key) => {
							res[key] = {
								id: key,
								bet_type: key.split('_')[0] === 'place' ? 'place' : '',
								dividend: key.split('_')[0] === 'place' ? 'NTD' : ''
							};
							return res;
					  }, {})
			};
		} else if (acc[newPosition].selection.number !== number) {
			newPosition = position + selection.number / 10;
			if (!acc[newPosition]) {
				acc[newPosition] = {
					selection: {
						selection_id,
						name,
						number,
						position,
						jockey: selection.jockey,
						trainer: selection.trainer,
						weight: selection.weight,
						f: selection.last_starts_string,
						silk: selection.silk
					},
					results: unAvaliable
						? {}
						: allPossibleResultKeys.reduce((res, key) => {
								res[key] = {
									id: key,
									bet_type: key.split('_')[0] === 'place' ? 'place' : '',
									dividend: key.split('_')[0] === 'place' ? 'NTD' : ''
								};
								return res;
						  }, {})
				};
			}
		}

		if (unAvaliable) return acc;
		// Add result data
		acc[newPosition].results[`${result.bet_type}_${result.keyword}_${result.product_id}`] = {
			...result,
			code: getCodeByKeyword(result.keyword, raceType),
			dividend: result.dividend ? result.dividend.toFixed(2) : 'NTD'
		};

		return acc;
	}, {});
	Object.keys(resultsByPosition).forEach((position) => {
		const resultKeys = resultsByPosition[position].results;
		resultsByPosition[position].results = sortResults(resultKeys);
	});

	return resultsByPosition;
};

const RaceResultWinPlace = ({ results, selections, raceType }) => {
	const resultsByPosition = useMemo(() => buildResultsByPosition(results, selections, raceType), [
		results,
		selections,
		raceType
	]);

	return (
		<StyledRaceResultTable>
			<Table.TG>
				{Object.entries(resultsByPosition)
					.sort(([a], [b]) => parseInt(a, 10) - parseInt(b, 10))
					.map(([position, value]) => (
						<ResultRow key={position}>
							<Table.TD
								className={css`
									padding: 0px 8px;
									display: flex;
									align-items: flex-end;
								`}
							>
								<RaceResultSelectionDetails selection={value.selection} />
							</Table.TD>
							<StyledRaceResultWinPlace>
								{Object.values(value.results).map((result) => (
									<ResultValue key={result.id}>
										{position === '1' && (
											<Text size="-3" right strong>
												{result.code}
											</Text>
										)}
										{position === '1' && (
											<Text size="-3" right>
												{result.bet_type.toUpperCase()}
											</Text>
										)}
										<Text size="-3" right>
											{result.dividend}
										</Text>
									</ResultValue>
								))}
							</StyledRaceResultWinPlace>
						</ResultRow>
					))}
			</Table.TG>
		</StyledRaceResultTable>
	);
};

const exoticTitles = {
	exacta: 'Exacta',
	trifecta: 'Trifecta',
	firstfour: 'First 4',
	quinella: 'Quinella',
	[RACING_BET_TYPE_DAILY_DOUBLE]: 'Daily Double',
	[RACING_BET_TYPE_RUNNING_DOUBLE]: 'Running Double',
	[RACING_BET_TYPE_RUNNING_TREBLE]: 'Treble',
	[RACING_BET_TYPE_QUADDIE]: 'Quaddies'
};

const ExoticSelections = ({ result }) => {
	return (
		<div
			className={css`
				display: flex;
				flex-direction: row;
				width: 100%;
				gap: 8px;
			`}
		>
			{result.selections.split('/').map((selection, i) => {
				const position_sign = i + 1 === 1 ? 'st' : i + 1 === 2 ? 'nd' : i + 1 === 3 ? 'rd' : 'th';
				const prefix = QUADDIES_BET_TYPES.includes(result.bet_type) ? 'R' : '';

				return (
					<div key={selection}>
						<Text size="-3" right>
							{i + 1}
							{position_sign}
						</Text>
						<Text
							className={css`
								padding: 2px 3px;
								color: white;
								text-align: center;
								background: #666666;
								border-radius: 4px;
								min-width: 24px;
							`}
							size="-1"
							right
						>
							{prefix + selection}
						</Text>
					</div>
				);
			})}
		</div>
	);
};

const RaceResultExotics = ({ exoticResults }) => {
	return (
		<StyledRaceResultTable>
			{exoticResults
				.filter((result) => exoticTitles[result.bet_type]) // Only show results that have a title defined
				.sort((a, b) => a.selections.length - b.selections.length)
				.map((result) => (
					<ResultRow key={result.id}>
						<Table.TD>
							<Text size="-2" right>
								{exoticTitles[result.bet_type]}
							</Text>
						</Table.TD>
						<Table.TD>
							<ExoticSelections result={result} />
						</Table.TD>
						<Table.TD>
							<Text
								size="-1"
								className={css`
									color: black;
									width: 100%;
									text-align: right;
								`}
								right
							>
								{result.dividend}
							</Text>
						</Table.TD>
					</ResultRow>
				))}
		</StyledRaceResultTable>
	);
};

const SubstituteTableWrapper = styled('div')`
	margin-top: 10px;
	border: 1px solid ${ui.color_5};
	border-radius: 4px;
`;

const SubstituteTable = ({ substitutes }) => {
	if (!substitutes || Object.keys(substitutes).length === 0) return null;

	const tableRows = Object.entries(substitutes)
		.filter(([_, value]) => value && value.length > 0)
		.map(([key, value]) => (
			<Table.TR key={key}>
				<Table.TD>{key.charAt(0).toUpperCase() + key.slice(1)}</Table.TD>
				<Table.TD>
					<Text
						size="-3"
						className={css`
							color: black;
							width: 100%;
							text-align: right;
						`}
						right
					>
						{value}
					</Text>
				</Table.TD>
			</Table.TR>
		));

	if (tableRows.length === 0) return null;

	return (
		<SubstituteTableWrapper>
			<Table density="cozy" size="-2" border>
				<Table.TG tbody>{tableRows}</Table.TG>
			</Table>
		</SubstituteTableWrapper>
	);
};

export default RaceResultsV2;

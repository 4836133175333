import cx from 'classnames/bind';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';

import { brand, Icon, spacings, ui } from '@tbh/ui-kit';
import { DEFAULT_COLORS } from '../../../constants/themes';

import {
	launchLiveChat,
	navigate,
	openNotification,
	scrollToTop,
	showDeposits,
	toggleMultiBetSlip,
} from '../../../store/application/applicationActions';
import { createErrorBoundary } from '../../ErrorBoundary/ErrorBoundaryActions';

import {
	buildEachWaySingleBets,
	buildExoticsBets,
	buildMultiBets,
	buildMultiSingleBets,
	buildSelectionsForRequest,
	buildSRMsBets,
	shortenRacingBetType,
} from '../../../common/BetPlacement';
import {
	QUADDIES_BET_TYPES,
	RACING_BET_TYPE_EACHWAY,
	RACING_BET_TYPE_EACHWAY_SHORT,
	RACING_BET_TYPE_PLACE,
	RACING_BET_TYPE_PLACE_SHORT,
	RACING_BET_TYPE_WIN,
	RACING_BET_TYPE_WIN_SHORT,
	RACING_EXOTIC_BET_TYPES,
	RACING_TYPES_LOOKUP,
} from '../../../common/constants/Racing';
import { buildWithDateGRSRouteToRace } from '../../../routes/RacingNew'; // add racing-v3 route
import { buildSportRoute } from '../../../routes/Sports';
import * as multiBetPlacementActions from '../../../store/betPlacement/multiBetPlacementActions';
import { fetchActiveBets, loadDataForSelections, updateBetStatus } from '../../../store/entities/actions/BetActions';
import { denormalizeBets } from '../../../store/entities/schemas/BetSchema';
import { denormalizeSelections } from '../../../store/entities/schemas/SelectionSchema';
import {
	getPendingBets,
	getRacingBetTypeLabel,
	getSportBetLabel,
} from '../../../store/entities/selectors/BetSelectors';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { getSelectionsForBetSlip, getSingleRaceSelectionsForSlip } from '../bettingMemoizedSelectors';
// import { GENERIC_LOGIN_MESSAGE } from '../../../common/constants/Notifications';
import {
	Button,
	ButtonGroup,
	// Image,
	Checkbox,
	HeaderBar,
	LoadingMask,
	Notification,
	PlotElements,
	Text,
} from '@tbh/ui-kit';
import { isNumber } from 'lodash-es';
import { serverDateTime } from '../../../../src/legacy/core/format';
import { calculateCombinations, parsePositions } from '../../../common/ExoticBets';
import { formatAndUpdateRacingMultiBet } from '../../../common/actions/multiActions';
import CurrencyDisplay from '../../../components/features/Application/Currency/CurrencyDisplay/CurrencyDisplay';
import ExoticBetList from '../../../components/features/Betting/ExoticBetList/ExoticBetList';
import MultiBetSelectionList from '../../../components/features/Betting/MultiBetSelectionList/MultiBetSelectionList';
import MultiLegItem from '../../../components/features/Betting/MultiLegItem/MultiLegItem';
import MultiSRMList from '../../../components/features/Betting/MultiSRMList/MultiSRMList';
import QuaddieList from '../../../components/features/Betting/QuaddieList/QuaddieList';
import MultiBetSummary from '../../../components/features/Betting/Receipt/MultiBetSummary/MultiBetSummary';
import UnitStake from '../../../components/features/Betting/Receipt/UnitStake/UnitStake';
import PusherSubscriberRaceBetChannel from '../../../components/features/Racing/PusherSubscribers/PusherSubscriberRaceBetChannel';
import PusherSubscriberUserBet from '../../../components/features/Racing/PusherSubscribers/PusherSubscriberUserBet';
import { BREAKPOINTS } from '../../../constants/themes';
import FeatureContext from '../../../contexts/featureContext';
import { USER_ACCOUNT_BET_TRANSACTIONS } from '../../../pages/UserAccount/UserAccountPageConstants';
import { handleTaglines } from '../../../store/acl/aclActions';
import { openLoginForm } from '../../../store/authentication/authenticationActions';
import { openBetExport } from '../../../store/betExport/betExportAction';
import { deleteRM } from '../../../store/betPlacement/SRMBetPlacementActions';
import {
	cancelPartialBet,
	handleBetslipTab,
	partialBet,
	removeExotics,
	removeQuaddies,
	removeRace,
	removeSelection,
	replaceExotics,
	replaceQuaddies,
	replaceSRM,
} from '../../../store/betPlacement/betPlacementActions';
import { TAB_BETSLIP, TAB_PENDING_BETS } from '../../../store/betPlacement/betPlacementConstants';
import { PLACE_MULTI_BET } from '../../../store/betPlacement/betPlacementReducerNames';
import defaultBetAmounts from '../../../store/defaultBetAmounts/defaultBetAmountsReducer';
import { MAX_BET_AMOUNT, toteProducts } from '../../../store/entities/constants/BetConstants';
import LoginContainer from '../../Application/Login/LoginContainer';
import PendingBetContainer from '../PendingBetContainer/PendingBetContainer';
import { PendingBetCount } from './PendingBetCount';

const StyledMultiBetContainer = styled('div')(
	(props) => css`

		label: MultiBetContainer;
		display: block;

		height: calc(100vh - ${props.headerHeight}px);
		position: relative;
		pointer-events: all;
		width: 100%;
		transition: 0.25s all ease-in-out;
		top: 0;

		@media (max-width: ${BREAKPOINTS.laptopMin}px) {
			@keyframes slideInFromRight {
				0% {
					transform: translateX(100%);
				}
				100% {
					transform: translateX(0);
				}
			}

			@keyframes slideOutFromRight {
				0% {
					transform: translateX(0);
				}
				100% {
					transform: translateX(100%);
				}
			}

			top: 0;
			right: 0;
			height: 100%;
			z-index: 1999;
			display: block;
			position: fixed;
			overflow: hidden;
			pointer-events: all; //none;
			background: rgba(0, 0, 0, 0.2);
			transform: translateX(100%);
			transition: 0.25s all ease-in-out;
			padding-bottom: calc(64px + env(safe-area-inset-bottom));
			${props.started && props.isOpen && 'animation: slideInFromRight 0.25s ease-in-out forwards;'}
			${props.started && !props.isOpen && 'animation: slideOutFromRight 0.25s ease-in-out forwards;'}
			${props.started &&
				props.isOpen &&
				css`
					pointer-events: all;
					transform: translateX(0);
				`}
		}
	`,
);

const RightArrow_Icon_Slip = styled(Icon)`
	font-size: 1rem;

	&::before {
		font-size: 1rem;
		padding: 5px 5px;
	}
`;

const StyledMultiBetContainer__Content = styled('div')(
	(props) => css`
		label: MultiBetContainer__Content;
		background-color: white;
		// background-color: ${ui(props).color_2};
		border-radius: 10px;
		position: relative;
		display: flex;
		flex-direction: column;
		z-index: 1;
		height: 100%;
		box-shadow: 1px 1px 10px 1px rgb(0 0 0 / 20%);
	`,
);

const StyledMultiBetContainer__HeaderClose = styled('div')(
	(props) => css`
		label: MultiBetContainer__HeaderClose;

		border: 1px solid ${ui(props).color_2};
		border-radius: 50%;
		// width: ${spacings(props).comfortable}px;
		// height: ${spacings(props).comfortable}px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		display: none;
		@media (max-width: ${BREAKPOINTS.laptopMin}px) {
			display: block;
		}
	`,
);

const TabContainer = styled('div')(
	(props) => css`
		label: TabContainer;
		flex: 1 1 auto;
		max-height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		flex-direction: column;
		justify-content: space-between;
		max-height: 100%;
		height: 100%;
		width: 100%;
		min-width: 100%;
		overflow-x: hidden;
		overflow: hidden;
		transition: 0.4s all ease-in-out;

		${props.isEmpty &&
			css`
				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: flex-start;
			`};

		${!props.isActive
			? `//transform: translateX(100%);
				display: none;
				`
			: `//transform: translateX(0);
				display: flex;
				`}
	`,
);

const StyledMultiBetContainer__Body = styled('div')(
	(props) => css`
		label: MultiBetContainer__Body;

		flex: 1 1 auto;
		// overflow-y: auto;

		border-radius: 0px 0px 10px 10px;
		max-height: 100%;
		background-image: url(betSlipbg.png) !important;
		background-repeat: no-repeat;
		background-size: 191px 221px;
		background-position: center;
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		overflow: hidden;
	`,
);

const StyledPrice__Container = styled('div')`
	margin-bottom: 6px;
	padding: 4px;
`;

const StyledPriceLabel__Wrapper = styled('div')`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 3px;
	margin-bottom: 6px;
`;

const StyledPriceLabel__Item = styled('div')(
	(props) => css`
		font-size: 12px;
		font-weight: 500;
		padding: 8px;
		border: 1px solid ${props.selectedItem ? '#A5161A' : '#e3e3e3'};
		background-color: ${props.selectedItem ? '#A5161A' : '#e3e3e3'};
		color: ${props.selectedItem ? '#FFFFFF' : '#070B19'};
		border-radius: 3px;
		text-align: center;
		cursor: pointer;

		&:hover {
			background-color: #a5161a;
			border: 1px solid #a5161a;
			color: #ffffff;
		}
	`,
);

const StyledPriceButton__Group = styled('div')`
	display: flex;
	justify-content: space-between;
	gap: 3px;
`;

const StyledPrice__Colse = styled('button')`
	border: 1px solid #2e2e2e;
	padding: 8px 12px;
	color: #ffffff;
	background: #2e2e2e;
	font-size: 12px;
	width: 100%;
	text-align: center;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
	border-radius: 2px;

	&:hover {
		background-color: #2e2e2e;
		border: 1px solid #2e2e2e;
		color: #ffffff;
	}
`;

const StyledPrice__Clear = styled('button')`
	border: 1px solid #2e2e2e;
	padding: 8px 12px;
	border-radius: 2px;
	color: #850000
	background: none;
	font-size: 12px;
	width: 100%;
	text-align: center;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
	border-radius: 2px;

	&:hover {
		background-color: #2e2e2e;
		border: 1px solid #2e2e2e;
		color: #ffffff;
	}
`;

const StyledMultiBetContainer__EmptyMessage = styled('div')(
	(props) => css`
		label: MultiBetContainer__EmptyMessage;
        color:black;
		margin-top:100px;
        // background: white;
		padding: ${spacings(props).cozy}px;
		// margin-bottom: ${spacings(props).spacious}px;
	`,
);

const StyledRightNavContainer = styled(HeaderBar)(
	(props) => css`
		background: ${brand(props).secondary_color ? brand(props).secondary_color : DEFAULT_COLORS.df_secondary_color};
		height: 100%;
		max-height: calc(42px + env(safe-area-inset-bottom));
		padding-top: calc(env(safe-area-inset-bottom));
	`,
);

const StyledMultiBetContainer__StruckWarning = styled('div')(
	(props) => css`
		label: MultiBetContainer__StruckWarning;
		display: flex;
		flex-direction: column;
		gap: ${spacings(props).tight}px;
		margin: 12px 0 0;
		padding: 0 ${spacings(props).compact}px;
	`,
);

const StyledMultiBetContainer__ActionGroupContainer = styled('div')(
	(props) => css`
		label: MultiBetContainer__ActionGroupContainer;

		margin: ${spacings(props).compact}px 0;
		padding: 0 ${spacings(props).compact}px;
		display: flex;
		flex-direction: column;
		justify-content: end;
	`,
);

const StyledMultiBetContainer__Footer = styled('div')(
	(props) => css`
		label: MultiBetContainer__Footer;

		transition: 1s all ease-in-out;
		transform: translateY(0);
		margin-bottom: 20px;
		${!props.showLogin &&
			`
			transform: translateY(1000%);
			animation: disapear 1s ease-in-out forwards;
		`};

		@keyframes disapear {
			0% {
				height: 100%;
			}
			100% {
				height: 0px;
			}
		}

		background-color: white;
		width: 100%;
		flex-wrap: wrap;
		flex: 0 0 auto;
		padding: ${spacings(props).cozy}px ${spacings(props).compact}px;
		z-index: 2;
		border-radius: 0px 0px 10px 10px;
		&:empty {
			padding: 0;
		}
	`,
);

const StyledMultiBetContainer__LoginContainer = styled(LoginContainer)(
	(props) => css`
		label: MultiBetContainer__LoginContainer;

		margin-bottom: ${-spacings(props).cozy}px;
	`,
);

const StyledBetSlipTab = styled('ul')(
	(props) => css`
		label: BetSlipTab;

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: 50px;
		align-items: stretch;
		margin: 0;
		padding: 0;
	`,
);

const StyledBetSlipTabItem = styled('li')(
	(props) => css`
		label: BetSlipTabItem;

		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		list-style: none;
		width: 50%;
		padding: 16px 8px;
		cursor: pointer;
		transition: 0.3s all ease-in-out;
		margin-bottom: -1px;

		${props.isActive
			? `border-bottom: 2px solid ${brand(props).color_4};`
			: `border-bottom: 2px solid ${ui(props).color_4};`}
	`,
);

const TabItemText = styled(Text)`
	${(props) =>
		css`
			${props.hasPending ? `color: ${brand(props).color_4};` : 'color: black;'}
		`}
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	text-align: center;
	padding: 0 5px;
	font-size: ${(props) => props.size};
	line-height: 1.2;
`;

class NewMultiBetContainer extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** Function to build up the shape of the multi bets */
		buildMultiBets: PropTypes.func.isRequired,

		/** Function to build up the shape of the multi bets */
		buildMultiSingleBets: PropTypes.func.isRequired,

		buildEachWaySingleBets: PropTypes.func.isRequired,

		/** Function for creating a route to a racing selection */
		//buildRouteToRace: PropTypes.func.isRequired,
		buildWithDateGRSRouteToRace: PropTypes.func.isRequired,

		/** Function for creating a route to a sport selection */
		buildSportRoute: PropTypes.func.isRequired,

		/** Load the entity data for saved selections in the multi slip */
		loadDataForSelections: PropTypes.func.isRequired,

		/** Handle closing the multi bet slip */
		onClose: PropTypes.func.isRequired,

		/** Determine the label for the racing selection, based on the betType */
		getRacingBetTypeLabel: PropTypes.func.isRequired,

		/** Function for determining the bet label for a sport selection */
		getSportBetLabel: PropTypes.func.isRequired,

		/** Handle bet placement */
		handlePlaceBet: PropTypes.func.isRequired,

		/** Handle removing a single bet selection from the multi bet slip */
		handleRemoveSingleSelection: PropTypes.func.isRequired,

		/** Handle updating a selection on the multi bet slip */
		handleReplaceSingleSelection: PropTypes.func.isRequired,

		/** Handle updating a leg on the multi bet slip */
		handleReplaceSingleLeg: PropTypes.func.isRequired,

		/** Handle updating all selections */
		handleReplaceAllSelections: PropTypes.func.isRequired,

		/** Handle when the rebet button is clicked */
		handleRebet: PropTypes.func.isRequired,

		/** Handle when the Undo Bet button is clicked */
		handleUndoBetSlip: PropTypes.func.isRequired,

		/** Function to update bets and legs when the bet entities change */
		handleMultiBetsPlaced: PropTypes.func.isRequired,

		/** Handle being able to set an Error Message */
		setErrorMessage: PropTypes.func.isRequired,

		/** Change the racing bet type key to it's short form */
		shortenRacingBetType: PropTypes.func.isRequired,

		/** Function to validate the multi server side */
		validateMulti: PropTypes.func.isRequired,

		/** Navigate to the selected page */
		navigate: PropTypes.func.isRequired,

		/** Whether the application is running in single wallet mode or not */
		isSingleWallet: PropTypes.bool.isRequired,

		/** Flag whether or not we must have a confirmation stage for bet placement */
		confirmBetPlacement: PropTypes.bool.isRequired,

		/**	Limit the user bet amount to their balance */
		limitUserBetAmount: PropTypes.bool.isRequired,

		/** Allow for navigation actions */
		useRouting: PropTypes.bool,

		/** If the bet slip is under a loading state, */
		isLoading: PropTypes.bool,

		/** If the bet slip is open or not */
		isMultiBetSlipOpen: PropTypes.bool,

		/** Store */
		boxed_flag: PropTypes.bool,
		free_credit_flag: PropTypes.bool,
		useBonuBets: PropTypes.bool,
		origin: PropTypes.number,

		/** The list of placed bets */
		bets: PropTypes.array,

		/** The list of multi legs */
		legs: PropTypes.array,

		/** Any error message, or list of error messages */
		errorMessage: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),

		/** Option to hide the multi container header */
		hideHeader: PropTypes.bool,

		/** The list of selections in the multi */
		selections: PropTypes.array,

		/** The list of bets in our multi, from the entity slice */
		betSelections: PropTypes.array,

		/** The list of selections in our multi, from the entity slice */
		entitySelections: PropTypes.array,

		/** Extra classes */
		className: PropTypes.string,

		/** List of racing bet types */
		racingBetTypes: PropTypes.array,

		/** Function for tracking */
		track: PropTypes.func,

		/** The currently authenticated user */
		user: PropTypes.object,

		/** Is the app loaded on a phone type device */
		isPhoneDevice: PropTypes.bool,

		/** If the login form should be displayed or not */
		showLogin: PropTypes.bool,

		/** The default bet amount, set from the store */
		defaultBetAmount: PropTypes.number,

		/** User's balance */
		account_balance: PropTypes.number,

		isUserLoggedIn: PropTypes.bool,

		lyb: PropTypes.array,

		liability: PropTypes.number,

		selectedPageKey: PropTypes.string,

		/** The number of bets in bet slip multi */
		count_bets: PropTypes.number,

		/** Action for toggling the bet slip */
		onBetSlipClick: PropTypes.func.isRequired,
	};

	static defaultProps = {
		useRouting: true,
		isLoading: false,
		isMultiBetSlipOpen: false,
		boxed_flag: false,
		free_credit_flag: false,
		origin: 0,
		bets: [],
		legs: [],
		count_bets: 0,
		errorMessage: null,
		hideHeader: false,
		selections: [],
		betSelections: [],
		entitySelections: [],
		lyb: '',
		liability: '',
		className: '',
		racingBetType: '',
		racingBetTypes: [
			{
				text: RACING_BET_TYPE_WIN_SHORT,
				value: RACING_BET_TYPE_WIN,
			},
			{
				text: RACING_BET_TYPE_PLACE_SHORT,
				value: RACING_BET_TYPE_PLACE,
			},
			{
				text: RACING_BET_TYPE_EACHWAY_SHORT,
				value: RACING_BET_TYPE_EACHWAY,
			},
		],
		track: () => {},
		user: null,
		isPhoneDevice: false,
		showLogin: true,
		defaultBetAmount: 0,
		account_balance: 0,
		isUserLoggedIn: false,
		selectedPageKey: null,
		useBonuBets: false,
	};

	/**
	 * Total bet amount from all bets and legs
	 *
	 * @returns {number}
	 */
	// eslint-disable-next-line react/sort-comp
	static calculateTotalBetAmount = (newProps, offBonus = true) => {
		const { legs, selections, SRMs, quaddies, exotics } = newProps;
		let amount = 0;
		amount = selections.reduce((total, selection) => {
			if (!!selection.free_credit_flag == offBonus) return 0;

			const amount =
				selection.product_id === 19 ||
				selection.product_id === 20 ||
				selection.product_id === 21 ||
				selection.product_id === 22
					? parseInt(selection.user_amount)
					: parseInt((selection.amount = selection.amount ? selection.amount : newProps.defaultBetAmount));
			return amount
				? selection.racingBetType === RACING_BET_TYPE_EACHWAY
					? total + amount * 2
					: total + amount
				: total;
		}, amount);
		amount = legs.reduce((total, leg) => {
			if (!!leg.free_credit_flag == offBonus) return 0;

			const amount = parseInt(leg.amount);

			return Number.isInteger(amount) ? total + amount : total;
		}, amount);

		amount = SRMs.reduce((total, SRM) => {
			if (!!SRM.free_credit_flag == offBonus) return 0;
			const amount = parseInt(SRM.amount);
			return Number.isInteger(amount) ? total + amount : total;
		}, amount);
		amount = quaddies.reduce((total, SRM) => {
			if (!!SRM.free_credit_flag == offBonus) return 0;
			const amount = parseInt(SRM.amount);
			return Number.isInteger(amount) ? total + amount : total;
		}, amount);

		amount = exotics.reduce((total, exotic) => {
			if (!!exotic.free_credit_flag == offBonus) return 0;
			const amount = parseInt(exotic.amount);
			return Number.isInteger(amount) ? total + amount : total;
		}, amount);

		return amount;
	};

	constructor(props) {
		super(props);

		// Open up all legs by default
		let legState = {};
		for (let i = 2; i <= 20; i++) {
			legState[i] = {
				open: true,
			};
		}
		const totalNormalBetAmount = NewMultiBetContainer.calculateTotalBetAmount(props, true);
		const totalBonusBetAmount = NewMultiBetContainer.calculateTotalBetAmount(props, false);
		const totalBetAmount = totalBonusBetAmount + totalNormalBetAmount;

		this.state = {
			legState,
			totalBetAmount,
			totalNormalBetAmount,
			totalBonusBetAmount,
			isMobile: props.isPhoneDevice,
			isClosePriceLabel: true,

			/**
			 * Indicate that the bet is in a confirming stage. This means that on the next click of the button it will place the bet
			 * Any alteration to the bet (i.e. amount, legs etc) should set this flag to false
			 */
			confirmingBet: false,
			betsLink: USER_ACCOUNT_BET_TRANSACTIONS,
			isSinglesCollapsed: false,
			applicationElement: document.getElementById('application'),
			position: 'fixed',
			eachWayBets: false,
			eachWaysBetsArray: [],
			shouldPulse: [],

			notificationPending: [],
			modalOpen: false,
			selectedCupomBet: null,
			useBonuBets: false,
			touchStart: null,
			touchEnd: null,
			legsSelection: this.props.legs,
			selectedBet: null, //
			started: false,
			checkedPending: false,
		};

		this.onTouchMove = this.onTouchMove.bind(this);
		this.onTouchStart = this.onTouchStart.bind(this);
		this.onTouchEnd = this.onTouchEnd.bind(this);
		this.handleColsePrice = this.handleColsePrice.bind(this);
		this.handleSelectedBetPrice = this.handleSelectedBetPrice.bind(this);
		this.handleSelectedBet = this.handleSelectedBet.bind(this);
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		const totalNormalBetAmount = NewMultiBetContainer.calculateTotalBetAmount(nextProps, true);
		const totalBonusBetAmount = NewMultiBetContainer.calculateTotalBetAmount(nextProps, false);
		const totalBetAmount = totalBonusBetAmount + totalNormalBetAmount;

		if (
			totalBetAmount !== prevState.totalBetAmount ||
			totalNormalBetAmount !== prevState.totalNormalBetAmount ||
			totalBonusBetAmount !== prevState.totalBonusBetAmount
		) {
			nextProps.setErrorMessage();
			return {
				totalBetAmount,
				totalNormalBetAmount,
				totalBonusBetAmount,
			};
		}

		return null;
	};
	

	componentDidMount() {
		this.props.loadDataForSelections(this.props.selections);
	}

	

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.selections.length && this.props.selections.length) {
			this.checkPriceChange(prevProps.selections, this.props.selections);
		}
		if (
			this.props.betSelections &&
			prevProps.betSelections &&
			this.props.betSelections.length &&
			prevProps.betSelections.length
		) {
			this.checkBetsChange(prevProps.betSelections, this.props.betSelections);
		}

		if (
			!this.props.isSingleWallet &&
			this.props.userId &&
			prevState.totalNormalBetAmount > this.props.account_balance &&
			!this.props.bets.length &&
			!prevProps.bets.length &&
			!this.props.errorMessage
		) {
			if ((this.props.credit_limit || 0) + this.props.account_balance < prevState.totalNormalBetAmount) {
				this.props.setErrorMessage(this.getExceedBetAmountMessage());
			}
		} else if (
			!this.props.isSingleWallet &&
			this.props.userId &&
			this.props.bonus_bet_enabled &&
			prevState.totalBonusBetAmount > this.props.free_credit_balance &&
			!prevProps.bets.length &&
			!this.props.errorMessage
		) {
			this.props.setErrorMessage(this.getExceedBetAmountMessage());
		}
		// if an selection was added should display multis
		if (prevProps.selections.length < this.props.selections.length) {
			this.props.handleReplaceAllSelections(this.props.selections);
		}

		if (prevProps.legs.length != this.props.legs.length && this.props.legs.length > 0) {
			this.clearSelectionsStake(this.props.selections);
		}

		if (
			prevProps.legs.length != this.props.legs.length &&
			this.props.legs.length > 0 &&
			!this.state.isSinglesCollapsed
		) {
			this.handleToggleSingleSelections();
		}

		if (
			prevProps.legs.length != this.props.legs.length &&
			this.props.legs.length === 0 &&
			this.state.isSinglesCollapsed
		) {
			this.handleToggleSingleSelections();
		}
		if (prevProps.userId && this.props.userId != prevProps.userId) {
			this.handleClearBetSlip();
		}

		if (this.props.isMultiBetSlipOpen != prevProps.isMultiBetSlipOpen && !this.state.started) {
			this.setState({ started: true });
		}

		if (!this.props.checkedPending && this.props.userBets.length != prevProps.userBets.length) {
			this.setState({ checkedPending: true });
			if (this.props.userBets.length > 0) {
				this.props.handleTab(TAB_PENDING_BETS);
			}
		}
	}

	handleColsePrice() {
		this.setState({ isClosePriceLabel: false });
	}


	getBetRealPrice = (selectedBet) => {
		let item = null;
		if (selectedBet.group === 'selections') {
			item = this.getSelectionWithOddsType(selectedBet.id, selectedBet.selectedBetType, selectedBet.product_id);
		}
		if (selectedBet.group === 'legs') item = this.props.legs.find((item) => item.id === selectedBet.id);
		if (selectedBet.group === 'SRMs') item = this.props.SRMs.find((item) => item.id === selectedBet.id);
		if (selectedBet.group === 'quaddies') item = this.props.quaddies.find((item) => item.id === selectedBet.id);
		if (selectedBet.group === 'exotics') item = this.props.exotics.find((item) => item.id === selectedBet.id);

		return item && item.amount == selectedBet.amount ? selectedBet.amount : item.amount;
	};

	handleSelectedBetPrice(price) {
		let { selectedBet } = this.state;

		if (selectedBet) {
			if (!price) selectedBet.amount = 0;
			else {
				selectedBet.amount = this.getBetRealPrice(selectedBet);
				if (!selectedBet.amount) selectedBet.amount = 0;
				selectedBet.amount += price * 100;
				this.handleSelectedBet(selectedBet);
			}

			if (selectedBet.group === 'selections') {
				this.handleStakeChange(selectedBet.id, selectedBet.amount, selectedBet.racingBetType, selectedBet.product_id);
			} else if (selectedBet.group === 'legs') {
				this.handleLegStakeChange(selectedBet.id, selectedBet.amount);
			} else if (selectedBet.group === 'SRMs') {
				this.handleSRMStakeChange(selectedBet.id, selectedBet.amount);
			} else if (selectedBet.group === 'quaddies') {
				this.handleQuaddieStakeChange(selectedBet.id, selectedBet.amount);
			} else if (selectedBet.group === 'exotics') {
				this.handleExoticStakeChange(selectedBet.id, selectedBet.amount);
			}
		}
	}

	
	handleSelectedBet = (bet) => {
		this.setState({ selectedBet: bet });
	};


	onTouchMove(e) {
		this.setState({ touchEnd: e.targetTouches[0].clientX });
	}

	onTouchStart(e) {
		this.setState({ touchEnd: null });
		this.setState({ touchStart: e.targetTouches[0].clientX });
	}

	onTouchEnd() {
		const minSwipeDistance = 50;
		if (!this.state.touchStart || !this.state.touchEnd) return;
		const distance = this.state.touchStart - this.state.touchEnd;
		//const isLeftSwipe = distance > minSwipeDistance
		const isRightSwipe = distance < -minSwipeDistance;
		if (isRightSwipe) this.props.onClose(this.props.isMultiBetSlipOpen);
		//if (isLeftSwipe || isRightSwipe) console.log('swipe', isLeftSwipe ? 'left' : 'right')
	}

	checkBalance = () => {
		const { totalNormalBetAmount, totalBonusBetAmount } = this.state;
		const { account_balance, credit_limit, free_credit_balance, bonus_bet_enabled } = this.props;
		if (account_balance <= 0 || account_balance < totalNormalBetAmount) {
			if ((credit_limit || 0) + account_balance < totalNormalBetAmount) {
				this.props.setErrorMessage(this.getExceedBetAmountMessage());
				return;
			}
		}

		if (bonus_bet_enabled && free_credit_balance < totalBonusBetAmount) {
			this.props.setErrorMessage(this.getExceedBetAmountMessage());
			return;
		}
	};
	

	/**
	 * Returns the translated exceed error message string
	 */
	getExceedBetAmountMessage = () => this.props.t('MultiBetContainer__ExceedMessage');

	/**
	 * Clear the main error for the multi bet slip
	 */
	clearErrorMessage = () => {
		this.props.setErrorMessage([]);
	};

	/**
	 * Compare our selections, with the matching selections in the entities list
	 *
	 * @param oldSelections
	 * @param newSelections
	 */
	checkPriceChange = (oldSelections, newSelections = []) => {
		// Loop through the new selections
		// newSelections.forEach((newSelection) => {
		// 	if (newSelection) {
		// 		// Grab the matching selection from the entities list
		// 		const oldSelection = oldSelections.find(
		// 			(selection) => newSelection.id === selection.id && newSelection.product_id === selection.product_id,
		// 		);
		// 		if (oldSelection) {
		// 			let selection = oldSelection;
		// 			// Check that the price has changed
		// 			if (selection.isRacing) {
		// 				selection = this.checkPriceChangeRacing(selection, newSelection);
		// 			} else {
		// 				selection = this.checkPriceChangeSport(selection, newSelection.price);
		// 			}
		// 			// If the odds have changed, update the store and rebuild the legs
		// 			if (selection.oddsChanged) {
		// 				selection.oddsChanged = false;
		// 				this.props.handleReplaceSingleSelection(selection, true);
		// 			}
		// 		}
		// 	}
		// });
	};

	/**
	 *
	 * @param oldBetSelections
	 * @param betSelections
	 */
	checkBetsChange = (oldBetSelections = [], betSelections = []) => {
		// Loop through the selections we receive from the entities slice
		const hasBetChanged =
			!betSelections ||
			oldBetSelections.length !== betSelections.length ||
			oldBetSelections.some(
				(bet, index) => !betSelections[index] || !bet || bet.status !== betSelections[index].status,
			);

		if (hasBetChanged) {
			// Ensure that there are valid bet objects in the betSelections, as the denormalized entities will
			// return 'undefined' if it can't find any matching bets for the provided keys
			const newBetSelections = betSelections.filter((bet) => bet && bet.id);
			if (newBetSelections.length === betSelections.length) {
				this.props.handleMultiBetsPlaced(betSelections);
			}
		}
	};

	/**
	 * Check that a sporting bet has changed in price and update accordingly
	 *
	 * @param selection
	 * @param newPrice
	 * @returns {*}
	 */
	checkPriceChangeSport = (selection, newPrice) => {
		let newSelection = { ...selection };
		if (newPrice !== selection.price) {
			newSelection.oddsChanged = true;
			newSelection.oldPrice = newSelection.price;
			newSelection.price = newPrice;

			// Only show an odds change message if the price has decreased
			if (newPrice < newSelection.price) {
				newSelection.warning = this.props.t('MultiBetContainer__OddsChange', {
					oldPrice: newSelection.oldPrice.toFixed(2),
					newPrice: newPrice.toFixed(2),
				});
			}
		}

		return newSelection;
	};

	/**
	 * Check that a racing bet has changed in price and update accordingly
	 *
	 * @param selection
	 * @param newSelection
	 */
	checkPriceChangeRacing = (selection, newSelection = []) => {
		// Set the racing bet types for determining the price
		const racingBetTypes =
			selection.racingBetType === RACING_BET_TYPE_EACHWAY
				? [RACING_BET_TYPE_PLACE, RACING_BET_TYPE_WIN]
				: [selection.racingBetType];

		// Loop through the racing bet types
		return racingBetTypes.reduce((sel, racingBetType) => {
			const oldPrice = sel[racingBetType];

			// Get the price from the entity slice
			const newPrice = newSelection[racingBetType];
			if (newPrice) {
				const oldOdds = oldPrice.odds;
				const newOdds = newPrice.odds;

				// Check if the price has changed
				if (newOdds !== oldOdds) {
					sel.oddsChanged = true;
					// Only throw an error if it is fixed odds and the price has decreased
					if (newPrice.fixed && oldPrice.fixed) {
						sel.warning = this.props.t('MultiBetContainer__OddsChange', {
							oldPrice: oldOdds.toFixed(2),
							newPrice: newOdds.toFixed(2),
						});
					}
					// Update with the new odds
					sel[racingBetType] = newPrice;
					// this.pulseForPriceChange(sel.id, racingBetType, newOdds > oldOdds);
				}
			}

			return sel;
		}, selection);
	};

	/**
	 * Pulse the selection if the price has changed
	 * @param id
	 * @param acingBetType
	 * @param bigger
	 */

	/**
	 * Toggle the single selection open or closed
	 */
	handleToggleSingleSelections = () => {
		this.setState({ isSinglesCollapsed: !this.state.isSinglesCollapsed });
	};

	/**
	 * Toggle leg item open or closed
	 *
	 * @param id
	 */
	handleToggleLeg = (id) => {
		this.setState({
			legState: {
				...this.state.legState,
				[id]: {
					...this.state.legState[id],
					open: !this.state.legState[id].open,
				},
			},
		});
	};

	/**
	 * Handler for when the bet slip is closed
	 */
	handleBetSlipClose = () => {
		//this.props.track('BetSlipMultis', 'Click', 'Close');
		this.handleClose();
	};

	handleClearBetSlip = () => {
		this.props.track('Click', 'Change Bet');
		this.setState({ confirmingBet: false });
		//this.props.track('BetSlipMultis', 'Click', 'Cancel Bet');
		this.props.handleUndoBetSlip();
	};
	/**
	 * Handle clicking the opposing action to confirm bet (was called undo at one stage)
	 *
	 * If the multi bet is in the confirming stage then it should unset that value and do nothing else. Otherwise
	 * it should clear the bet slip and close the drawer
	 */
	handleUndoBetSlip = () => {
		this.clearErrorMessage();
		if (this.state.confirmingBet) {
			//this.props.track('Click', 'Change Bet');
			this.setState({ confirmingBet: false });
			return;
		}

		this.props.track('BetSlipMultis', 'Click', 'Cancel Bet');
		this.handleClose();
		this.props.handleUndoBetSlip();
	};

	/**
	 * Close the multi slip if bets are not being referred
	 */
	handleClose = () => {
		if (!this.isBetsReferred()) {
			// check phone device
			if (this.props.isPhoneDevice) {
				// for mobile
				this.props.onClose(this.props.isMultiBetSlipOpen);
			} else {
				// for desktop
				//this.props.onClose(!this.props.isMultiBetSlipOpen); // expand can not close on large device
				this.props.onClose(this.props.isMultiBetSlipOpen);
			}
		}
	};

	/**
	 * Build the bet selection item props for a sport selection
	 *
	 * @param selection
	 * @returns {{isRacing: boolean, singleOnly: (boolean|*), selectionDisplay, betTypeLabel, eventDisplay, price: number, id: number, stake: (Number|*)}}
	 */
	buildSportSelectionItem = (selection) => {
		return {
			isRacing: false,
			singleOnly: selection.singleOnly,
			selectionDisplay: selection.name,
			betTypeLabel: this.props.getSportBetLabel(selection),
			racingBetType: null,
			eventDisplay: selection.event ? selection.event.name : '',
			price: selection.price * 100,
			id: selection.id,
			selection_id: selection.id,
			stake: selection.amount,
			invalid: selection.invalid,
			warning: selection.warning,
		};
	};

	/**
	 * get selected selection details
	 * @param selectionId
	 */
	filterBetSelection = (selectionId) => {
		const { selections } = this.props;
		const topSelections = selections[0].race.selection;

		const selectedselection = topSelections.find((racingBetSelection) => racingBetSelection.id === selectionId);
		return selectedselection;
	};

	/**
	 * Build the bet selection item props for a racing selection
	 * Push To Selection Array Ashan
	 * @param selection
	 * @returns {{isRacing: boolean, singleOnly: (boolean|*), selectionDisplay, betTypeLabel, eventDisplay, price: number, placePrice: number, racingBetType: *, id: number, stake: (Number|*)}}
	 */

	buildBetName = (product_id) => {
		if (product_id === 15) {
			return 'Starting Price';
		}

		if (product_id === 16) {
			return 'Win or Place';
		}

		return 'Win or Place';
	};

	buildRacingSelectionItem = (selection) => {
		const { racingBetType, bet_type } = selection;
		const nonExoticBetType = this.props.shortenRacingBetType(racingBetType);
		// const eventDisplay =

		let price = null;
		let bet_code = null;
		if (nonExoticBetType == 'EW') {
			price = (selection.win.odds + selection.place.odds) * 100;
			bet_code = selection.win.code;
		} else {
			price =
				isNaN(selection[bet_type].odds) || !selection[bet_type].odds
					? selection[bet_type].code
					: selection[bet_type].odds * 100;
			bet_code = selection[bet_type].code;
		}

		let winPrice = isNaN(selection.win.odds) || !selection.win.odds ? selection.win.code : selection.win.odds * 100;
		let placePrice =
			isNaN(selection.place.odds) || !selection.place.odds ? selection.place.code : selection.place.odds * 100;
		if (isNaN(price)) price = null;
		if (isNaN(winPrice)) winPrice = null;
		if (isNaN(placePrice)) placePrice = null;

		const hasGBProduct =
			selection.race && selection.race.products && selection.race.products.find((p) => p.product_code == 'GB');
		return {
			race_id: selection.race_id,
			meeting_id: selection.meeting_id,
			isRacing: true,
			singleOnly: selection.singleOnly,
			selectionDisplay: `#${selection.selection_number} ${selection.name}`,
			betTypeLabel: this.props.getRacingBetTypeLabel(racingBetType, selection, this.props.t),
			selectedBetType: racingBetType,
			product_id: selection.product_id,
			isBumped: selection.product_id === 13,
			product_name: this.buildBetName(selection.product_id),
			eventDisplay:
				selection.race.number && selection.race.meeting_name
					? `R${selection.race.number} ${selection.race.meeting_name}`
					: `R${selection.race.race_number} ${selection.race.meeting_name}`,
			price: price,
			winPrice,
			placePrice,
			racingBetType: nonExoticBetType,
			id: selection.id,
			selection_id: selection.id,
			name: selection.name,
			silk: selection.silk,
			stake: selection.amount,
			//stake: selection.amount === 0 || selection.product_id === 19 || selection.product_id === 20 || selection.product_id === 21 ? selection.win.liability * 100 : selection.amount,
			liability: selection.win.liability,
			invalid: selection.invalid,
			warning: selection.warning,
			eachwayDisabled: !selection.win.productId || !selection.place.productId,
			user_bet_amount: selection.user_amount,
			meeting_name: selection.race.meeting_name,
			number: selection.race.number ? selection.race.number : selection.race.race_number,
			selection_number: selection.selection_number,
			raceType: selection.race.type,
			startTime: selection.race.start_datetime || selection.race.start_date,
			bet_code: bet_code,
			selection_count: selection.race.selection_count,
			// date: selection.
			win: selection.win,
			place: selection.place,
			free_credit_flag: selection.free_credit_flag,
			eachway: {
				amount: selection.amount,
				id: selection.id,
				odds: selection.place.odds + selection.win.odds,
				win_dividend: selection.win.odds,
				win_product: selection.win.productId,
				place_dividend: selection.place.odds,
				place_product: selection.place.productId,
			},
			bet_from: selection.bet_from,
			hasGBProduct,
		};
	};

	defaultBet = () => {
		return {
			defaultBetAmounts: defaultBetAmounts,
		};
	};
	/**
	 * Determine how to build the bet selection props
	 */
	buildSelectionItems = () => {
		const { selections } = this.props;
		return selections.map((selection) => {
			if (selection.isRacing) {
				return this.buildRacingSelectionItem(selection);
			}
			return this.buildSportSelectionItem(selection);
		});
	};

	buildUserSelectionItem = () => {
		const { user } = this.props;

		return user.map((user) => {
			return this.buildUserSelectionItem(user);
		});
	};

	/**
	 * Returns the specified leg from the list of legs
	 *
	 * @param id
	 */
	getLeg = (id) => {
		return this.props.legs.find((leg) => leg.id === id);
	};

	getSRM = (id) => {
		return this.props.SRMs.find((srm) => srm.id === id);
	};

	/**
	 * Returns the specified selection from the list of selections
	 *
	 * @param id
	 */
	getSelection = (id) => {
		return this.props.selections.find((selection) => selection.id === id);
	};

	getSelectionWithOddsType = (id, type, product_id) => {
		if (
			this.props.selections[0].product_id === 23 ||
			this.props.selections[0].product_id === 24 ||
			this.props.selections[0].product_id === 25 ||
			this.props.selections[0].product_id === 26 ||
			this.props.selections[0].product_id === 27 ||
			this.props.selections[0].product_id === 28 ||
			this.props.selections[0].product_id === 29 ||
			this.props.selections[0].product_id === 30 ||
			this.props.selections[0].product_id === 31 ||
			this.props.selections[0].product_id === 33 ||
			this.props.selections[0].product_id === 34 ||
			this.props.selections[0].product_id === 35 ||
			this.props.selections[0].product_id === 36
		) {
			return this.props.selections.find((selection) => selection.id === id);
		} else {
			return this.props.selections.find(
				(selection) => selection.id === id && selection.racingBetType === type && selection.product_id === product_id,
			);
		}
	};

	/**
	 * Handler for when the multi checkbox is toggled
	 *
	 * @param id
	 */
	handleMultiToggle = (id) => {
		const selection = this.getSelection(id);
		if (selection) {
			const newSelection = {
				...selection,
				singleOnly: !selection.singleOnly,
			};

			this.props.handleReplaceSingleSelection(newSelection, true);
			//this.props.validateMulti();
		}
	};

	handleLegStakeChange = (id, value) => {
		const leg = this.getLeg(id);
		if (leg) {
			this.setState({ confirmingBet: false });
			const val = this.limitStakeAmount(value, leg.stake, leg.numberOfBets);
			const newLeg = {
				...leg,
				stake: val,
				amount: val * leg.numberOfBets,
			};
			this.props.handleReplaceSingleLeg(newLeg);
		}
	};

	handleSRMStakeChange = (id, value) => {
		const srm = this.getSRM(id);

		if (srm) {
			this.setState({ confirmingBet: false });
			// const val = this.limitStakeAmount(value,srm.stake, srm.numberOfBets);
			const val = this.limitStakeAmount(value);
			const newSRM = {
				...srm,
				stake: val,
				amount: val,
			};
			this.props.handleReplaceSingleSRM(newSRM);
		}
	};

	/**
	 * Handler for when the stake is changed for a single bet
	 * Change By Ashan
	 * @param id
	 * @param value
	 * @param racingBetType
	 */
	handleStakeChange = (id, value, racingBetType, product_id) => {
		const selection = this.getSelection(id);
		this.setState({ confirmingBet: false });
		this.modifySelectionField({
			id,
			value: this.limitStakeAmount(value, selection.amount),
			fieldName: 'amount',
			racingBetType,
			product_id,
		});
	};

	clearSelectionsStake = (selections) => {
		for (const s of selections) {
			const types = {
				win: 'W',
				place: 'P',
				eachway: 'EW',
			};

			this.handleStakeChange(s.id, 0, types[s.racingBetType] || s.racingBetType, s.product_id);
		}
	};
	/**
	 * Limit the stake amount to the user's account balance.
	 * Use the divisor for calculating the remainder based on the number of bets for legs, or number of singles with the bulk updater
	 *
	 * @param value
	 * @param ignoreAmount
	 * @param divisor
	 * @returns {*}
	 */
	limitStakeAmount = (value, ignoreAmount = 0, divisor = 1) => {
		const { user, setErrorMessage, isSingleWallet, limitUserBetAmount, account_balance } = this.props;

		let amount = parseInt(value) || 0;
		if (limitUserBetAmount) {
			const maxAmount = user && !isSingleWallet ? account_balance : MAX_BET_AMOUNT;
			let remainder = maxAmount - this.state.totalBetAmount;

			// Since state is updated when an input is changed, we need to exclude the previous input amount from the remainder
			if (ignoreAmount) {
				remainder += ignoreAmount * divisor;
			}

			// If there is no remainder left
			if (remainder <= 0) {
				amount;
			} else if (amount * divisor > remainder && !isSingleWallet && remainder === amount) {
				// If the total amount is greater than the remainder
				amount = remainder / divisor;
				setErrorMessage(this.getExceedBetAmountMessage());
			}
		}

		// Final validation on the amount
		amount = parseInt(amount) || 0;
		if (amount < 0) {
			amount = 0;
		}
		return amount;
	};

	/**
	 * Handler for when the bulk stake is changed for all single bets
	 *
	 * @param name
	 * @param value
	 */
	handleBulkStakeChange = (name, value) => {
		const newSelections = this.props.selections.map((selection) => {
			//console.log('am', value);
			selection.amount = this.limitStakeAmount(value, selection.amount, this.props.selections.length);
			return selection;
		});
		this.setState({ confirmingBet: false });
		this.props.handleReplaceAllSelections(newSelections);
	};

	/**
	 * Handle when one of the Racing Bet Type buttons is clicked on
	 *
	 * @param id
	 * @param betType
	 */
	handleBetTypeChange = (id, betType, oldBetType, product_id, isTote) => {
		const nonExoticBetObj = this.props.racingBetTypes.find((racingBetType) => racingBetType.text === betType);
		const nonExoticBetType = nonExoticBetObj ? nonExoticBetObj.value : RACING_BET_TYPE_WIN;
		let selection = this.props.selections.find(
			(selection) =>
				selection.id === id && selection.product_id === product_id && selection.racingBetType === oldBetType,
		);

		if (!selection) return;

		if (isTote) {
			product_id = selection.totes[nonExoticBetType];
		}

		// If the new type already exists in the bet slip, then do nothing
		const newSelectionExist = this.props.selections.find(
			(selection) =>
				selection.id === id && selection.product_id === product_id && selection.racingBetType === nonExoticBetType,
		);

		if (newSelectionExist) return;
		const newSelection = this.props.updateSelection(selection, {
			...selection,
			bet_type: nonExoticBetType,
			product_id: product_id,
		});
		if (this.state.selectedBet) {
			this.handleSelectedBet({
				...this.state.selectedBet,
				...this.buildRacingSelectionItem(newSelection),
				amount: 0,
			});
		}
	};

	/**
	 * Handle when we are dismissing a warning from a selection
	 *
	 * @param id
	 */
	handleDismissSelection = (id) => {
		const selection = this.getSelection(id);

		// Remove the selection if it is marked as invalidFatal
		if (selection.invalidFatal) {
			this.props.handleRemoveSingleSelection(id);
		} else {
			const newSelection = {
				...selection,
				warning: '',
			};

			this.props.handleReplaceSingleSelection(newSelection);
		}
	};

	/**
	 * Handle when we are removing a selection from the bet slip entirely
	 *
	 * @param id
	 */
	handleRemoveSingleSelection = (selection) => {
		let { selectedBet } = this.state;

		if (selectedBet && selectedBet.id === selection.id) {
			selectedBet = null;
			this.setState({ selectedBet });
		}

		this.props.handleRemoveSingleSelection(selection);
	};

	/**
	 * Handle when a selection is clicked on
	 *
	 * @param id
	 */
	handleClickSelection = (id) => {
		if (this.props.useRouting) {
			const selection = this.getSelection(id);
			if (selection) {
				if (selection.isRacing) {
					const meeting = {
						id: selection.meeting_id,
						name: selection.race.meeting_name,
						type: RACING_TYPES_LOOKUP[selection.type_code],
						date: selection.race.start_datetime,
					};

					//this.props.navigate(this.props.buildRouteToRace(meeting, selection.race), { trigger: true });
					this.props.navigate(
						this.props.buildWithDateGRSRouteToRace(
							selection.type_code,
							serverDateTime(selection.race.start_datetime).format('YYYY-MM-DD'),
							meeting,
							selection.race,
						),
						{ trigger: true },
					);
				} else {
					if (
						selection.event &&
						selection.event.competition &&
						selection.event.competition.base_competition &&
						selection.event.competition.base_competition.sport
					) {
						const sport = {
							name: selection.event.competition.base_competition.sport.name,
						};
						const competition = {
							id: selection.event.competition.id,
							name: selection.event.competition.name,
						};
						const event = {
							id: selection.event.id,
							name: selection.event.name,
						};

						this.props.navigate(this.props.buildSportRoute(sport, competition, event), { trigger: true });
					}
				}
			}
		}
	};

	/**
	 * Modify a field in the selection object and dispatch an update for that selection
	 *
	 * @param id
	 * @param fieldName
	 * @param value
	 * @param rebuildLegs
	 * @param oldBetType
	 * @param racingBetType
	 * Change Oddds Hear Ashan
	 */
	modifySelectionField = ({
		id,
		product_id,
		fieldName,
		value,
		racingBetType,
		oldBetType = null,
		rebuildLegs = false,
	}) => {
		let select_bet_type = '';

		if (racingBetType === 'W') {
			select_bet_type = 'win';
		} else if (racingBetType === 'EW') {
			select_bet_type = 'eachway';
		} else {
			select_bet_type = 'place';
		}

		let selection;
		selection = this.getSelectionWithOddsType(id, select_bet_type, product_id);

		if (selection) {
			const newSelection = {
				...selection,
				[fieldName]: value,
			};
			this.props.handleChangeSingleSelection(selection, newSelection, rebuildLegs);
		}
	};

	/**
	 * Returns whether there are any bets that have been referred
	 *
	 * @returns {*}
	 */
	isBetsReferred = () => {
		const { bets } = this.props;
		// return !!(bets.length && bets[0].bets.length);
		return false;
	};

	/**
	 * Keep the user' selections
	 */
	onKeepBets = () => {
		const { selections, price_bump_enabled } = this.props;
		for (const selection of selections) {
			if (selection.product_id === 13 && !price_bump_enabled) {
				this.handleUpdateSelection(selection, 'priceBump');
			}
		}

		this.props.handleRebet();
	};

	onCacheOutBets = () => {};

	handleUpdateSelection = (selection, type) => {
		const { product_id } = selection;
		let bet_type;
		let selected_product_id;
		if (type == 'priceBump') {
			bet_type = 'win';

			if (product_id == 16) {
				selected_product_id = 13;
			} else {
				selected_product_id = 16;
			}
		}
		const newSelection = this.props.updateSelection(selection, {
			...selection,
			bet_type,
			product_id: selected_product_id,
		});

		if (this.state.selectedBet) {
			this.handleSelectedBet({
				...this.state.selectedBet,
				...this.buildRacingSelectionItem(newSelection),
			});
		}
	};

	/**
	 * Confirm the bet
	 */
	onConfirm = () => {
		const {
			buildMultiBets,
			buildMultiSingleBets,
			buildSRMsBets,
			boxed_flag,
			origin,
			legs,
			SRMs,
			quaddies,
			exotics,
			bets,
			selections,
			confirmBetPlacement,
			buildEachWaySingleBets,
			free_credit_flag,
		} = this.props;

		const totalNormalBetAmount = NewMultiBetContainer.calculateTotalBetAmount(this.props, true);
		const totalBonusBetAmount = NewMultiBetContainer.calculateTotalBetAmount(this.props, false);

		const totalBetAmount = totalNormalBetAmount + totalBonusBetAmount;
		// If the bet placement isn't in the confirming stage and confirming bet placement is set in features, set the stage and do nothing else
		if (confirmBetPlacement && !this.state.confirmingBet) {
			this.setState({ confirmingBet: true });
			return;
		}

		// Format the bets list for the endpoint
		const singleBets = buildMultiSingleBets(selections, {
			origin,
			boxed_flag,
			totalBetAmount,
		});

		const multiBets = buildMultiBets(selections, legs, {
			origin,
			boxed_flag,
			free_credit_flag,
		});
		const SRMsBets = buildSRMsBets(SRMs, {
			origin,
			boxed_flag,
			free_credit_flag,
		});

		const quaddiesBets = buildExoticsBets(quaddies, {
			origin,
			boxed_flag,
			free_credit_flag,
		});
		const exoticsBets = buildExoticsBets(exotics, {
			origin,
			boxed_flag,
			free_credit_flag,
		});

		const eachWaySelections = selections.filter((selection) => selection.racingBetType === 'eachway');
		const singleBetsEachway = buildEachWaySingleBets(eachWaySelections, {
			origin,
			boxed_flag,
			free_credit_flag,
			totalBetAmount,
		});
		// Format our bet params for submissions

		const betPlacement = {
			origin,
		};

		betPlacement.bet_list = [...singleBets, ...singleBetsEachway];

		if (multiBets) {
			betPlacement.bet_list.push(multiBets);
		}

		if (SRMsBets.length) {
			betPlacement.bet_list = [...betPlacement.bet_list, ...SRMsBets];
		}

		if (quaddiesBets.length) {
			betPlacement.bet_list = [...betPlacement.bet_list, ...quaddiesBets];
		}

		if (exoticsBets.length) {
			betPlacement.bet_list = [...betPlacement.bet_list, ...exoticsBets];
		}

		//console.log('betPlacement', JSON.stringify(betPlacement));

		// if (this.state.eachWayBets) {
		// 	const singleBets = betPlacement.bet_list.filter((bets) => singleBetsEachway.some((eacBets) =>
		// 		eacBets.selectionId !== bets.selectionId));
		// 	betPlacement.bet_list = [...singleBets, ...singleBetsEachway];
		// }

		this.props.handlePlaceBet(betPlacement).finally(() => {
			this.setState({
				confirmingBet: false,
				eachWayBets: false,
				selectedBet: null,
			});
		});
	};

	onEachBets = (selection) => {
		const selections = this.state.eachWaysBetsArray.concat(selection);
		this.setState({
			eachWaysBetsArray: selections,
		});
		this.setState({ eachWayBets: true });
	};

	handleBetReceiptLink = () => {
		this.handleClose();
		// if (this.props.useRouting) {
		// 	this.props.navigate(this.state.betsLink, {
		// 		trigger: true,
		// 	});
		// }
		scrollToTop();
	};

	handleRemoveSRM = (srm) => {
		let { selectedBet } = this.state;

		if (selectedBet && selectedBet.id === srm.id) {
			selectedBet = null;
			this.setState({ selectedBet });
		}

		this.props.handleRemoveSRM(srm);
	};

	handleRemoveQuaddie = (quaddie) => {
		let { selectedBet } = this.state;

		if (selectedBet && selectedBet.id === quaddie.id) {
			selectedBet = null;
			this.setState({ selectedBet });
		}

		this.props.handleRemoveQuaddie(quaddie);
	};

	handleQuaddieStakeChange = (id, value) => {
		const exotic = this.props.quaddies.find((q) => q.id === id);

		if (exotic) {
			this.setState({ confirmingBet: false });
			// const val = this.limitStakeAmount(value,srm.stake, srm.numberOfBets);
			const val = this.limitStakeAmount(value);

			const flexiAmount = val / exotic.combinations;
			const [integerPart, decimalPart = ''] = flexiAmount.toString().split('.');

			const newFlexiFixed2 = `${integerPart}.${decimalPart.slice(0, 2)}`;

			const newExotic = {
				...exotic,
				stake: val,
				amount: val,
				flexiAmount: newFlexiFixed2,
			};
			this.props.handleReplaceQuaddieLeg(newExotic);
		}
	};

	handleExportBetInReceipt = (bet) => {
		if (!bet) {
			this.props.handleBetExport(null);
			return;
		}

		if (
			bet.bet_type === 'win' ||
			bet.bet_type === 'place' ||
			bet.bet_type === 'eachway' ||
			bet.bet_type === 'sameracemulti' ||
			bet.bet_type === 'multi' ||
			RACING_EXOTIC_BET_TYPES.includes(bet.bet_type) ||
			QUADDIES_BET_TYPES.includes(bet.bet_type)
		) {
			let returnValue;
			let odds;
			const isTote =
				toteProducts.includes(bet.product_code) || bet.bet_selections.some((s) => toteProducts.includes(s.product));
			if (isTote) {
				returnValue = 'TBD';
				odds = 'TBD';
			} else if (bet.bet_type === 'eachway') {
				odds = `${bet.bet_selections[0].odds}/${bet.bet_selections[1].odds}`;
				returnValue = `$${((bet.odds * bet.amount) / 100).toFixed(2)}`;
			} else if (bet.bet_type == 'sameracemulti') {
				returnValue = `$${(((bet.display_amount / 100) * bet.amount) / 100).toFixed(2)}`;
				odds = (bet.display_amount / 100).toFixed(2);
			} else {
				odds = bet.bet_selections
					.reduce((acc, s) => {
						return acc * parseFloat(s.fixed_odds);
					}, 1)
					.toFixed(2);
				returnValue = `$${((odds * bet.amount) / 100).toFixed(2)}`;
			}

			const data = {
				id: bet.id,
				status: bet.status,
				type: bet.bet_type,
				date: serverDateTime(bet.date).format('DD MMM YYYY HH:mm'),
				stake: bet.amount / 100,
				odds: odds,
				return: returnValue,
				flexiAmount: bet.percentage,
				selection_string: bet.selection_string,
				isTote,
				selections: bet.bet_selections.map((s) => ({
					selection_number: s.selection_number,
					selection_name: s.selection_name,
					product: s.product,
					meeting_name: s.competition_name,
					race_number: s.race_number,
					meeting_id: s.meeting_id,
					type: s.bet_type,
					race_type: s.event_type,
					startTime: s.date,
				})),
			};

			this.props.handleBetExport(data);
		}
	};

	handleRemoveExotic = (exotic) => {
		let { selectedBet } = this.state;

		if (selectedBet && selectedBet.id === exotic.id) {
			selectedBet = null;
			this.setState({ selectedBet });
		}

		this.props.handleRemoveExotic(exotic);
	};

	handleExoticStakeChange = (id, value) => {
		const exotic = this.props.exotics.find((q) => q.id === id);
		if (!exotic) return;

		this.setState({ confirmingBet: false });
		const val = this.limitStakeAmount(value);

		const combinations = calculateCombinations(
			exotic.exoticDetails.id,
			exotic.selections,
			exotic.exoticDetails.isBoxed,
		);

		const flexiAmount = val / combinations;
		const [integerPart, decimalPart = ''] = flexiAmount.toString().split('.');

		const newFlexiFixed2 = `${integerPart}.${decimalPart.padEnd(2, '0').slice(0, 2)}`;
		const newExotic = {
			...exotic,
			stake: val,
			amount: val,
			flexiAmount: newFlexiFixed2,
		};
		this.props.handleReplaceExotic(newExotic);
	};

	handleExoticFlexiChange = (id, value) => {
		const exotic = this.props.exotics.find((q) => q.id === id);
		if (exotic) {
			this.setState({ confirmingBet: false });
			const amount = parseInt(value) || 0;

			const combinations = calculateCombinations(
				exotic.exoticDetails.id,
				exotic.selections,
				exotic.exoticDetails.isBoxed,
			);
			const val = this.limitStakeAmount(combinations * amount);

			const flexiAmount = val / exotic.combinations;
			const [integerPart, decimalPart = ''] = flexiAmount.toString().split('.');

			const newFlexiFixed2 = `${integerPart}.${decimalPart.padEnd(2, '0').slice(0, 2)}`;

			const newExotic = {
				...exotic,
				stake: val,
				amount: val,
				flexiAmount: newFlexiFixed2,
			};
			this.props.handleReplaceExotic(newExotic);
		}
	};

	handleBonusBetChange = (id, type) => {
		let item = {};
		if (type === 'single') {
			item = this.getSelection(id);
			item.free_credit_flag = !item.free_credit_flag;
			this.props.handleReplaceSingleSelection(item);
			//console.log('item', item);
		} else if (type === 'multi') {
			item = this.getLeg(id);
			item.free_credit_flag = !item.free_credit_flag;
			this.props.handleReplaceSingleLeg(item);
		} else if (type === 'sameracemulti') {
			item = this.getSRM(id);
			item.free_credit_flag = !item.free_credit_flag;
			this.props.handleReplaceSingleSRM(item);
		} else if (type === 'quaddie') {
			item = this.props.quaddies.find((q) => q.id === id);
			item.free_credit_flag = !item.free_credit_flag;
			this.props.handleReplaceQuaddieLeg(item);
		} else {
			item = this.props.exotics.find((q) => q.id === id);
			item.free_credit_flag = !item.free_credit_flag;
			this.props.handleReplaceExotic(item);
		}
		this.checkBalance();
	};

	renderReceipt = () => {
		const { bets } = this.props;
		const multiBet = bets[0];

		return (
			<div
				key={`${multiBet.summaries.length}_${multiBet.totalStake}_${multiBet.totalPotential}`}
				style={{
					height: '100%',
					overflowY: 'scroll',
				}}
			>
				{multiBet.unitSummaries.map((bet) => (
					<UnitStake
						key={`${bet.id}`}
						bet={bet}
						onClickTransaction={this.handleBetReceiptLink}
						totalStake={bet.totalStake}
						totalPotential={bet.totalPotential}
						handleExportBetInReceipt={this.handleExportBetInReceipt}
					/>
				))}
				<MultiBetSummary
					bets={multiBet.summaries}
					totalStake={multiBet.totalStake}
					totalPotential={multiBet.totalPotential}
				/>
			</div>
		);
	};

	selectionsAreFromSameRace = (selections) => {
		if (selections.length === 0) {
			return false;
		}

		const firstSelection = selections[0];
		const { race_id } = firstSelection;
		return selections.every((selection) => selection.race_id === race_id);
	};

	handleSelectedCupomBet = (bet_id, type) => {
		if (!isNumber(bet_id)) {
			this.props.handleBetExport(null);
			return;
		}

		let bet = { status: 'OPEN / SELLING' };
		if (type === 'single') {
			let selection = this.buildRacingSelectionItem(this.props.selections.find((selection) => selection.id === bet_id));
			let isTote = toteProducts.includes(this.props.selections.find((selection) => selection.id === bet_id).product);

			bet = {
				...bet,
				id: selection.id,
				type: selection.selectedBetType,
				date: serverDateTime(),
				stake: selection.stake,
				odds: isTote ? 'TOTE' : selection[selection.selectedBetType].odds.toFixed(2),
				return: selection[selection.selectedBetType].odds * selection.stake,
				selections: [
					{
						selection_number: selection.selection_number,
						selection_name: selection.name,
						silk: selection.silk,
						product: selection.product_name,
						meeting_name: selection.meeting_name,
						meeting_id: selection.meeting_id,
						race_type: selection.raceType,
						race_number: selection.number,
						type: selection.bet_type,
					},
				],
			};
		} else if (type === 'multi') {
			const leg = this.props.legs.find((leg) => leg.id === bet_id);
			let selections = this.props.selections.map((s) => this.buildRacingSelectionItem(s));
			let isTote = this.props.selections.some((s) => toteProducts.includes(s.product));
			bet = {
				...bet,
				id: selections.id,
				type: 'multi',
				date: serverDateTime(),
				stake: leg.stake,
				odds: isTote ? 'TOTE' : leg.price.toFixed(2),
				return: leg.price * leg.stake,
				selections: selections.map((s) => {
					return {
						type: s.selectedBetType,
						race_type: s.raceType,
						selection_number: s.selection_number,
						selection_name: s.name,
						silk: s.silk,
						product: s.product_name,
						race_number: s.number,
						meeting_name: s.meeting_name,
						meeting_id: s.meeting_id,
					};
				}),
			};
		} else if (type === 'sameracemulti') {
			const srm = this.props.SRMs.find((srm) => srm.id === bet_id);

			bet = {
				...bet,
				date: serverDateTime(),
				id: srm.id + srm.race_id,
				type: 'sameracemulti',
				stake: srm.stake,
				odds: srm.price.toFixed(2),
				return: srm.price * srm.stake,
				selections: srm.selections.map((s) => ({
					silk: s.silk,
					selection_number: s.selection_number,
					selection_name: s.name,
					product: s.product.product_code,
					race_number: srm.race.number,
					meeting_name: srm.race.meeting_name,
					race_type: srm.race.type,
					type: s.bet_type,
				})),
			};
		} else {
			const quaddie = this.props.quaddies.find((quaddie) => quaddie.id === bet_id);
			bet = {
				...bet,
				return: 0,
				date: serverDateTime(),
				id: bet_id,
				type: quaddie.bet_type,
				stake: quaddie.stake,
				combinations: quaddie.combinations,
				flexiAmount: quaddie.flexiAmount,
				odds: null,
				selections: quaddie.selections.map((s) => ({
					race_number: s.race_number,
					selection_number: s.number,
					selection_name: s.name,
					meeting_name: quaddie.eventName,
					race_type: quaddie.race_type,
					type: quaddie.bet_type,
				})),
			};
		}

		this.props.handleBetExport(bet);
	};

	handleBonusBet = () => {
		this.setState({ useBonuBets: !this.state.useBonuBets });
	};

	renderSelections = () => {
		const {
			legs,
			SRMs,
			quaddies,
			selections,
			exotics,
			t,
			defaultBetAmount,
			lyb,
			liability,
			bonus_bet_enabled,
		} = this.props;

		const { isSinglesCollapsed, totalBetAmount } = this.state;

		const selectionsAreFromSameRace = this.selectionsAreFromSameRace(selections);

		// If it's multi hide single bets
		const isEmpty =
			selections.length === 0 && legs.length == 0 && SRMs.length === 0 && quaddies.length === 0 && exotics.length === 0;
		const showedLegs = legs.filter((leg) => leg.name.includes('Leg Multi'));

		return (
			<div
				style={{
					width: '100%',
					overflowY: 'auto',
					// maxHeight: '80%'
				}}
			>
				{selections.length > 0 ? (
					<div>
						<MultiBetSelectionList
							races={this.props.races}
							handleBonusBetChange={this.handleBonusBetChange}
							bonus_bet_enabled={bonus_bet_enabled}
							price_bump_enabled={this.props.price_bump_enabled}
							selectionsAreFromSameRace={selectionsAreFromSameRace}
							selections={this.buildSelectionItems()}
							isCollapsed={isSinglesCollapsed}
							onMultiToggle={this.handleMultiToggle}
							onSelectionToggle={this.handleToggleSingleSelections}
							onDismiss={this.handleDismissSelection}
							onRemove={this.handleRemoveSingleSelection}
							onStakeChange={this.handleStakeChange}
							onBulkStakeChange={this.handleBulkStakeChange}
							onBetTypeChange={this.handleBetTypeChange}
							onClickSelection={() => {}}
							onChangeBetType={this.handleUpdateSelection}
							showPricePump={this.props.showPricePump}
							EachBetaction={this.onEachBets}
							cash_out_enabled={this.props.cash_out_enabled}
							totalBetAmount={totalBetAmount}
							bet={
								lyb.toString() !== 'back1' ||
								lyb.toString() !== 'back2' ||
								lyb.toString() !== 'back3' ||
								lyb.toString() !== 'back4'
									? liability
									: defaultBetAmount
							}
							lyabi={lyb}
							shouldPulse={this.state.shouldPulse}
							handleSelectedCupomBet={this.handleSelectedCupomBet}
							selectedBet={this.state.selectedBet}
							handleSelectedBet={this.handleSelectedBet}
						/>

						{selections.some((selection) => selection.invalid) ? (
							<Notification
								strong
								type={Notification.types.COLOUR_DANGER}
								message={t('MultiBetContainer__InvalidSelections')}
							/>
						) : null}
					</div>
				) : null}
				{showedLegs.map((leg) => (
					<MultiLegItem
						races={this.props.races}
						handleBonusBetChange={this.handleBonusBetChange}
						bonus_bet_enabled={bonus_bet_enabled}
						selectionsAreFromSameRace={selectionsAreFromSameRace}
						key={leg.id}
						isCollapsed={!this.state.legState[leg.id].open}
						leg={leg}
						selections={this.buildSelectionItems()}
						onLegToggle={this.handleToggleLeg}
						onStakeChange={this.handleLegStakeChange}
						handleSelectedCupomBet={this.handleSelectedCupomBet}
						onRemove={this.handleRemoveSingleSelection}
						selectedBet={this.state.selectedBet}
						handleSelectedBet={this.handleSelectedBet}
					/>
				))}

				{SRMs.length > 0 ? (
					<MultiSRMList
						races={this.props.races}
						handleBonusBetChange={this.handleBonusBetChange}
						bonus_bet_enabled={bonus_bet_enabled}
						isCollapsed={true}
						SRMs={SRMs}
						onRemove={this.handleRemoveSRM}
						onLegToggle={() => {}}
						onStakeChange={this.handleSRMStakeChange}
						handleSelectedCupomBet={this.handleSelectedCupomBet}
						selectedBet={this.state.selectedBet}
						handleSelectedBet={this.handleSelectedBet}
					/>
				) : null}
				{quaddies.length > 0 && (
					<QuaddieList
						races={this.props.races}
						handleBonusBetChange={this.handleBonusBetChange}
						bonus_bet_enabled={bonus_bet_enabled}
						isCollapsed={true}
						quaddies={quaddies}
						onRemove={this.handleRemoveQuaddie}
						onLegToggle={() => {}}
						onStakeChange={this.handleQuaddieStakeChange}
						handleSelectedCupomBet={this.handleSelectedCupomBet}
						selectedBet={this.state.selectedBet}
						handleSelectedBet={this.handleSelectedBet}
					/>
				)}
				{exotics.length > 0 && (
					<ExoticBetList
						races={this.props.races}
						handleBonusBetChange={this.handleBonusBetChange}
						bonus_bet_enabled={bonus_bet_enabled}
						isCollapsed={true}
						exotics={exotics}
						onRemove={this.handleRemoveExotic}
						onStakeChange={this.handleExoticStakeChange}
						handleFlexiChange={this.handleExoticFlexiChange}
						handleSelectedCupomBet={this.handleSelectedCupomBet}
						selectedBet={this.state.selectedBet}
						handleSelectedBet={this.handleSelectedBet}
					/>
				)}
				{/* {!this.state.eachWayBets && totalBetAmount > 0 &&
                    <Button
                      className={cx(
                        css`
                          flex: 1;
                          position: absolute;
                          margin-top: -30px;
                        `,
                      )}
                      type={Button.types.CALLTOACTION}
                      key="confirming"
                      disabled={betsReferred}
                      action={this.onEachBets}
                    >
                      {t('Each Way')}
                    </Button>
                  } */}

				{isEmpty ? (
					<StyledMultiBetContainer__EmptyMessage>
						<Text
							align="center"
							className={css`
								color: black;
								font-size: 15px;
								font-weight: bold;
							`}
						>
							{t('MultiBetContainer__EmptyOne')}
						</Text>
						<Text
							align="center"
							className={css`
								color: black;
								font-size: 15px;
							`}
						>
							{t('MultiBetContainer__EmptyTwo')}
						</Text>
					</StyledMultiBetContainer__EmptyMessage>
				) : null}
			</div>
		);
	};

	formatMoney = (amount) => {
		let s = '$';
		return s + (amount / 100).toFixed(2);
	};

	isSpendLimitOver = () => {
		const { user } = this.props;
		if (user.daily_bet_limit && this.state.totalBetAmount >= user.daily_bet_limit) {
			this.props.setErrorMessage('You have reached your daily bet limit ' + this.formatMoney(user.daily_bet_limit));
			return false;
		} else if (user.weekly_bet_limit && this.state.totalBetAmount >= user.weekly_bet_limit) {
			this.props.setErrorMessage('You have reached your weekly bet limit ' + this.formatMoney(user.weekly_bet_limit));
			return false;
		} else if (user.monthly_bet_limit && this.state.totalBetAmount >= user.monthly_bet_limit) {
			this.props.setErrorMessage('You have reached your monthly bet limit ' + this.formatMoney(user.monthly_bet_limit));
			return false;
		} else {
			return true;
		}
	};

	handleNotificationPending = (betId, status) => {
		// if (this.props.selectedTab === TAB_PENDING_BETS) return;
		// const notifications = this.state.notificationPending;
		// const existNotification = notifications.find((notification) => notification.betId === betId);
		// if (existNotification) return;
		// this.setState({ notificationPending: [...notifications, { betId, status }] });
	};

	handleGotoTab = (tab) => {
		// if (tab === TAB_PENDING_BETS) {
		// 	this.setState({ notificationPending: [] });
		// }
		this.props.handleTab(tab);
	};

	renderBody = () => {
		const {
			t,
			bets,
			top,
			errorMessage,
			isLoading,
			selections,
			showLogin,
			hideHeader,
			count_bets,
			user,
			useRouting,
			isSingleWallet,
			isMultiBetSlipOpen,
			defaultBetAmount,
			account_balance,
			credit_limit,
			isUserLoggedIn,
			isPhoneDevice,
			onBetSlipClick,
			SRMs,
			headerHeight,
			quaddies,
			exotics,
			taglines,
			selectedTab,
			bonus_bet_enabled,
			free_credit_balance,
		} = this.props;
		const pendingBets = [];

		const { totalBetAmount, totalBonusBetAmount, totalNormalBetAmount, betSlipTabs, useBonuBets } = this.state;

		const isEmpty = selections.length === 0 && SRMs.length === 0 && quaddies.length === 0 && exotics.length === 0;
		let placeBetDisabled = isEmpty || !totalBetAmount || selections.some((selection) => selection.invalidFatal);
		// || totalBetAmount < 1000;
		if (user && !isSingleWallet) {
			placeBetDisabled = true;
		}
		const betsReferred = this.isBetsReferred();
		// const ammountIsLowerThan10 = totalBetAmount < 1000 && totalBetAmount > 0;
		const onPlaceBetHanlde = (e) => {
			if (bets.length > 0) {
				this.onKeepBets();
				return;
			}

			if (!isUserLoggedIn) {
				this.props.onJoinLogin();
				return;
			}
			if (account_balance < totalNormalBetAmount) {
				if ((credit_limit || 0) + account_balance < totalNormalBetAmount) {
					this.props.openDeposits();
					return;
				}
			}

			if (bonus_bet_enabled && free_credit_balance < totalBonusBetAmount) {
				this.props.openDeposits();
				return;
			}

			this.clearErrorMessage();
			this.onConfirm();
		};

		const priceList = [1, 2, 5, 10, 20, 50, 100, 500];

		// Add this condition to determine if the button should be disabled
		const isPlaceBetDisabled =
			(bonus_bet_enabled && free_credit_balance < totalBonusBetAmount) ||
			totalBonusBetAmount + totalNormalBetAmount == 0;

		return (
			<StyledMultiBetContainer__Body>
				<TabContainer isEmpty={isEmpty} isLogged={showLogin} isActive={selectedTab === TAB_BETSLIP}>
					{bets.length > 0 ? this.renderReceipt() : this.renderSelections()}

					{!isEmpty && (
						<StyledMultiBetContainer__ActionGroupContainer>
							{this.state.selectedBet && (
								<StyledPrice__Container>
									<StyledPriceLabel__Wrapper>
										{priceList.map((item, index) => (
											<StyledPriceLabel__Item
												key={index}
												// selectedItem={index + 1 * 100 === this.state.selectedBet.price}
												onClick={() => this.handleSelectedBetPrice(item)}
											>
												+${item}
											</StyledPriceLabel__Item>
										))}
									</StyledPriceLabel__Wrapper>
									<StyledPriceButton__Group>
										<StyledPrice__Clear onClick={() => this.handleSelectedBet(null)}>Done / Close</StyledPrice__Clear>
										<StyledPrice__Clear onClick={() => this.handleSelectedBetPrice(0)}>Clear</StyledPrice__Clear>
									</StyledPriceButton__Group>
								</StyledPrice__Container>
							)}

							<ButtonGroup flex>
								<Button
									className={cx(
										css`
											flex: 2;
										`,
									)}
									type="primary"
									key="cancel"
									disabled={betsReferred}
									action={this.handleUndoBetSlip}
								>
									{bets.length > 0 ? t('Close') : this.state.confirmingBet ? t('Change') : t('Delete All')}
								</Button>

								{this.state.confirmingBet ? (
									<Button
										className={cx(
											css`
												flex: 4;
											`,
										)}
										type={Button.types.CALLTOACTION}
										key="confirming"
										action={bets.length > 0 ? this.onKeepBets : this.onConfirm}
									>
										<PlotElements>
											<span>
												{t('Confirm')}
												&nbsp;
											</span>
											{totalBetAmount > 0 ? <CurrencyDisplay amount={totalBetAmount} /> : ' '}
											{totalBetAmount > 0 && <span>&nbsp;</span>}
											<span>{t('Bet')}</span>
										</PlotElements>
									</Button>
								) : bets.length > 0 ? (
									<div
										className={cx(
											css`
												display: flex;
												padding: 10px;
											`,
										)}
									>
										<Checkbox
											className={cx(
												css`
													//margin-left: 10px;
												`,
											)}
											name="Keep Selections"
											disabled={(!bets.length && placeBetDisabled) || betsReferred}
											action={bets.length > 0 && this.onKeepBets}
										/>
										<span
											className={cx(
												css`
													font-size: 12px;
													margin-left: 4px;
												`,
											)}
										>
											{t('MultiBetContainer__KeepBets')}
										</span>
									</div>
								) : isUserLoggedIn ? (
									<Button
										className={cx(
											css`
												flex: 4;
											`,
										)}
										type={Button.types.CALLTOACTION}
										//disabled={(!bets.length && placeBetDisabled) || betsReferred}
										disabled={isPlaceBetDisabled}
										key="confirm"
										action={onPlaceBetHanlde}
									>
										{
											<PlotElements>
												<span>
													{t('Place')}
													&nbsp;
												</span>
												{totalBetAmount > 0 ? <CurrencyDisplay amount={totalBetAmount} /> : ' '}
												{totalBetAmount > 0 && <span>&nbsp;</span>}
												<span>{t('Bet')}</span>
											</PlotElements>
										}
									</Button>
								) : (
									<Button
										className={cx(
											css`
												flex: 4;
											`,
										)}
										type={Button.types.CALLTOACTION}
										//disabled={(!bets.length && placeBetDisabled) || betsReferred}
										disabled={isPlaceBetDisabled}
										key="confirm"
										action={!isUserLoggedIn && onPlaceBetHanlde}
									>
										{
											<PlotElements>
												<span>
													{t('Login to Place')}
													&nbsp;
												</span>
											</PlotElements>
										}
									</Button>
								)}
							</ButtonGroup>

							{!isUserLoggedIn && (
								<Text size="-3" className="mt-1">
									To place your bet, please login though the login pop-up.
								</Text>
							)}
							{/* WHAT ARE YOU REALLY GAMBLING WITH? For free and confidential support call 1800 858 858 or visit  */}
							{useBonuBets ? (
								<Text size="-2" align="left">
									{t('Money from Bonus Balance')}
								</Text>
							) : (
								''
							)}

							<StyledMultiBetContainer__StruckWarning>
								<Text size="-2" align="center" strong>
									{taglines && taglines.contact ? handleTaglines(taglines, 'betslip') : t('MultiBetContainer__Warning')}
								</Text>
								<Text size="-3" align="left">
									{taglines && taglines.contact && <div dangerouslySetInnerHTML={{ __html: taglines.contact }} />}
								</Text>
							</StyledMultiBetContainer__StruckWarning>

							{((account_balance < totalNormalBetAmount &&
								(credit_limit || 0) + account_balance < totalNormalBetAmount) ||
								(bonus_bet_enabled && free_credit_balance < totalBonusBetAmount)) &&
							isUserLoggedIn == true ? (
								<Notification
									type={Notification.types.COLOUR_DANGER}
									message={t('MultiBetContainer__KeepBets_disable_placeButton')}
									strong
								/>
							) : null}
						</StyledMultiBetContainer__ActionGroupContainer>
					)}
				</TabContainer>

				<TabContainer isEmpty={isEmpty} isLogged={showLogin} isActive={selectedTab === TAB_PENDING_BETS}>
					<PendingBetContainer
						isActive={selectedTab === TAB_PENDING_BETS}
						bets={this.props.userBets}
						handleNotificationPending={this.handleNotificationPending}
						handlePartialBet={this.handlePartialBet}
						handleClosePartialBet={this.props.handleClosePartialBet}
						showTab={() => this.handleGotoTab(TAB_PENDING_BETS)}
						openNotification={this.props.openNotification}
					/>
				</TabContainer>
			</StyledMultiBetContainer__Body>
		);
	};

	handlePartialBet = async (betId, amount, isAcepted = true) => {
		let a = amount * 100;

		const data = { betId, amount: a.toFixed(2), isAcepted };
		const bet = this.props.userBets.find((bet) => bet.id == betId);

		let isExotic = RACING_EXOTIC_BET_TYPES.includes(bet.bet_type);
		let isQuaddie = QUADDIES_BET_TYPES.includes(bet.bet_type);
		data.isExotic = isExotic || isQuaddie;
		if (isQuaddie) {
			const combinations = calculateCombinations(bet.bet_type, bet.bet_selections, bet.boxed_flag);
			const flexiAmount = a / combinations;
			data.flexiAmount = flexiAmount.toFixed(2);
		} else if (isExotic) {
			const selections = parsePositions(bet.selection_string);
			for (let s of selections) {
				const selection = bet.bet_selections.find((sel) => sel.selection_number == s.number);
				s.id = selection.id;
			}
			const combinations = calculateCombinations(bet.bet_type, selections, bet.boxed_flag);
			const flexiAmount = a / combinations;
			data.flexiAmount = flexiAmount.toFixed(2);
		}
		await this.props.handlePartialBet(data);
		this.handleGotoTab(TAB_BETSLIP);
	};

	render() {
		const {
			t,
			errorMessage,
			isLoading,
			selections,
			hideHeader,
			userId,
			user,
			isSingleWallet,
			isMultiBetSlipOpen,
			SRMs,
			legs,
			exotics,
			headerHeight,
			account_balance,
			quaddies,
			selectedTab,
		} = this.props;

		const { totalBetAmount, started } = this.state;
		// const topa = this.handleScroll(top);
		const betsReferred = this.isBetsReferred();

		const isEmpty =
			selections.length === 0 && legs.length == 0 && SRMs.length === 0 && quaddies.length === 0 && exotics.length === 0;
		let placeBetDisabled = isEmpty || !totalBetAmount || selections.some((selection) => selection.invalidFatal);

		if (user && !isSingleWallet) {
			placeBetDisabled = true;
		}
		// console.log(started);
		return (
			<StyledMultiBetContainer
				id="multi-bet-container"
				isOpen={isMultiBetSlipOpen}
				started={started}
				headerHeight={headerHeight}
				onTouchMove={this.onTouchMove}
				onTouchStart={this.onTouchStart}
				onTouchEnd={this.onTouchEnd}
			>
				<StyledMultiBetContainer__Content>
					{this.props.userBets.map((bet) => (
						<PusherSubscriberUserBet key={bet.id} betId={bet.id} userId={userId} />
					))}

					{this.props.races.map((race) => (
						<PusherSubscriberRaceBetChannel key={race.id} raceId={race.id} />
					))}

					{!hideHeader && (
						<StyledRightNavContainer
							type="primary"
							nodeRight={
								// isPhoneDevice by @HW 28Oct2020
								!betsReferred && (
									<StyledMultiBetContainer__HeaderClose onClick={this.handleBetSlipClose}>
										<RightArrow_Icon_Slip color="white" size="-3" icon="arrow-right" />
									</StyledMultiBetContainer__HeaderClose>
								)
							}
						>
							<Text type="alternate" strong>
								<FeatureContext.Consumer>
									{(featureToggles) => {
										return (
											<PlotElements align="start">
												{featureToggles.features.multiBetSlipTitle &&
												featureToggles.features.multiBetSlipTitle.enabled ? (
													featureToggles.features.multiBetSlipTitle.value
												) : (
													<span>{t('MultiBetContainer__BetSlip')}</span>
												)}
												{/* {this.state.isMobile && user && !isSingleWallet ? (
													<PlotElements>
														<span>
															&nbsp;(
															{t('MultiBetContainer__Balance')}
															:&nbsp;
														</span>
														<CurrencyDisplay amount={account_balance} />
														<span>)</span>
													</PlotElements>
												) : null} */}
											</PlotElements>
										);
									}}
								</FeatureContext.Consumer>
							</Text>
						</StyledRightNavContainer>
					)}

					<LoadingMask loading={isLoading} />
					{errorMessage &&
						errorMessage.map((e) => (
							<Notification
								key={e}
								type={Notification.types.COLOUR_DANGER}
								message={e}
								strong
								buttonText={t('Dismiss')}
								buttonAction={this.clearErrorMessage}
							/>
						))}

					<StyledBetSlipTab>
						<StyledBetSlipTabItem
							key={TAB_BETSLIP}
							onClick={() => this.handleGotoTab(TAB_BETSLIP)}
							isActive={selectedTab === TAB_BETSLIP}
						>
							<TabItemText size="-3">{TAB_BETSLIP}</TabItemText>
						</StyledBetSlipTabItem>

						<StyledBetSlipTabItem
							key={TAB_PENDING_BETS}
							onClick={() => this.handleGotoTab(TAB_PENDING_BETS)}
							isActive={selectedTab === TAB_PENDING_BETS}
						>
							<TabItemText size="-3" hasPending={this.props.userBets.length > 0}>
								REFERRED BETS <br /> (PENDING APPROVAL)
								{this.props.userBets.length > 0 && <PendingBetCount count={this.props.userBets.length} />}
							</TabItemText>
						</StyledBetSlipTabItem>
					</StyledBetSlipTab>

					{this.renderBody()}
				</StyledMultiBetContainer__Content>
			</StyledMultiBetContainer>
		);
	}
}

const mapStateToProps = (state, ownProps) => {
	const betKeys = state[PLACE_MULTI_BET].bets.length
		? state[PLACE_MULTI_BET].bets[0].bets
			? state[PLACE_MULTI_BET].bets[0].bets.map((bet) => bet.id)
			: []
		: [];
	const selectionKeys = state[PLACE_MULTI_BET].selections.map((selection) => selection.id);
	const selections = getSelectionsForBetSlip(state);
	const race = getSingleRaceSelectionsForSlip(state);
	const getselectionTB = selections.map((selection) => selection.win);
	const getProductCode = getselectionTB.map((selection) => selection.code);
	const getselectionLiability = getselectionTB.map((selection) => selection.liability) * 100;

	const authenticatedUser = state.application.authenticatedUser;

	let account_balance;
	let credit_limit;
	let pricePumpLimit;

	if (authenticatedUser) {
		account_balance = state.entities.users[authenticatedUser].account_balance;
		credit_limit = state.entities.users[authenticatedUser].credit_limit;
		pricePumpLimit = state.entities.users[authenticatedUser].racing_boost_available;
	}

	const user = state.entities.users[authenticatedUser] || {};
	const settings = user.settings || {};

	let errorMessage = state[PLACE_MULTI_BET].errorMessage;
	if (errorMessage && !Array.isArray(errorMessage)) {
		errorMessage = [errorMessage];
	}

	return {
		...state[PLACE_MULTI_BET],
		errorMessage,
		taglines: state.acl.taglines,
		selections: selections || state[PLACE_MULTI_BET].selections,
		betSelections: betKeys ? denormalizeBets(state.entities, betKeys) : null,
		entitySelections: denormalizeSelections(state.entities, selectionKeys),
		userId: authenticatedUser,
		user: user,
		defaultBetAmount:
			getProductCode.toString() === 'GRST' || getProductCode.toString() === 'GRSF'
				? state.defaultBetAmounts.default_bet_amount
				: 0,
		//defaultBetAmount: getProductCode === 'TRB1' ? getselectionTBOdds : state.defaultBetAmounts.default_bet_amount,
		lyb: getProductCode,
		liability: getselectionLiability,
		isPhoneDevice: state.application.isPhoneDevice,
		isMultiBetSlipOpen: state.application.showMultiBetSlip,
		showLogin: state.featureToggles.features.loginInModals.enabled && !authenticatedUser,
		isSingleWallet: state.featureToggles.features.singleWallet.enabled,
		confirmBetPlacement: state.featureToggles.features.confirmBetPlacement.enabled && !(settings.quick_bet || false),
		cash_out_enabled: race[0] === undefined ? 0 : race[0].cash_out_enabled,
		useRouting:
			typeof ownProps.useRouting === 'undefined'
				? state.featureToggles.features.urlRouting.enabled
				: ownProps.useRouting,
		limitUserBetAmount: state.featureToggles.features.limitUserBetAmount.enabled,
		account_balance,
		credit_limit,
		isUserLoggedIn: Boolean(authenticatedUser),
		selectedPageKey: state.application.selectedPage,
		showPricePump: pricePumpLimit > 0,
		userBets: getPendingBets(state),
		free_credit_balance: Boolean(authenticatedUser) && user.free_credit_balance,
		price_bump_enabled:
			Boolean(authenticatedUser) && user.racing_boost_available > 0 && state.featureToggles.features.oddBoost.enabled,
		bonus_bet_enabled:
			Boolean(authenticatedUser) && user.free_credit_balance > 0 && state.featureToggles.features.bonusBets.enabled,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		shortenRacingBetType,
		buildSelectionsForRequest,
		//buildRouteToRace,
		buildWithDateGRSRouteToRace,
		buildSportRoute,
		buildMultiBets,
		buildMultiSingleBets,
		buildEachWaySingleBets,
		buildSRMsBets,
		getSportBetLabel,
		getRacingBetTypeLabel,
		setErrorMessage: (errorMessage) => {
			dispatch(multiBetPlacementActions.setError(errorMessage));
		},
		handleUndoBetSlip: () => {
			dispatch(multiBetPlacementActions.reset());
		},
		handleReplaceAllSelections: (selections) => {
			dispatch(multiBetPlacementActions.setSelections(selections));
			dispatch(multiBetPlacementActions.rebuildLegs());
			//dispatch(multiBetPlacementActions.validate());
		},
		handleRemoveSingleSelection: (selection) => {
			//dispatch(trackGaEvent('BetSlipMultis', 'Click', 'Remove Selection'));

			dispatch(multiBetPlacementActions.removeSingleSelection(selection));
			dispatch(removeRace(PLACE_MULTI_BET, selection.race_id));
		},
		handleChangeSingleSelection: (oldSelection, newSelection, rebuildLegs = false) => {
			dispatch(multiBetPlacementActions.changeSelection({ oldSelection, newSelection }));
			if (rebuildLegs) {
				dispatch(multiBetPlacementActions.rebuildLegs());
			}
		},
		handleReplaceSingleSelection: (selection, rebuildLegs = false) => {
			dispatch(multiBetPlacementActions.replaceSelection(selection));
			if (rebuildLegs) {
				dispatch(multiBetPlacementActions.rebuildLegs());
			}
		},
		handleReplaceSingleLeg: (leg) => {
			dispatch(multiBetPlacementActions.replaceLeg(leg));
		},
		handleRemoveMultiLeg: (leg) => {
			dispatch(removeSelection(PLACE_MULTI_BET, leg));
		},
		handleReplaceSingleSRM: (srm) => {
			dispatch(replaceSRM(PLACE_MULTI_BET, srm));
		},
		handleRemoveSRM: (srm) => {
			dispatch(deleteRM(srm.id));
			dispatch(removeRace(PLACE_MULTI_BET, srm.race_id));
		},
		handleRemoveQuaddie: (leg) => {
			dispatch(removeQuaddies(PLACE_MULTI_BET, leg.id));
			for (let r of leg.races) {
				dispatch(removeRace(PLACE_MULTI_BET, r.id));
			}
		},
		handleRemoveExotic: (leg) => {
			dispatch(removeExotics(PLACE_MULTI_BET, leg.id));
			dispatch(removeRace(PLACE_MULTI_BET, leg.race_id));
		},
		handleReplaceExotic: (leg) => {
			dispatch(replaceExotics(PLACE_MULTI_BET, leg));
		},
		handleReplaceQuaddieLeg: (leg) => {
			dispatch(replaceQuaddies(PLACE_MULTI_BET, leg));
		},
		handlePlaceBet: (confirmedBets) => {
			//dispatch(trackGaEvent('BetSlipMultis', 'Click', 'Place Bet'));
			return dispatch(multiBetPlacementActions.placeMultiBet(confirmedBets));
		},
		handleRebet: () => {
			//dispatch(trackGaEvent('BetSlipMultis', 'Click', 'Keep Selections'));
			return dispatch(multiBetPlacementActions.clearBets());
		},
		handleCacheOut: () => {
			return dispatch(multiBetPlacementActions.cacheOut());
		},
		handleMultiBetsPlaced: (betSelections) => {
			dispatch(multiBetPlacementActions.handleMultiBetsPlaced(betSelections));
		},
		loadDataForSelections: (selections) => dispatch(loadDataForSelections(selections)),
		track: (label, value) => {}, //dispatch(trackGaEvent('BetSlipMultis', label, value)),
		validateMulti: () => {
			//dispatch(multiBetPlacementActions.validate());
		},
		onClose: () => {
			dispatch(toggleMultiBetSlip(true));
		},
		navigate: (route, opts) => {
			dispatch(navigate(route, opts));
		},
		updateSelection: (oldSelection, selection) => {
			const { id, race_id, meeting_id, product_id, cash_out_enabled, bet_type, stake, bet_from } = selection;
			const newSelection = dispatch(
				formatAndUpdateRacingMultiBet(id, race_id, meeting_id, product_id, bet_type, stake, cash_out_enabled, bet_from),
			);
			dispatch(multiBetPlacementActions.changeSelection({ oldSelection, newSelection }));
			return newSelection;
		},
		onJoinLogin: () => {
			return dispatch(openLoginForm());
		},
		handleBetExport: (bet) => {
			dispatch(openBetExport(bet));
		},
		openNotification: (message, type) => {
			dispatch(
				openNotification(message, type, {
					autoClose: 3500,
				}),
			);
		},
		handlePartialBet: async ({ betId, amount, isAcepted = true, isExotic = false, flexiAmount }) => {
			if (isAcepted) {
				const r = await dispatch(await partialBet({ betId, amount, flexi: flexiAmount }));

				if (r) {
					await dispatch(fetchActiveBets());
					dispatch(
						openNotification('Your partial bet has been placed successfully', 'success', {
							autoClose: 3500,
						}),
					);
				}
				return;
			}
			const res = await dispatch(await cancelPartialBet({ betId }));
			await dispatch(fetchActiveBets());
			if (res) {
				dispatch(
					openNotification('Your partial bet has been cancelled', 'success', {
						autoClose: 3500,
					}),
				);
			}
		},
		handleClosePartialBet: (id, status) => {
			dispatch(updateBetStatus(id, status.toLowerCase()));
		},
		handleTab: (tab) => dispatch(handleBetslipTab(tab)),
		// opens Deposits modal
		openDeposits: () => {
			return dispatch(showDeposits());
		},
	};
};

export default withNamespaces()(
	createErrorBoundary(connect(mapStateToProps, mapDispatchToProps)(NewMultiBetContainer), {
		message: 'MessageBetPrompt',
		buttonText: 'Retry',
		remountFailureButtonAction: launchLiveChat,
	}),
);

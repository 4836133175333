import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import {
	spacings,
	ui,
	Link,
	Text,
	PlotElements,
	BettingAdd,
	CollapsibleContent,
	OverlayMessage,
	Table,
} from '@tbh/ui-kit';

import CurrencyDisplay from '../../../Application/Currency/CurrencyDisplay/CurrencyDisplay';

import { formatOddsAsPrice } from '../../../../../common/BetPlacement';
import {
	TOTE_PRICE_UNAVAILABLE,
	FIXED_PRICE_UNAVAILABLE,
	DEFAULT_BET_AMOUNT_PLACEHOLDER_TEXT,
	TOTE_NAMES_BY_PRODUCT,
} from '../../../../../store/entities/constants/BetConstants';
import {
	BET_CANCELLED_STATUS,
	BET_FULL_REFUND_STATUS,
	BET_REJECTED_STATUS,
} from '../../../../../common/constants/Bets';
import { USER_ACCOUNT_V2_BASE_URL } from '../../../../../pages/UserAccountV2/UserAccountPageConstants';
import { trackGaEvent } from '../../../../../store/trackingPixels/trackingActions';
import { routeTo, scrollToTop } from '../../../../../store/application/applicationActions';
import { createErrorBoundary } from '../../../../../containers/ErrorBoundary/ErrorBoundaryActions';
import { StyleBetCupomPreview } from '../../BetSelection/BetSelectionMulti/BetSelectionMulti';
import { COLORS } from '../../../../../constants/themes';

const MAX_COMMENT_LENGTH = 110;

/**
 * Styling
 */
const StyledUnitStake__Header = styled('div')(
	(props) => css`
		label: UnitStake__Header;

		background-color: ${ui(props).color_2};
		padding: ${spacings(props).compact}px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	`,
);

const StyledUnitStake__Footer = styled('div')(
	(props) => css`
		label: UnitStake__Footer;

		background-color: ${ui(props).color_3};
		padding: ${spacings(props).compact}px;
	`,
);

const StyledUnitStake__FooterTableRow = styled(Table.TR)(
	(props) => css`
		label: UnitStake__FooterTableRow;

		padding-bottom: ${spacings(props).compact}px;

		&:last-child {
			padding-bottom: 0;
		}
	`,
);

const StyledUnitStake__RejectedSelections = styled(CollapsibleContent)(
	(props) => css`
		label: UnitStake__RejectedSelections;

		margin-left: ${spacings(props).compact}px;
	`,
);
// End Styling

class UnitStake extends Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,

		/** The bet that was made */
		bet: PropTypes.shape({
			/** The name for the bet */
			name: PropTypes.string.isRequired,

			/** The number of bets made in the bet */
			number: PropTypes.number.isRequired,

			/** The odds amount for the bet */
			price: PropTypes.string.isRequired,

			/** The receipt ID for a single bet */
			receipt: PropTypes.number,
		}).isRequired,

		/** The potential payout of the bet */
		totalPotential: PropTypes.number.isRequired,

		/** The stake amount */
		totalStake: PropTypes.number.isRequired,

		/** Action if the bet link was clicked on */
		goToTransactions: PropTypes.func,

		/** Extra classes */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		goToTransactions: () => {},
	};

	state = { betSelections: {} };

	/**
	 * Toggle the selections open or closed for rejected bets
	 *
	 * @param event
	 * @param rejectedId
	 */
	toggleRejectedSelections = (event, rejectedId) => {
		this.setState((state) => ({
			betSelections: {
				...state.betSelections,
				[rejectedId]: !state.betSelections[rejectedId],
			},
		}));
	};

	getBetSelections = (key = 'rejected') => {
		const { betSelections } = this.state;
		const { bet, goToTransactions, t } = this.props;
		const betSelectionList = bet[key];

		return betSelectionList.map((selection) => {
			// Trim the rejection message back so that it doesn't overflow the component too much
			const rejectionComment =
				selection.rejection_comment && selection.rejection_comment.length > MAX_COMMENT_LENGTH
					? `${selection.rejection_comment.substring(0, MAX_COMMENT_LENGTH)}...`
					: selection.rejection_comment;

			const betIsRefunded = selection.status === BET_FULL_REFUND_STATUS;
			const betIsRejected = selection.status === BET_REJECTED_STATUS || selection.status === BET_CANCELLED_STATUS;

			return (
				<ul key={selection.id}>
					<li>
						<Link action={this.toggleRejectedSelections} data={selection.id} type={Link.types.INFO} size="-2">
							{t('UnitStake__ViewSelections')}
						</Link>
						<StyledUnitStake__RejectedSelections isCollapsed={!betSelections[selection.id]}>
							{selection.bet_selections.map((betSelection) => (
								<Text size="-2" key={betSelection.selection_id}>
									{betSelection.selection_name}
								</Text>
							))}
						</StyledUnitStake__RejectedSelections>
					</li>
					{betIsRejected && (
						<li>
							<Text size="-2" type="danger">
								- {t('Rejected')}: {selection.rejection_reason}
							</Text>
						</li>
					)}
					{betIsRefunded && (
						<li>
							<Text size="-2" type="warning">
								{t('Refunded')}
							</Text>
						</li>
					)}
					{selection.rejection_comment && (
						<li>
							<Text size="-2">
								- {t('Comment')}: {rejectionComment}
							</Text>
						</li>
					)}
					{!betIsRejected && !betIsRefunded && (
						<li>
							{selection.id ? (
								<div>
									<Text size="-2" tag="span">
										- {t('UnitStake__BetReceipt')}:{' '}
									</Text>
									<Link type={Link.types.INFO} size="-2" action={goToTransactions}>
										{`#${selection.id}`}
									</Link>
								</div>
							) : (
								<Link type={Link.types.INFO} size="-2" action={goToTransactions}>
									- {t('UnitStake__ViewDetails')}
								</Link>
							)}
						</li>
					)}
				</ul>
			);
		});
	};

	render() {
		const { bet, totalPotential, totalStake, className, goToTransactions, t, handleExportBetInReceipt } = this.props;
		const {
			name,
			subName,
			info,
			number,
			price,
			pending,
			rejected,
			refunded,
			approved,
			processing,
			product_code,
			bet_type,
			isTote,
			isExotic,
			percentage,
		} = bet;
		const componentClasses = cx(
			css`
				position: relative;
			`,
			{
				[className]: className,
			},
		);

		// Set the selection price as a string if there are prices unavailable
		let selectionIsFixed, selectionPriceUnavailable, selectionIsTote;
		const allBets = [...approved, ...pending, ...rejected, ...refunded, ...processing];
		allBets.forEach((allBet) => {
			if (!selectionIsFixed) {
				selectionIsFixed = allBet.bet_selections.some(
					(selection) =>
						selection.entitySelection &&
						selection.entitySelection.isRacing &&
						selection.entitySelection[selection.entitySelection.racingBetType] &&
						selection.entitySelection[selection.entitySelection.racingBetType].fixed,
				);
			}
			if (!selectionPriceUnavailable) {
				selectionPriceUnavailable = allBet.bet_selections.some(
					(selection) =>
						selection.entitySelection &&
						selection.entitySelection.isRacing &&
						selection.entitySelection[selection.entitySelection.racingBetType] &&
						isNaN(selection.entitySelection[selection.entitySelection.racingBetType].odds),
				);
			}
		});
		let betPrice = '';
		if (isTote || isExotic) {
			betPrice =
				TOTE_NAMES_BY_PRODUCT[product_code] && TOTE_NAMES_BY_PRODUCT[product_code][bet.selections[0].event_type]
					? TOTE_NAMES_BY_PRODUCT[product_code][bet.selections[0].event_type]
					: isTote
					? 'TOTE'
					: product_code;
		} else if (bet_type === 'eachway') {
			betPrice = `${bet.selections[0].fixed_odds}/${bet.selections[1].fixed_odds}`;
		} else {
			betPrice =
				(isNaN(price) || !price) && product_code
					? product_code
					: selectionPriceUnavailable && selectionIsFixed
					? FIXED_PRICE_UNAVAILABLE
					: selectionPriceUnavailable
					? TOTE_PRICE_UNAVAILABLE
					: formatOddsAsPrice(price);
		}

		return (
			<div className={componentClasses}>
				{pending.length ? (
					<OverlayMessage action={this.props.goToTransactions} message={t('UnitStake__PendingReview')} open />
				) : null}
				{approved.length >= 1 && (
					<div
						className={css`
							display: flex;
							flex-direction: row;
							justify-content: center;
							padding: 5px;
						`}
					>
						<div
							className={css`
								display: flex;
								flex-direction: row;
								justify-content: center;
								align-items: center;
								width: 100%;
								background: #4caf50;
								border-radius: 4px;
								padding: 5px;
							`}
						>
							<Text
								size="-1"
								strong
								className={css`
									color: white !important;
								`}
							>
								Bet Accepted
							</Text>
						</div>
					</div>
				)}
				{processing.length >= 1 && (
					<OverlayMessage action={this.props.goToTransactions} message={t('UnitStake__BetProcessing')} open />
				)}
				<StyledUnitStake__Header>
					<div>
						<ul>
							{info && (
								<li>
									<Text size="-2">{info}</Text>
								</li>
							)}
							<li>
								<Text size="-1" strong>
									{name}
								</Text>
							</li>
							{subName && (
								<li>
									<Text size="-2">
										{subName}
										{product_code == 'GB' && (
											<span
												style={{
													color: COLORS.red[300],
												}}
											>
												(BOOST)
											</span>
										)}
									</Text>
								</li>
							)}
							{rejected.length ? <li>{this.getBetSelections('rejected')}</li> : null}
							{refunded.length ? <li>{this.getBetSelections('refunded')}</li> : null}
							{(refunded.length || rejected.length) && approved.length ? (
								<li>{this.getBetSelections('approved')}</li>
							) : approved.length >= 1 ? (
								approved.map((bet) => (
									<li key={bet.id}>
										<Text size="-2" tag="span">
											{t('UnitStake__BetReceipt')}:{' '}
										</Text>
										{/*<Link type={Link.types.INFO} size="-2" action={() => {}}>
											{`#${bet.id}`}
										</Link> */}
										<Text size="-2" tag="span">
											{`#${bet.id}`}
										</Text>
									</li>
								))
							) : null}
						</ul>
					</div>
					<div
						className={css`
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							gap: 4px;
						`}
					>
						<BettingAdd metric={betPrice} disabled />
						{approved.length ? (
							<StyleBetCupomPreview onClick={() => handleExportBetInReceipt(approved[0])}>
								Bet Receipt
							</StyleBetCupomPreview>
						) : null}
					</div>
				</StyledUnitStake__Header>
				<StyledUnitStake__Footer>
					<Table density="compact">
						<StyledUnitStake__FooterTableRow>
							<Table.TD>
								<Text strong size="-2">
									{t(DEFAULT_BET_AMOUNT_PLACEHOLDER_TEXT)}
								</Text>
							</Table.TD>
							<Table.TD>
								<Text strong size="-2" align="right">
									<PlotElements align="end">
										{totalStake > 0 ? <span></span> : ''}
										<CurrencyDisplay amount={totalStake} imageWidth={12} />
									</PlotElements>
								</Text>
							</Table.TD>
						</StyledUnitStake__FooterTableRow>
						{isExotic && (
							<StyledUnitStake__FooterTableRow>
								<Table.TD>
									<Text strong size="-2">
										Flexi
									</Text>
								</Table.TD>
								<Table.TD>
									<Text strong size="-2" align="right">
										{percentage}%
									</Text>
								</Table.TD>
							</StyledUnitStake__FooterTableRow>
						)}
						<StyledUnitStake__FooterTableRow>
							<Table.TD>
								<Text strong size="-2">
									{t('PotentialPayout')}
								</Text>
							</Table.TD>
							<Table.TD>
								<Text strong size="-2" align="right">
									{isNaN(totalPotential) ? 'TBD' : <CurrencyDisplay amount={totalPotential} imageWidth={12} />}
								</Text>
							</Table.TD>
						</StyledUnitStake__FooterTableRow>
					</Table>
				</StyledUnitStake__Footer>
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		goToTransactions: () => {
			//dispatch(trackGaEvent('AccountMenu', 'Click', 'Transactions'));
			scrollToTop();
			return dispatch(routeTo(`${USER_ACCOUNT_V2_BASE_URL}/transactions/all`));
		},
	};
};

export default withNamespaces()(createErrorBoundary(connect(undefined, mapDispatchToProps)(UnitStake)));

import cx from 'classnames/bind';
import PropTypes from 'prop-types';
import React from 'react';
import { withNamespaces } from 'react-i18next';

import { PlotElements } from '@tbh/ui-kit';
import CurrencyDisplay from '../../Application/Currency/CurrencyDisplay/CurrencyDisplay';
import BetListItem from '../../Betting/BetListItem/BetListItem';

import {
	buildDerivativeLabel,
	getBetSelectionDerivativeTitle,
} from '../../../../store/entities/selectors/BetSelectors';

// Constants
import { BET_PAID_STATUS, BET_RESULT_STATUSES } from '../../../../common/constants/Bets';
import {
	GENERIC_SILK,
	QUADDIES_BET_TYPES,
	RACE_PAID_STATUS,
	RACE_STATUSES,
	RACING_BET_PRODUCT_CODE_SP,
	RACING_BET_PRODUCT_CODE_TF,
	RACING_BET_TYPE_MARGIN,
	RACING_EXOTIC_BET_TYPES,
	RACING_SAME_RACE_MULTI_TYPE,
	SRMTypes,
} from '../../../../common/constants/Racing';
import { groupBetSelectionByRaceNumber } from '../../../../common/GroupBetSelectionByRaceNumber';
import {
	BET_TYPE_DERIVATIVE,
	TOTE_NAMES_BY_PRODUCT,
	toteProducts,
} from '../../../../store/entities/constants/BetConstants';

// Context
import AclContext from '../../../../contexts/AclContext';
import { css } from 'emotion';
import { COLORS } from '../../../../constants/themes';
import { GOAT_PRODUCT_TYPE_BOOST } from '../../../../common/constants/GoatProducts';

// Styles

/**
 * Format the display of the bet selections for non-exotic bets
 *
 * @param selection
 * @returns {string}
 */
const formatSelections = (selection) => {
	return selection.selection_number + '. ' + selection.selection_name;
};

/**
 * Format the display of the approx. win OR how much was paid out
 * @param status
 * @param amount
 * @param odds
 * @param is_exotic
 * @param paid
 * @returns {*}
 */
const formatApproxWinOrPaid = ({
	status,
	amount,
	odds,
	bet_type,
	display_amount,
	bet_selections,
	is_exotic,
	paid,
	liability
}) => {
	// After bet has been paid
	if (status === BET_PAID_STATUS) {
		return paid;
	} else {
		// If the bet is exotic and hasn't been paid
		if (is_exotic) {
			return '-';
		}
		if (bet_type == RACING_SAME_RACE_MULTI_TYPE) {
			return amount * parseFloat(display_amount / 100).toFixed(2);
		}

		if (bet_type === 'eachway') {
			return amount + liability;
		}

		// Extract odds from bet_selections array
		const localodds =
			bet_selections && bet_selections.length > 0
				? bet_selections.map((selection) => parseFloat(selection.fixed_odds)).filter((odds) => !isNaN(odds))
				: [];

		// If there are valid odds, calculate amount * highest odds
		if (localodds.length > 0) {
			return amount * Math.max(...localodds);
		}

		return 'n/a';
	}
};

/** What currency type is being used for the bet */
const getCurrencyType = (betItem) => {
	return betItem.free_credit_amount > 0 ? 'bonusCurrency' : 'currency';
};

/**
 * Build a formatted string containing the bet amount and the odds/price
 *
 * @param betItem
 * @returns {string}
 */
const buildAmountAndPriceString = (betItem) => {
	const currencyType = getCurrencyType(betItem);

	const isExotic = RACING_EXOTIC_BET_TYPES.includes(betItem.bet_type) || QUADDIES_BET_TYPES.includes(betItem.bet_type);
	const isTote = !betItem.is_fixed && toteProducts.includes(betItem.product_code) && !isExotic;
	const firstSelection = betItem.bet_selections && betItem.bet_selections.length > 0 ? betItem.bet_selections[0] : null;
	const secondSelection =
		betItem.bet_selections && betItem.bet_selections.length > 1 ? betItem.bet_selections[1] : null;

	let productCode = '';
	let odds;
	if (betItem.bet_type === RACING_SAME_RACE_MULTI_TYPE) {
		odds = `$${parseFloat(betItem.display_amount / 100).toFixed(2)}`;
		productCode = '';
	} else if (isTote || isExotic) {
		odds = isExotic ? betItem.percentage + '%' : 'TOTE';
		productCode =
			TOTE_NAMES_BY_PRODUCT[betItem.product_code] &&
			TOTE_NAMES_BY_PRODUCT[betItem.product_code][betItem.bet_selections[0].event_type]
				? TOTE_NAMES_BY_PRODUCT[betItem.product_code][betItem.bet_selections[0].event_type]
				: productCode;
	} else if (
		(betItem.product_code === RACING_BET_PRODUCT_CODE_TF || betItem.product_code === RACING_BET_PRODUCT_CODE_SP) &&
		betItem.is_fixed
	) {
		productCode =
			TOTE_NAMES_BY_PRODUCT[betItem.product_code] &&
			TOTE_NAMES_BY_PRODUCT[betItem.product_code][betItem.bet_selections[0].event_type]
				? TOTE_NAMES_BY_PRODUCT[betItem.product_code][betItem.bet_selections[0].event_type]
				: productCode;
		odds = `${productCode}`;
	} else {
		if (betItem.bet_type === 'eachway') {
			odds = `$${parseFloat(firstSelection.fixed_odds).toFixed(2)}/$${parseFloat(secondSelection.fixed_odds).toFixed(
				2,
			)}`;
		} else {
			odds = `$${firstSelection.fixed_odds}`;
		}
		productCode = betItem.product_code === 'GRSF' ? 'FIXED' : betItem.product_code;
	}

	return (
		<PlotElements align="start" style={{ flexWrap: 'wrap' }}>
			<CurrencyDisplay amount={betItem.amount} imageWidth={10} type={currencyType} />
			{currencyType === 'bonusCurrency' && (
				<AclContext.Consumer>
					{(acl) =>
						acl.currencyConfiguration && acl.currencyConfiguration.bonusCurrencyName ? (
							<span>&nbsp;({acl.currencyConfiguration.bonusCurrencyName} Bet)</span>
						) : (
							<span>&nbsp;(Bonus Bet)</span>
						)
					}
				</AclContext.Consumer>
			)}

			<span>
				&nbsp;@
				{odds}
			</span>
		</PlotElements>
	);
};

/**
 *
 *
 * @param betItem
 * @param raceStatus
 * @returns {string}
 */
const buildBetTypeAndPayoutText = (betItem, raceStatus) => {
	let betTypeLabel = '';

	if (betItem.bet_type === BET_TYPE_DERIVATIVE && betItem.derivative_bet_selections) {
		betTypeLabel = betItem.derivative_bet_selections[0].derivative_market_name;
	} else if (betItem.bet_type === RACING_BET_TYPE_MARGIN && betItem.bet_selections[0].margin > 0) {
		betTypeLabel = `${betItem.bet_type} of ${betItem.bet_selections[0].margin} L`;
	} else if (betItem.bet_type === RACING_SAME_RACE_MULTI_TYPE) {
		betTypeLabel = 'SRM';
	} else if (betItem.product_type === GOAT_PRODUCT_TYPE_BOOST) {
		betTypeLabel = (
			<React.Fragment>
				{betItem.bet_type}{' '}
				<span
					className={css`
						color: ${COLORS.red[300]};
					`}
				>
					(BOOST)
				</span>
			</React.Fragment>
		);
	} else {
		betTypeLabel = betItem.bet_type;
	}

	const isTote = toteProducts.includes(betItem.product_code);

	return (
		<PlotElements align="start" style={{ flexWrap: 'wrap' }}>
			<span>{betTypeLabel}</span>
			{raceStatus !== RACE_PAID_STATUS && betItem.is_fixed && !isTote ? (
				<PlotElements align="start" style={{ whiteSpace: 'nowrap' }}>
					<span>&nbsp;(BET RETURN:&nbsp;</span>
					<CurrencyDisplay amount={formatApproxWinOrPaid(betItem)} imageWidth={10} type={getCurrencyType(betItem)} />
					<span>)</span>
				</PlotElements>
			) : betItem.paid && !isTote ? (
				<PlotElements align="start" style={{ whiteSpace: 'nowrap' }}>
					<span>&nbsp;(Paid:&nbsp;</span>
					<CurrencyDisplay amount={betItem.paid} imageWidth={10} type={getCurrencyType(betItem)} />
					<span>)</span>
				</PlotElements>
			) : (
				''
			)}

			{raceStatus !== RACE_PAID_STATUS && isTote && (
				<PlotElements align="start" style={{ whiteSpace: 'nowrap' }}>
					<span>&nbsp;(BET RETURN:&nbsp;TBD)</span>
				</PlotElements>
			)}
		</PlotElements>
	);
};

const RaceBetItem = (props) => {
	const {
		className,
		raceStatus,
		betItem,
		showStatus,
		t,
		handlecacheOut,
		cashout_limit,
		dailyBailOutLimit,
		bailOutIsEnabled
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	const selectionsByNumber = betItem.bet_selections.sort(function(a, b) {
		if (a.race_number < b.race_number) {
			return -1;
		}
		if (a.race_number > b.race_number) {
			return 1;
		}
		return 0;
	});

	const isMultiLeg = QUADDIES_BET_TYPES.includes(betItem.bet_type);

	const derivativeBetSelection =
		betItem.bet_type === BET_TYPE_DERIVATIVE && betItem.derivative_bet_selections
			? betItem.derivative_bet_selections[0]
			: null;

	let title = '';

	if (betItem.is_exotic) {
		title = betItem.selection_string;
	} else if (betItem.bet_type === RACING_SAME_RACE_MULTI_TYPE) {
		title = betItem.bet_selections
			.map(({ product, selection_number }) => `${selection_number}. ${SRMTypes[product]}`)
			.join(' | ');
	} else {
		title = formatSelections(betItem.bet_selections[0]);
	}

	let imageSrc = null;

	if (
		!betItem.is_exotic &&
		(betItem.bet_selections.length === 1 || betItem.bet_type == 'eachway') &&
		betItem.bet_selections[0].silk
	) {
		imageSrc = betItem.bet_selections[0].silk;
	}

	let betStatus = null;
	betStatus = betItem.status;
	// if(betItem.response && betItem.response[0] && betItem.response[0].status) {
	// 	betStatus = betItem.response[0].status;
	// } else {
	// 	betStatus = betItem.status;
	// }

	return (
		<BetListItem
			className={componentClasses}
			bets={[
				{
					amountAndOdds: buildAmountAndPriceString(betItem),
					betTypeDescription: buildBetTypeAndPayoutText(betItem, raceStatus),
					title,
					multiLegSelections: isMultiLeg ? groupBetSelectionByRaceNumber(selectionsByNumber) : [],
					raceStatus: raceStatus,
					derivativeTitle: derivativeBetSelection
						? getBetSelectionDerivativeTitle(
								derivativeBetSelection.derivative_market_code,
								derivativeBetSelection,
								true,
								t,
						  )
						: '',
					derivativeSubTitle: derivativeBetSelection
						? buildDerivativeLabel(
								derivativeBetSelection.derivative_market_code,
								betItem.bet_selections,
								derivativeBetSelection.opponent_selections,
								t,
						  )
						: '',
				},
			]}
			betId={betItem.id}
			betStatus={betStatus}
			betPaid={betItem.paid}
			betType={betItem.bet_type}
			imageSrc={imageSrc}
			imageName={
				!betItem.is_exotic
					? betItem.bet_selections.length === 1 && betItem.bet_selections[0].selection_name + ' racing silk'
					: null
			}
			imageSrcSubstitute={GENERIC_SILK}
			handlecacheOut={handlecacheOut}
			betItem={betItem}
			cashout_limit={cashout_limit}
			dailyBailOutLimit={dailyBailOutLimit}
			bailOutIsEnabled={bailOutIsEnabled}
			freeCreditAmount={betItem.free_credit_amount}
		/>
	);
};

RaceBetItem.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Extra class names */
	className: PropTypes.string,

	betItem: PropTypes.shape({
		/** The type of bet that was placed ie. 'Win', 'Place', 'Trifecta' etc. */
		bet_type: PropTypes.string.isRequired,

		/** The amount that was placed on the bet */
		amount: PropTypes.number.isRequired,

		/** Free credit amount used for the bet */
		free_credit_amount: PropTypes.number,

		/** Bet status as defined in our racing constants */
		status: PropTypes.oneOf(BET_RESULT_STATUSES).isRequired,

		/** If an exotic bet type then this contains the percentage of the bet dividend that is returned to the punter */
		percentage: PropTypes.number,

		/** The odds that are being paid for the bet. This will be an approximate only if it is a tote product and unresulted */
		odds: PropTypes.number,

		/** The code for the odds product that the bet was placed with */
		product_code: PropTypes.string,

		/** How much the bet paid out */
		paid: PropTypes.number,

		/** Whether the bet is an exotic net or not ie. 'Trifecta', 'Quinella' etc. */
		is_exotic: PropTypes.bool,

		/** A string containing the exotic bet selections including whether is is boxed or not ie. '3, 4, 5, 6, 9 (BOXED)' */
		selection_string: PropTypes.string,

		/** The selections available to a derivative market that are selected */
		bet_selections: PropTypes.arrayOf(
			PropTypes.shape({
				selection_number: PropTypes.number.isRequired,
				selection_name: PropTypes.string.isRequired,
				silk: PropTypes.string,
			}),
		),

		/** The derivative bet selection */
		derivative_bet_selections: PropTypes.arrayOf(
			PropTypes.shape({
				/** Derivative market type name */
				derivative_market_name: PropTypes.string,

				/** Derivative market type code */
				derivative_market_code: PropTypes.string,

				/** Derivative selection name */
				derivative_selection_name: PropTypes.string,

				/** This is the selections available to a derivative market that are NOT selected */
				opponent_selections: PropTypes.arrayOf(
					PropTypes.shape({
						name: PropTypes.string,
						number: PropTypes.number,
					}),
				),
			}),
		),

		/** Margin for a Butt bet */
		margin: PropTypes.string,
	}).isRequired,

	/** The race status as defined in our racing constants */
	raceStatus: PropTypes.oneOf(RACE_STATUSES).isRequired,

	/** Should the bet status be shown */
	showStatus: PropTypes.bool,

	dailyBailOutLimit: PropTypes.number,
};

RaceBetItem.defaultProps = {
	className: null,
	showStatus: true,
	dailyBailOutLimit: 0,
};

export default withNamespaces()(RaceBetItem);

import styled, { css } from 'react-emotion';
import { spacings, brand, Button, media } from '@tbh/ui-kit';
import {
	FONT_SIZE_12,
	lessThan2MinColor,
	paidColor,
	COLORS,
	WEIGHTS,
} from '../../../../store/application/applicationConstants';
import { MEDIA_QUERIES } from '../../../../constants/themes';

export const StyledRacingFeature_races = styled('div')`
	${(props) =>
		css`
			label: RacingFeature_races;

			display: flex;
			align-items: center;
			flex-direction: row;
			box-sizing: border-box;
			width: 98%;
			height: auto;
			border-bottom: 1px solid ${COLORS.gray[200]};
			justify-content: space-between;
			&:hover {
				background: #f9f9f99c;
			}
		`};

	${media(
		(props) =>
			css`
				padding: 8px;
				height: auto;
			`,
		500,
	)};

	@media ${MEDIA_QUERIES.tabletAndUp} {
		width: 100%;
	}
`;

export const StyledFeature_races_raceDetails = styled('div')`
${(props) => css`
	label: Feature_races_raceDetails;

	display: flex;
	aligment-item: center;
	flex: 0 0 80%;
	text-transform: uppercase;
	color: ${COLORS.gray[600]};
	font-weight: ${WEIGHTS.bold};
	font-size: 11px;
	&:hover {
		cursor: pointer;
	}
`}

    @media ${MEDIA_QUERIES.tabletAndUp} {
        flex: 0 0 60%;
    }
`;

export const StyledMeetingAndNumber = styled('span')`
	display: block;
	font-weight: 800;
	color: rgb(0, 58, 145);
`;

export const StyledMeetingAndName = styled('span')`
	font-weight: 500;
	color: #091325;
`;

export const StyledFeature_races_raceTime_mobile = styled('div')`
	display: block;
	flex: 0 0 20%;
	color: ${COLORS.gray[600]};
	font-weight: ${WEIGHTS.bold};
	font-size: 11px;
	text-align: left;
	margin-top: 3px;

	@media ${MEDIA_QUERIES.tabletAndUp} {
		display: none;
	}
`;

export const StyledFeature_races_raceTime = styled('div')`
	${(props) => css`
		label: Feature_races_raceTime;
		display: none;
	`};

	@media ${MEDIA_QUERIES.tabletAndUp} {
		display: block;
		flex: 0 0 20%;
		color: ${COLORS.gray[600]};
		font-weight: ${WEIGHTS.bold};
		font-size: 11px;
		text-align: center;
	}

	${media(
		(props) =>
			css`
				flex: 0 0 25%;
				font-size: ${FONT_SIZE_12};
			`,
		768,
	)};
`;

export const StyledFeature_races_timeButton = styled('div')`
	${(props) =>
		css`
			label: Feature_races_timeButton;

			flex: 0 0 20%;
			display: flex;
			justify-content: end;
		`};

	${media(
		(props) =>
			css`
				display: flex;
				flex: 0 0 10%;
				justify-content: end;
			`,
		768,
	)};
`;

export const StyledFeature_racesTimeToGoBUtton = styled(Button)(
	(props) => css`
		label: Feature_racesTimeToGoBUtton;
		//border-radius: 12px !important;
		font-size: ${FONT_SIZE_12};
		display: block;
		background: ${brand(props).rch_4};
		border-color: ${brand(props).rch_4};
		size: small;
		width: auto;
		height: auo;
		position: relative;
		color: #ffffff;
		font-weight: bold;
		padding: 1px 6px;
		${props.status === 'paid' &&
			css`
				&& {
					background-color: ${paidColor};
					border: 1px solid ${paidColor};
					text-transform: uppercase;
				}
			`};

		${props.status === 'closed' &&
			css`
				&& {
					background-color: ${lessThan2MinColor};
					border: 1px solid ${lessThan2MinColor};
					text-transform: uppercase;
				}
			`};
	`,
);

export const StyledFeatureIconWrapper = styled('div')`
    ${(props) => css`
			label: FeatureIconWrapper;

			display: flex;
			align-items: center;
			justify-content: flex-start;
			min-width: 40px;
			width: 60px;
			height: 40px;
			margin-right: 1px;
			position: relative;

			/* Ensure RacingIcon stays consistent */
			& > *:first-child {
				margin-left: 1px;
				margin-right: 1px;
				flex-shrink: 0;
				width: 40px;
				height: 34px;
			}

			/* Ensure CountryImage stays consistent */
			& > *:last-child {
				width: 34px;
				height: 24px;
				flex-shrink: 0;
				margin-bottom: 1px;
			}
		`}

    @media ${MEDIA_QUERIES.tabletAndUp} {
        display: block;
        margin-right: 5px;
    }

    @media ${MEDIA_QUERIES.laptopAndUp} {
        display: block;
        margin-right: 5px;
    }
`;

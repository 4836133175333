const URI = require('urijs');
import { batchActions } from 'redux-batched-actions';
import { get, mergeBearerToken, post, removeBearerToken } from '../../common/Ajax';

// Actions
import { openNotification } from '../../store/application/applicationActions';
// Constants
import { AUTHENTICATED_USER_POLLING_INTERVAL } from '../../common/constants/Application';
import { GENERIC_ERROR_MESSAGE } from '../../common/constants/Notifications';
import { HIDE_LOCKED_SCREEN, SHOW_LOCKED_SCREEN } from '../application/applicationActionTypes';
import { PLACE_MULTI_BET } from '../betPlacement/betPlacementReducerNames';

// Actions and Selectors
import { createAction } from '../../common/actions/actionHelpers';
import { postPrefixedMessage } from '../../common/actions/widgetActions';
import { setAclConfig } from '../acl/aclActions';
import { setApplicationLoading, startRealityCheckTimer } from '../application/applicationActions';
import { getAuthenticatedUser } from '../application/applicationSelectors';
import { reset } from '../betPlacement/multiBetPlacementActions';
import { setDataTable } from '../dataPaginator/userTransactionsDataPaginatorActions';
import { getDefaultBetAmounts } from '../defaultBetAmounts/defaultBetAmountsActions';
import { fetchActiveBets } from '../entities/actions/BetActions';
import { mergeRaces } from '../entities/actions/RaceActions';
import { clearAuthenticatedUser, setAuthenticatedUser } from '../entities/actions/UserActions';
import { confirmNewLimit, getAllLimits } from '../limitsActions';
import { openModal } from '../modal/modalActions';
import { VERIFICATION_SET_TOKEN } from '../verifications/VerificationsActionType';
import Cookies from 'js-cookie';
// Containers

/**
 * Get a list of open races against a user's session
 *
 * @returns {function(*, *): *}
 */
const getUserRacesList = () => (dispatch, getState) => {
	const state = getState();
	const raceIds = new Set();

	if (state.grsRacingHome.selectedRace) {
		raceIds.add(state.grsRacingHome.selectedRace);
	}

	if (state.activeTournament.raceDetails.raceFilter) {
		raceIds.add(state.activeTournament.raceDetails.raceFilter);
	}

	if (state.betPrompt.selections.length) {
		const singleBetSelection = state.betPrompt.selections[0];
		const raceId = singleBetSelection.race ? singleBetSelection.race.id : singleBetSelection.race_id;
		if (raceId) {
			raceIds.add(raceId);
		}
	}

	if (state[PLACE_MULTI_BET].selections.length) {
		state[PLACE_MULTI_BET].selections.forEach((multiBetSelection) => {
			const raceId = multiBetSelection.race ? multiBetSelection.race.id : multiBetSelection.race_id;
			if (raceId) {
				raceIds.add(raceId);
			}
		});
	}

	return raceIds.size ? [...raceIds] : null;
};

/**
 * Set the authenticated user
 *
 * @param user
 * @param skipPinAuthentication
 */
export const setTheAuthenticatedUser = (user, skipPinAuthentication = false, fetch = true) => (dispatch, getState) => {
	const state = getState();

	const newUserId = user && user.id;
	const previousUser = getAuthenticatedUser(state);

	const previousUserId = previousUser && previousUser.id;

	if (newUserId) {
		// Set the authenticated user in the request headers, then in state
		mergeBearerToken(user.user_token);
		App.connectPusher(user.user_token);
		Cookies.set('grs_user_token', user.user_token, {});
		dispatch(setAuthenticatedUser(user));
		if (fetch) dispatch(fetchActiveBets());
	}

	// Update the authenticated user if it has changed
	if (previousUserId !== newUserId) {
		// If there is a new user, set the new authenticated user
		if (newUserId) {
			// Open the PIN authentication if needed
			dispatch(openPINRegistration(user.username, skipPinAuthentication));

			// Set any user specific ACL overrides
			if (user.acl) {
				dispatch(setAclConfig(state.acl, user.acl));
			}

			// Fetch the authenticated user every minute
			if (App) {
				App.authenticatedUserPolling = setInterval(
					() => dispatch(fetchAuthenticatedUser()),
					AUTHENTICATED_USER_POLLING_INTERVAL,
				);
			}

			postPrefixedMessage('authenticated');

			// Set the current user for Raygun reporting
			// rg4js('setUser', {
			// 	identifier: user.id,
			// 	isAnonymous: false,
			// 	email: user.email,
			// 	firstName: user.first_name,
			// 	fullName: user.name,
			// });
			// Start the Reality Check timer, get the user's default bet amount
			dispatch(startRealityCheckTimer());
			if (fetch) dispatch(getDefaultBetAmounts());

			// If the logged in user is from UK, get all the user's limits
			if (user.country === 'United Kingdom') {
				dispatch(getAllLimits()).then((response) => {
					dispatch(confirmNewLimit(response.payload));
				});
			}
		} else {
			if (App) {
				// Clear the timer to fetch the authenticated user every minute
				clearInterval(App.authenticatedUserPolling, AUTHENTICATED_USER_POLLING_INTERVAL);
			}
			// Remove the authenticated user token then clear the authenticated user from state
			removeBearerToken();

			dispatch(clearAuthenticatedUser());

			// Refetch the ACL to clear the user specific overrides
			// const qsParams = URI().query(true);
			// dispatch(fetchAcl(qsParams.affiliate_id, qsParams.acl_id, qsParams.base_affiliate_id, qsParams.base_acl_id));
			//----------Comment this part

			// Clear the multi bet slip (if bets placed) and the users' account transactions table
			const multiBetsPlaced = state[PLACE_MULTI_BET].bets.length;
			const clearUserDataActions = [setDataTable()];
			if (multiBetsPlaced) {
				clearUserDataActions.push(reset());
			}
			dispatch(batchActions(clearUserDataActions));

			postPrefixedMessage('unauthenticated');

			// Unset the current user for Raygun reporting
			// rg4js('setUser', {
			// 	identifier: '',
			// 	isAnonymous: true,
			// });
		}

		//dispatch(setupLiveChatUser());
		//dispatch(updateIntercom());
	}

	return user;
};

/**
 * Get the authenticated user
 */
export const fetchAuthenticatedUser = () => (dispatch) => {
	// If there is a local token it should be in the axiosHeader
	const userToken = Cookies.get('grs_user_token');
	mergeBearerToken(userToken);

	if (!userToken) return;

	return get('authentication/user')
		.then((response) => {
			const user = response.data.data;
			dispatch(setTheAuthenticatedUser(user));
			return Promise.resolve(response);
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			dispatch(setTheAuthenticatedUser(null));
			return Promise.reject(error);
		});
};

/**
 * Store a device PIN against the user
 *
 * @param device_id
 * @param pin
 */
export const saveDevicePin = (device_id, pin) => () => post('user/pin', { device_id, pin });

/**
 * Action for logging in the user with the PIN
 *
 * @param device_id
 * @param username
 * @param pin
 */
export const loginWithPin = (device_id, username, pin) => (dispatch) =>
	post('authentication/pin/login', { device_id, username, pin }).then((response) => {
		const user = response.data.data;
		dispatch(setTheAuthenticatedUser(user));
		return response;
	});

/**
 *
 * @param params (source, token, username)
 */
export const tokenAuthentication = (params) => (dispatch) => {
	return get('authentication/token/login', { params })
		.then((response) => {
			const user = response.data.data;
			dispatch(setTheAuthenticatedUser(user));
			return Promise.resolve(response);
		})
		.catch((error) => {
			document.Sentry && document.Sentry.captureException(error);
			return Promise.reject(error);
		});
};

/**
 * Action for logging in the user
 *
 * @param username
 * @param password
 * @param keep_logged_in
 * @param race_id
 */
export const login = (email, password, keep_logged_in, race_id = null) => (dispatch) => {
	const payload = {
		email,
		password,
		keep_logged_in,

		// if no defined race id is provided, collect a list of race_ids from the store
		race_id: race_id || dispatch(getUserRacesList()),
	};

	return post('/authentication/login', payload)
		.then((response) => {
			// updates races with new user centric products
			const races = response.data.races;
			if (races && races.length) {
				dispatch(mergeRaces(races));
			}

			// set the authenticated user
			const user = response.data.data;
			dispatch(setTheAuthenticatedUser(user));

			return response;
		})
		.catch((error) => {
			if (App && App.channel) {
				App.channel.trigger('login:failed', error);
			}
			document.Sentry && document.Sentry.captureException(error);
			return Promise.reject(error);
		});
};

/**
 * Action for logging out the user. Only show a loading indicator if there was an authenticated user
 *
 * @param authenticatedUser
 * @param unlock
 * @returns {function(*): *}
 */
export const logoutUser = (authenticatedUser = true, unlock = true, logoutWithoutCall = false) => (
	dispatch,
	getState,
) => {
	Cookies.remove('grs_user_token');
	if (logoutWithoutCall) {
		dispatch(setTheAuthenticatedUser(null));
		window.location.href = '/welcome?openLogin=true';
		return Promise.resolve();
	}

	if (authenticatedUser) {
		dispatch(setApplicationLoading(true));
	}

	const params = {
		race_id: dispatch(getUserRacesList()),
	};

	if (!getAuthenticatedUser(getState())) {
		dispatch(setApplicationLoading(false));
		return;
	}

	return get('/authentication/logout', { params })
		.then((response) => {
			// updates races with original products
			const races = response.data.races;
			if (races && races.length) {
				dispatch(mergeRaces(races));
			}
			if (unlock) {
				dispatch(unlockScreen());
			}
			Cookies.remove('grs_user_token');
			dispatch(setTheAuthenticatedUser(null));
			return response;
		})
		.catch((error) => {
			dispatch(openNotification(GENERIC_ERROR_MESSAGE, 'danger'));
		})
		.finally(() => {
			if (authenticatedUser) {
				dispatch(setApplicationLoading(false));
				dispatch(createAction(VERIFICATION_SET_TOKEN, null));
				// dispatch(createAction(VERIFICATION_RESET, {}));
			}
		});
};
/**
 * Opens the Login Or Join modal
 * @param hideCreateAccount: bool
 * @param redirectRoute
 */
export const openLoginForm = (hideCreateAccount, redirectRoute) => (dispatch, getState) => {
	let skipPin = false;
	const acl = getState().acl;

	// If the ACL has declared to disable pin authentication, then force skipping the pin
	if (acl.hasOwnProperty('disablePinAuth') && acl.disablePinAuth) {
		skipPin = true;
	}

	// If the pin authentication feature toggle is disabled, then force skipping the pin
	if (!getState().featureToggles.features.pinAuthentication.enabled) {
		skipPin = true;
	}

	if (skipPin === false && localStorage.getItem('TB:PIN:DEVICEID') && localStorage.getItem('TB:PIN:USERNAME')) {
		return dispatch(openLoginWithPinForm(redirectRoute));
	} else {
		return dispatch(
			openModal({
				id: 'LoginContainer',
				Component: 'LoginContainer',
				config: {
					wide: true,
				},
				props: {
					hideCreateAccount: hideCreateAccount,
					redirectRoute: redirectRoute,
				},
			}),
		);
	}
};

/**
 * Opens the PIN Authentication for login
 * @param redirectRoute
 */
export const openLoginWithPinForm = (redirectRoute) => (dispatch) => {
	return dispatch(
		openModal({
			id: 'PINAuthenticationContainer',
			Component: 'PINAuthenticationContainer',
			config: {
				wide: true,
				title: 'Login',
			},
			props: {
				username: localStorage.getItem('TB:PIN:USERNAME'),
				deviceId: localStorage.getItem('TB:PIN:DEVICEID'),
				redirectRoute: redirectRoute,
			},
		}),
	);
};

/**
 * Open the PIN registration
 *
 * @param username
 * @param skipPinAuthentication
 */
export const openPINRegistration = (username, skipPinAuthentication) => (dispatch, getState) => {
	let skipPin = false;

	const state = getState();
	const acl = state.acl;
	const applicationStateSlice = state.application;

	// If the ACL has declared to disable pin authentication
	//  OR if the app requires a mandatory update
	//  OR if skipPinAuthentication is passed in
	// Then don't show the pin authentication modal
	if (acl.disablePinAuth || applicationStateSlice.loadedVersionRequiresUpdate || skipPinAuthentication) {
		skipPin = true;
	}

	// If the pin authentication feature toggle is disabled, then force skipping the pin
	if (!getState().featureToggles.features.pinAuthentication.enabled) {
		skipPin = true;
	}

	if (skipPin === false && (!localStorage.getItem('TB:PIN:DEVICEID') || !localStorage.getItem('TB:PIN:USERNAME'))) {
		localStorage.setItem('TB:PIN:USERNAME', username);

		setTimeout(() => {
			dispatch(
				openModal({
					id: 'PINAuthenticationContainer',
					Component: 'PINAuthenticationContainer',
					config: {
						wide: true,
						title: 'PIN Authentication',
					},
					props: {
						registration: true,
						username: localStorage.getItem('TB:PIN:USERNAME'),
						deviceId: localStorage.getItem('TB:PIN:DEVICEID'),
					},
				}),
			);
		}, 1000);
	}
};

/**
 * Send a request to reset the password
 *
 * @param email
 */
export const resetPassword = (email) => () => {
	return post('/password_resets', { email: email }).then((response) => {
		return response;
	});
};

/**
 * Resets the password with email
 *
 * @param oldPassword
 * @param newPassword
 * @param newPasswordConfirmation
 * @param token
 */
export const confirmPasswordReset = (oldPassword, newPassword, newPasswordConfirmation, token) => () => {
	return post('password_resets/reset', {
		email: oldPassword,
		password: newPassword,
		password_confirmation: newPasswordConfirmation,
		token: token,
	}).then((response) => {
		return response;
	});
};

/**
 * Show the Locked Screen
 *
 * @returns {{type: string}}
 */
const showLockedScreen = () => {
	return {
		type: SHOW_LOCKED_SCREEN,
	};
};

/**
 * Hide the Locked Screen
 *
 * @returns {{type: string}}
 */
const hideLockedScreen = () => {
	return {
		type: HIDE_LOCKED_SCREEN,
	};
};

/**
 * Locks the screen
 */
export const lockScreen = () => (dispatch, getState) => {
	if (!getAuthenticatedUser(getState())) {
		console.warn('No authenticated user');
		return;
	}

	try {
		localStorage.setItem('lockScreen', 'true');
	} catch (e) {
		console.error(e);
	}

	//dispatch(shutdownIntercom());
	dispatch(showLockedScreen());
};

/**
 * Unlocks the screen
 */
export const unlockScreen = () => (dispatch) => {
	//dispatch(bootIntercom());

	try {
		localStorage.removeItem('lockScreen');
	} catch (e) {
		console.error(e);
	}

	dispatch(hideLockedScreen());
};

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import {
	RACING_THOROUGHBRED_CODE,
	RACING_GREYHOUNDS_CODE,
	RACING_HARNESS_CODE,
} from '../../../../common/constants/Racing';
import FeatureRaceList from '../FeatureRaceList/FeatureRaceList';
import RacingFeatureHeader from './RacingFeatureHeader/RacingFeatureHeader';
import { countdownLessThan2Min, countdown } from '../../../../legacy/core/format';
import { Card, CardHeader, CardTitle, CardBody } from '../../../UI/Card/Card';
import styled, { css } from 'react-emotion';

import { sortFeatureRacesByStartTime } from '../../../../store/entities/selectors/FeatureRaceSelectors';
import { StyledFeatureRaceCardContainer } from './RacingFeature.styled';
import { BREAKPOINTS } from '../../../../constants/themes';
import { Ticker } from '@tbh/ui-kit';

class RacingFeature extends React.Component {
	static propTypes = {
		/** Translation func provided by withNamespaces HOC */
		t: PropTypes.func.isRequired,
		/** Extra class(es) to pass through to the component */
		className: PropTypes.string,

		/** An array of the next to jump races */
		items: PropTypes.arrayOf(
			PropTypes.shape({
				/** The id of the race */
				id: PropTypes.number.isRequired,

				/** The id of the meeting the race is a part of */
				meeting_id: PropTypes.number.isRequired,

				/** The name of the meeting */
				meeting_name: PropTypes.string.isRequired,

				/** The type of race ie. Thoroughbred, Harness, Greyhounds */
				type: PropTypes.oneOf([RACING_THOROUGHBRED_CODE, RACING_HARNESS_CODE, RACING_GREYHOUNDS_CODE]).isRequired,

				/** The number of the race within the meeting */
				number: PropTypes.number.isRequired,

				/** The starting time of the race */
				start_datetime: PropTypes.string.isRequired,
			}),
		).isRequired,

		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}).isRequired,

		/** Function to handle the click event on each next to jump item */
		onItemClick: PropTypes.func,

		/** Action to build the list of selection bet buttons */
		buildSelectionBetButtons: PropTypes.func.isRequired,
	};

	static defaultProps = {
		// t: undefined,
		className: null,
		onItemClick: () => undefined,
	};

	/**
	 * The onClick on the item returns the race id passed in.
	 * Find that id in the list of items then call the onClick handler
	 *
	 * @param id
	 */
	handleClick = (id) => {
		let item = this.props.items.find((item) => item.id === id);
		this.props.onItemClick(item.type, item.start_datetime, item.id, item.meeting_id);
	};

	getTickerInterval = (startTime = null) => {
		/**
		 * Date gets startTime and convert to milliseconds to calculate time to jump.
		 * If the result in millisecond is less then 5 minutes return 1 sec in milliseconds.
		 */
		if (Math.abs(new Date(startTime) - new Date()) <= 300000) {
			return 1000;
		}
		// null makes Ticker clear interval.
		return null;
	};

	render() {
		const { t, className, size, items } = this.props;
		const componentClasses = cx({
			[className]: className,
		});

		if (items.length === 0) {
			return false;
		}
		const firstTwoFeaureItems = size.width >= 1440 && items.length >= 2 ? items.slice(0, 2) : items.slice(0, 1);
		const checkFavoriteSelection = firstTwoFeaureItems.filter((item) => (item.top_selections = []));

		const firstFeaturedItem = items.slice(0, 1);

		// check empty top and expert selection array
		const isAvailbleTopAndExpertSelections =
			firstFeaturedItem &&
			firstFeaturedItem.map(function(firstFe) {
				return firstFe.favorite_selection &&
					firstFe.favorite_selection.length == 0 &&
					firstFe.top_selections && firstFe.top_selections.length == 0
					? true
					: false;
			});

		const isEmptyTopAndExpertSelectins = isAvailbleTopAndExpertSelections && isAvailbleTopAndExpertSelections.join('');

		const nextFiveFeatureItems =
			isEmptyTopAndExpertSelectins === 'false' && size.width >= 1440 && items.length >= 2
				? items.slice(2, 5)
				: isEmptyTopAndExpertSelectins === 'false' && size.width >= 1440 && items.length < 2
				? items.slice(1, 5)
				: isEmptyTopAndExpertSelectins === 'false' && size.width < 1440
				? items.slice(1, 5)
				: items.slice(0, 5);

		const sortNextFiveFeatureItems = sortFeatureRacesByStartTime(nextFiveFeatureItems);

        return (
            <Card className={componentClasses} style={{ boxShadow: 'none' }}>
                <CardHeader className="reduced-padding">
                    <CardTitle>Featured Events</CardTitle>
                </CardHeader>
                <CardBody>
                    {checkFavoriteSelection.map((item) => (
                        <OptimizedFeatureRow key={item.id} size={size} isRow={item.favorite_selection.length > 0}>
                            <div className="feature-header">
                                <RacingFeatureHeader
                                    id={item.id}
                                    country={item.country}
                                    meetingName={item.meeting_name}
                                    number={item.number}
                                    name={item.name}
                                    distance={item.distance}
                                    start_datetime={item.start_datetime}
                                    type={item.type}
                                    size={size}
                                    onClickItem={this.handleClick}
                                    status={item.status}
                                    lessThan2Min={countdownLessThan2Min(item.start_datetime)}
                                />
                            </div>
                            {item.favorite_selection.length > 0 && (
                                <div className="feature-content">
                                    <StyledFeatureRaceCardContainer
                                        id={item.id}
                                        size={size}
                                        race={item}
                                        raceId={item.id}
                                        meetingId={item.meeting_id}
                                        meetingName={item.meeting_name}
                                        selections={item.favorite_selection}
                                        selectionType="favorite_selection"
                                        status={item.status}
                                        action={this.handleClick}
                                        onClickItem={this.handleClick}
                                    />
                                </div>
                            )}
                        </OptimizedFeatureRow>
                    ))}
                    
                    <RaceList>
                        {sortNextFiveFeatureItems.map((Item) => (
                            <Ticker key={Item.id} every={this.getTickerInterval(Item.start_datetime)}>
                                <FeatureRaceList
                                    size={size}
                                    id={Item.id}
                                    meetingName={Item.meeting_name}
                                    meetingId={Item.meeting_id}
                                    onClickItem={this.handleClick}
                                    country={Item.country}
                                    name={Item.name}
                                    number={Item.number}
                                    type={Item.type}
                                    start_datetime={Item.start_datetime}
                                    status={Item.status}
                                    meta={`${countdown(Item.start_datetime)}`}
                                />
                            </Ticker>
                        ))}
                    </RaceList>
                </CardBody>
            </Card>
        );
    }
}

const OptimizedFeatureRow = styled('div')`
    ${(props) => css`
        display: grid;
        grid-template-columns: ${props.isRow ? '1fr 1fr' : '1fr'};
        gap: 8px;
        margin-bottom: 12px;
        
        @media (max-width: ${BREAKPOINTS.laptopMin}px) {
            grid-template-columns: 1fr;
            gap: 4px;
        }
        
        .feature-header, .feature-content {
            padding: 8px;
            background: white;
            border-radius: 4px;
        }
    `}
`;

const RaceList = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 4px;
    
    > * {
        margin-bottom: 0 !important; // Override any existing margins
    }
`;

export default RacingFeature;
import { PROMOTIONS_BASE_URL, PROMOTIONS_SUB_APP } from '../pages/Promotions/PromotionsPageConstants';
import { scrollToTop } from '../store/application/applicationActions';

const { dispatch } = App.store;

const PromotionsV2Router = Backbone.Router.extend({
	/* standard routes can be mixed with appRoutes/Controllers above */
	routes: {
		[PROMOTIONS_BASE_URL]: 'showPromotions',
	},

	showPromotions: async function() {
		App.startSubApp(PROMOTIONS_SUB_APP);
		try {
			scrollToTop();

			await Promise.all([dispatch(fetchNextToJumpRaces_GRS())]);
		} catch (e) {
			dispatch(show404());
		}
	},
});

module.exports = new PromotionsV2Router();

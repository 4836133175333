// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import {
  spacings,
  typography,
  ui,
  Input,
  InputGroup,
  Popover,
  Text,
  MASK_AU_MOBILE_NUMBER,
  MASK_UK_MOBILE_NUMBER,
  MASK_NZ_MOBILE_NUMBER,
  MASK_SL_MOBILE_NUMBER,
  AU_MOBILE_NUMBER,
  UK_MOBILE_NUMBER,
  NZ_MOBILE_NUMBER,
  SL_MOBILE_NUMBER,
} from '@tbh/ui-kit';

// Components

import { EVENT_KEY_ENTER } from '../../../../common/constants/Application';

const StyledMobileInput__Country = styled('div')(
  (props) => css`
		label: MobileInput__Country;

		position: relative;
		flex: 0 1 30%;
		margin-right: ${spacings(props).compact}px;
	`,
);

const StyledMobileInput__CountryLabel = styled(Text)(
  (props) => css`
		label: MobileInput__CountryLabel;

		line-height: ${typography(props)['size_-2']};
	`,
);

const StyledMobileInput__CountrySelect = styled('div')(
  (props) => css`
		label: MobileInput__CountrySelect;

		font-size: ${typography(props)['size_-1']};
		line-height: normal;
		padding: ${spacings(props).compact}px 0;
		margin-bottom: ${spacings(props).cozy}px;
		border-bottom: 1px solid ${ui(props).color_4};
		cursor: pointer;
		z-index: 2;
	`,
);

const StyledMobileInput__CountrySelectArrow = styled('span')(
  (props) => css`
		label: MobileInput__CountrySelectArrow;

		position: absolute;
		top: 26px;
		right: 4px;
		border: 5px solid ${ui(props).color_transparent};
		border-top-color: ${typography(props).base_color};
		z-index: 1;
	`,
);

const StyledMobileInput__CountryList = styled(Popover)`
	label: MobileInput__CountryList;

	top: 44px;
	left: 0;
	min-width: 130px;
	width: max-content;

	${Popover.StyledPopover__Arrow} {
		display: none;
	}
`;

class MobileInput extends Component {
  static propTypes = {
    /** Translation func provided by withNamespaces HOC */
    t: PropTypes.func.isRequired,

    /** The provided phone number */
    number: PropTypes.string.isRequired,

    /** If the number is valid or not */
    valid: PropTypes.bool.isRequired,

    /** Country to SMS to */
    country: PropTypes.string.isRequired,

    /** Action to validate the mobile number */
    onChange: PropTypes.func.isRequired,

    /** Mobile error to display */
    error: PropTypes.string,

    /** Action on key down  */
    onKeyDown: PropTypes.func,
    countrycode: PropTypes. string,
    /** Extra classes */
    className: PropTypes.string,
  };

  static defaultProps = {
    error: '',
    onKeyDown: null,
    className: null,
    countrycode: null,   
  };

  state = {
    country: this.props.country,
    showCountryOptions: false,
  };

  /**
   * Handle changes in the inputs
   *
   * @param name
   * @param value
   * @param valid
   */
  handleChange = (name, value, valid = false) => {
    this.setState(
      {
        mobile_number: value,
      },
      () => {
        // if the input is valid, runs the validation
        if (valid) {
          this.props.onChange(this.formatMobileNumber(), valid);
        }
        else {
          this.props.onChange(this.formatMobileNumber());
        }
      },
    );
  };

  handleToggleSelect = () => {
    this.setState({
      showCountryOptions: !this.state.showCountryOptions,
    });
  };

  handleSelect = (id) => {
    this.setState({
      country: id,
    });
    this.handleToggleSelect();
  };


  /**
   * Sends Sms code when pressing enter
   * @param event
   */
  onKeyDown = (event) => {
    if (event.key === EVENT_KEY_ENTER && this.props.valid && this.props.onKeyDown) {
      this.props.onKeyDown(this.formatMobileNumber());
    }
  };

  formatMobileNumber = () => {

    let mobileNumber;
    let arrayOfNumbers = this.state.mobile_number.split('');
    arrayOfNumbers.splice(0, 1);
    arrayOfNumbers.splice(3, 1);
    arrayOfNumbers.splice(6, 1);
    {/*
        arrayOfNumbers.splice(5, 1);
    arrayOfNumbers.splice(7, 1);
    */}
    mobileNumber = arrayOfNumbers.join('');
    mobileNumber = '61' + mobileNumber;
    return mobileNumber;
  };

  render() {
    const { className, error, t } = this.props;

    const { country, mobile_number, showCountryOptions} = this.state;

    let countryPlaceholder, countryCode, countryMask, countryConstraints, countrycode;

    if (country === 'United Kingdom') {
      countryPlaceholder = 'UK';
      countryCode = '+44';
      countryMask = MASK_UK_MOBILE_NUMBER;
      countryConstraints = UK_MOBILE_NUMBER;
      countrycode = '';
    } else if (country === 'New Zealand') {
      countryPlaceholder = 'NZ';
      countryCode = '+64';
      countryMask = MASK_NZ_MOBILE_NUMBER;
      countryConstraints = NZ_MOBILE_NUMBER;
      countrycode = '';
    } else if (country === 'Sri Lanka'){
      countryPlaceholder = 'SL';
      countryCode = '+94';
      countryMask = MASK_SL_MOBILE_NUMBER;
      countryConstraints = SL_MOBILE_NUMBER;
      countrycode = '';
    } else {
      countryPlaceholder = 'AU';
      countryCode = '+61';
      countryMask = MASK_AU_MOBILE_NUMBER;
      countryConstraints = AU_MOBILE_NUMBER;
      countrycode = '0';
    }

    return (
	<InputGroup className={cx({ [className]: className })}>
    {/*
    <StyledMobileInput__Country>
			<StyledMobileInput__CountryLabel type="light" size="-2">
				{countryPlaceholder}
			</StyledMobileInput__CountryLabel>
			<StyledMobileInput__CountrySelect onClick={this.handleToggleSelect}>
				<StyledMobileInput__CountrySelectArrow />
				{countryCode}
			</StyledMobileInput__CountrySelect>
			<StyledMobileInput__CountryList open={showCountryOptions} onOutsideClick={this.handleToggleSelect}>
				<AutoCompleteAddressItem
              active={country === 'Australia'}
              id="Australia"
              onClick={this.handleSelect}
              text="Australia +61"
            />
        <AutoCompleteAddressItem
          active={country === 'New Zealand'}
          id="New Zealand"
          onClick={this.handleSelect}
          text="New Zealand +64"
        />
        <AutoCompleteAddressItem
          active={country === 'United Kingdom'}
          id="United Kingdom"
          onClick={this.handleSelect}
          text="United Kingdom +44"
        />

				<AutoCompleteAddressItem
              active={country === 'Sri Lanka'}
              id="Sri Lanka"
              onClick={this.handleSelect}
              text="Sri Lanka +94"
            />
			</StyledMobileInput__CountryList>
		</StyledMobileInput__Country>
    */}


		<Input
          name="mobile_number"
          type="tel"
          onChange={this.handleChange}
          onKeyDown={this.onKeyDown}
          value= {mobile_number}
          label={t('Profile__MobileNumber')}
          mask={countryMask}
          placeholderChar="#"
          placeholder="#### ### ###"
          constraints={countryConstraints}
          error={error}
          minLength={10}
          margin="cozy"
        />
	</InputGroup>
    );
  }
}

export default withNamespaces()(MobileInput);

// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';

// Components
import SetLimit from './SetLimit/SetLimit';
import MoreInfo from './MoreInfo/MoreInfo';
import { LoadingMask } from '@tbh/ui-kit';

// Actions
import { scrollToTop } from '../../../../store/application/applicationActions';

class BetLimits extends Component {
	static propTypes = {
		/**
		 * Type of limit that will appear in the text
		 * The first letter should be uppercase
		 * Examples: 'Deposit', 'Loss', 'Spend'
		 */
		limitType: PropTypes.string.isRequired,

		/** User's first name */
		first_name: PropTypes.string.isRequired,

		/** Hook for changing the screen */
		onScreenChange: PropTypes.func.isRequired,

		/** Represent the screen to be displayed */
		flow: PropTypes.string.isRequired,

		/** Hook for setting the limit */
		setBetLimit: PropTypes.func.isRequired,

		/** Hook for removing the limit */
		removeBetLimit: PropTypes.func.isRequired,

		/** Hook for clearing the message */
		clearMessage: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/** Redirects to the Self Exclusion route */
		goToSelfExclusion: PropTypes.func,

		/** Error returned from the function setBetLimit */
		error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

		/** If the `More Info` link should appear or not */
		moreInfo: PropTypes.bool,

		/** Color for the buttons and current limit */
		color: PropTypes.oneOf(['success', 'danger']),

		/** User's old daily limit */
		daily_bet_limit: PropTypes.shape({
			bet_limit: PropTypes.number,
			requested_bet_limit: PropTypes.number,
			days_remaining_to_update: PropTypes.number,
			update_limit: PropTypes.bool,
		}),

		/** User's old weekly limit */
		weekly_bet_limit: PropTypes.shape({
			bet_limit: PropTypes.number,
			requested_bet_limit: PropTypes.number,
			days_remaining_to_update: PropTypes.number,
			update_limit: PropTypes.bool,
		}),

		/** User's old monthly limit */
		monthly_bet_limit: PropTypes.shape({
			bet_limit: PropTypes.number,
			requested_bet_limit: PropTypes.number,
			days_remaining_to_update: PropTypes.number,
			update_limit: PropTypes.bool,
		}),

		/** If the component is loading or not */
		componentIsLoading: PropTypes.bool,

		/** If the limit update request is loading or not */
		updateIsLoading: PropTypes.bool,

		/** If the limit removal request is loading or not */
		removeLimitLoading: PropTypes.bool,

		/** Confirmation message */
		confirmation: PropTypes.string,

		/** Brand name */
		brandName: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		daily_bet_limit: {},
		weekly_bet_limit: {},
		monthly_bet_limit: {},
		goToSelfExclusion: null,
		error: null,
		moreInfo: false,
		color: 'danger',
		componentIsLoading: false,
		updateIsLoading: false,
		removeLimitLoading: false,
		confirmation: null,
		brandName: null,
		shouldClear: false,
	};

	state = {
		className: '',
		new_daily_bet_limit: '',
		new_weekly_bet_limit: '',
		new_monthly_bet_limit: '',
		type: null,
		showMoreInfo: false,
	};

	componentDidUpdate(prevProps) {
		if (this.props.shouldClear && !prevProps.shouldClear) {
			this.clear();
		}
	}

	/**
	 * handle content change when clicking on a tab item
	 *
	 * @param data
	 */
	handleTabChange = (data) => {
		/**
		 * Send to parent container the screen that should be displayed
		 */
		this.props.onScreenChange(data);

		this.setState({
			new_daily_bet_limit: '',
			new_weekly_bet_limit: '',
			new_monthly_bet_limit: '',
		});
	};

	/**
	 * handle changes in the inputs
	 *
	 * @param name
	 * @param value
	 */
	handleChange = (name, value) => {
		this.setState({
			[name]: value,
		});
	};

	/**
	 * hook for setting/updating the limit for the selected amount
	 * when clicking on the 'Update Limit' button
	 *
	 * @param type
	 */
	handleSetNewLimit = (type) => {
		let amount =
			(type === 'daily'
				? this.state.new_daily_bet_limit
				: type === 'weekly'
				? this.state.new_weekly_bet_limit
				: this.state.new_monthly_bet_limit) * 100;

		if (amount > 0) {
			this.props.setBetLimit(amount, type);
		}
	};

	clear = () => {
		this.setState({
			new_daily_bet_limit: '',
			new_weekly_bet_limit: '',
			new_monthly_bet_limit: '',
		});
		this.props.setShouldClear(false);
	};

	/**
	 * hook for removing the limit
	 *
	 * @param type
	 */
	handleRemoveLimit = (type) => {
		this.props.removeBetLimit(type);
	};

	/**
	 * show/hide the More Info screen
	 */
	toggleMoreInfo = () => {
		this.setState({
			showMoreInfo: !this.state.showMoreInfo,
		});
		scrollToTop();
	};

	render() {
		const {
			className,
			confirmation,
			limitType,
			flow,
			first_name,
			error,
			daily_bet_limit,
			weekly_bet_limit,
			monthly_bet_limit,
			goToSelfExclusion,
			moreInfo,
			color,
			componentIsLoading,
			updateIsLoading,
			removeLimitLoading,
			clearMessage,
			brandName,

			isRegistration,
			handleNoLimit,
		} = this.props;

		const { new_daily_bet_limit, new_weekly_bet_limit, new_monthly_bet_limit, showMoreInfo } = this.state;

		const { handleTabChange, handleChange, toggleMoreInfo, handleSetNewLimit, handleRemoveLimit } = this;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<div className={componentClasses}>
				<LoadingMask loading={componentIsLoading} />

				{showMoreInfo ? (
					<MoreInfo limitType={limitType} closeMoreInfo={toggleMoreInfo} />
				) : (
					<SetLimit
						isRegistration={isRegistration}
						handleNoLimit={handleNoLimit}
						limitType={limitType}
						flow={flow}
						first_name={first_name}
						daily_bet_limit={daily_bet_limit}
						weekly_bet_limit={weekly_bet_limit}
						monthly_bet_limit={monthly_bet_limit}
						new_daily_bet_limit={new_daily_bet_limit}
						new_weekly_bet_limit={new_weekly_bet_limit}
						new_monthly_bet_limit={new_monthly_bet_limit}
						onTabChange={handleTabChange}
						onChange={handleChange}
						onSetNewLimit={handleSetNewLimit}
						onRemoveLimit={handleRemoveLimit}
						goToSelfExclusion={goToSelfExclusion}
						moreInfo={moreInfo}
						openMoreInfo={toggleMoreInfo}
						color={color}
						error={error}
						clearMessage={clearMessage}
						confirmation={confirmation}
						updateIsLoading={updateIsLoading}
						removeLimitLoading={removeLimitLoading}
						brandName={brandName}
					/>
				)}
			</div>
		);
	}
}

export default BetLimits;

// Libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import cx from 'classnames/bind';

// Actions
import { getDepositLimit, setDepositLimit } from '../../../store/depositLimits/depositLimitsActions';
import { trackGaEvent } from '../../../store/trackingPixels/trackingActions';
import { scrollToTop } from '../../../store/application/applicationActions';

// Components
import BetLimits from '../../../components/features/ResponsibleGambling/BetLimits/BetLimits';

// Constants
import {
	SET_DAILY_LIMIT,
	SET_WEEKLY_LIMIT,
	SET_MONTHLY_LIMIT,
} from '../../../components/features/ResponsibleGambling/BetLimits/Constants';
const CONFIRMATION_CHANGE = 'CONFIRMATION_CHANGE';
const CONFIRMATION_REMOVAL = 'CONFIRMATION_REMOVAL';

class DepositLimitsContainer extends Component {
	static propTypes = {
		/**
		 * user's id
		 */
		userId: PropTypes.number.isRequired,

		/**
		 * user's first name
		 */
		first_name: PropTypes.string.isRequired,

		/**
		 * hook for getting the deposit limits
		 */
		getDepositLimit: PropTypes.func.isRequired,

		/**
		 * hook for setting the deposit limit
		 */
		setDepositLimit: PropTypes.func.isRequired,

		/**
		 * GA Tracking for when a tab is clicked:
		 * Category         | Action    | Label
		 * DepositLimits    | Select    | Daily/Weekly
		 */
		onTabClick: PropTypes.func.isRequired,

		/** Extra classes */
		className: PropTypes.string,

		/**
		 * user's old daily deposit limit
		 */
		daily_deposit_limit: PropTypes.shape({
			bet_limit: PropTypes.number,
			requested_bet_limit: PropTypes.number,
			days_remaining_to_update: PropTypes.number,
			update_limit: PropTypes.bool,
		}),

		/**
		 * user's old weekly deposit limit
		 */
		weekly_deposit_limit: PropTypes.shape({
			bet_limit: PropTypes.number,
			requested_bet_limit: PropTypes.number,
			days_remaining_to_update: PropTypes.number,
			update_limit: PropTypes.bool,
		}),

		/**
		 * user's old monthly deposit limit
		 */
		monthly_deposit_limit: PropTypes.shape({
			bet_limit: PropTypes.number,
			requested_bet_limit: PropTypes.number,
			days_remaining_to_update: PropTypes.number,
			update_limit: PropTypes.bool,
		}),

		/** Brand name */
		brandName: PropTypes.string,
	};

	static defaultProps = {
		className: '',
		daily_deposit_limit: {},
		weekly_deposit_limit: {},
		monthly_deposit_limit: {},
		brandName: null,
	};

	state = {
		confirmation: null,
		flow: SET_DAILY_LIMIT,
		error: null,
		componentIsLoading: false,
		updateIsLoading: false,
		removeLimitLoading: false,
	};

	componentDidMount() {
		/**
		 * Load the deposit limits
		 * Set a loading mask while loading the limits
		 * Remove when done
		 */
		this.setLoadingMask(true, 'componentIsLoading');

		this.props.getDepositLimit('weekly');
		this.props.getDepositLimit('monthly');
		return this.props.getDepositLimit('daily').finally(() => {
			this.setLoadingMask(false, 'componentIsLoading');
		});
	}

	/**
	 * Set a loading mask
	 *
	 * @param loading
	 * @param region
	 */
	setLoadingMask(loading = false, region) {
		this.setState({
			[region]: loading,
		});
	}

	/**
	 * handle content change when clicking on a tab item
	 * and send GA tracking
	 *
	 * @param screen
	 */
	handleScreenChange = (screen) => {
		let track;

		if (screen === SET_DAILY_LIMIT) {
			track = 'Daily';
		} else if (screen === SET_WEEKLY_LIMIT) {
			track = 'Weekly';
		} else if (screen === SET_MONTHLY_LIMIT) {
			track = 'Monthly';
		}

		this.props.onTabClick(track);

		this.handleClearMessage();

		this.setState({
			flow: screen,
		});
	};

	/**
	 * Calls the action to set the deposit limit
	 * Set an error message if the action fails
	 *
	 * @param amount
	 * @param type
	 */
	handleSetDepositLimit = (amount, type) => {
		this.setLoadingMask(true, 'updateIsLoading');

		const period = type === 'daily' ? 1 : type === 'weekly' ? 7 : 30;

		this.props
			.setDepositLimit(this.props.userId, amount, period)
			.then((response) => {
				this.props
					.getDepositLimit(type)
					.then(() => {
						this.setState({
							error: null,
							confirmation: response.data.data,
							shouldClear: true,
						});
					})
					.finally(() => {
						this.setLoadingMask(false, 'updateIsLoading');
						scrollToTop();
					});
			})
			.catch((error) => {
				this.setState({
					error: error.response && error.response.data ? error.response.data.errors : error.message,
					confirmation: null,
				});
				this.setLoadingMask(false, 'updateIsLoading');
				scrollToTop();
			});
	};

	/**
	 * Calls the action to remove the deposit limit
	 * Set an error message if the action fails
	 *
	 * @param type
	 */
	handleRemoveDepositLimit = (type) => {
		this.setLoadingMask(true, 'removeLimitLoading');

		const period = type === 'daily' ? 1 : type === 'weekly' ? 7 : 30;

		this.props
			.setDepositLimit(this.props.userId, -1, period)
			.then((response) => {
				this.props
					.getDepositLimit(type)
					.then(() => {
						this.setState({
							error: null,
							confirmation: response.data.data,
							shouldClear: true,
						});
					})
					.finally(() => {
						this.setLoadingMask(false, 'removeLimitLoading');
						scrollToTop();
					});
			})
			.catch((error) => {
				this.setState({
					error: error.response && error.response.data ? error.response.data.errors : error.message,
					confirmation: null,
				});

				this.setLoadingMask(false, 'removeLimitLoading');
				scrollToTop();
			});
	};

	/**
	 * Remove the error message
	 */
	handleClearMessage = () => {
		this.setState({
			error: null,
			confirmation: null,
		});
	};

	render() {
		const {
			className,
			first_name,
			daily_deposit_limit,
			weekly_deposit_limit,
			monthly_deposit_limit,
			brandName,
		} = this.props;

		const { confirmation, componentIsLoading, updateIsLoading, removeLimitLoading, flow, error } = this.state;

		const containerClasses = cx({
			[className]: className,
		});

		return (
			<BetLimits
				shouldClear={this.state.shouldClear}
				setShouldClear={(shouldClear) => this.setState({ shouldClear })}
				className={containerClasses}
				first_name={first_name}
				flow={flow}
				limitType="Deposit"
				daily_bet_limit={daily_deposit_limit}
				weekly_bet_limit={weekly_deposit_limit}
				monthly_bet_limit={monthly_deposit_limit}
				onScreenChange={this.handleScreenChange}
				setBetLimit={this.handleSetDepositLimit}
				removeBetLimit={this.handleRemoveDepositLimit}
				clearMessage={this.handleClearMessage}
				error={error}
				confirmation={confirmation}
				componentIsLoading={componentIsLoading}
				updateIsLoading={updateIsLoading}
				removeLimitLoading={removeLimitLoading}
				color="success"
				brandName={brandName}
			/>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		first_name: state.entities.users[state.application.authenticatedUser].first_name,
		userId: state.application.authenticatedUser,
		daily_deposit_limit: state.depositLimits.daily_deposit_limit,
		weekly_deposit_limit: state.depositLimits.weekly_deposit_limit,
		monthly_deposit_limit: state.depositLimits.monthly_deposit_limit,
		brandName: state.acl.brandDetails && state.acl.brandDetails.name,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDepositLimit: (period) => {
			return dispatch(getDepositLimit(period));
		},
		setDepositLimit: (userId, amount, period) => {
			return dispatch(setDepositLimit(userId, amount, period));
		},
		onTabClick: (type) => {
			return dispatch(trackGaEvent('DepositLimits', 'Select', type));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DepositLimitsContainer);

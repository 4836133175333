// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { withNamespaces } from 'react-i18next';

import { spacings, Text, PlotElements } from '@tbh/ui-kit';

// Components
import BetSummaryBetHeader from '../BetSummaryBetHeader/BetSummaryBetHeader';
import BetSummaryBetItem from '../BetSummaryBetItem/BetSummaryBetItem';
import CurrencyDisplay from '../../../Application/Currency/CurrencyDisplay/CurrencyDisplay';

// Constants
import {
	RACING_BET_TYPE_EACHWAY,
	RACING_BET_TYPE_MARGIN,
	RACING_BET_TYPE_SP,
	RACING_BET_TYPE_TF,
	RACING_BET_TYPE_TITLES,
	SRMTypes,
} from '../../../../../common/constants/Racing';
import { GOAT_PRODUCT_TYPE_BOOST } from '../../../../../common/constants/GoatProducts';
import {
	BET_TYPE_DERIVATIVE,
	BET_TYPE_QUINELLA,
	TOTE_NAMES_BY_PRODUCT,
	toteProducts,
} from '../../../../../store/entities/constants/BetConstants';

// Functions
import Format from '../../../../../legacy/core/format';
import { buildDerivativeLabel } from '../../../../../store/entities/selectors/BetSelectors';

// Context
import AclContext from '../../../../../contexts/AclContext';
import { COLORS } from '../../../../../constants/themes';

const StyledBetSummaryBetListItem__BetSummaryBetItem = styled(BetSummaryBetItem)(
	(props) => css`
		label: BetSummaryBetListItem__BetSummaryBetItem;

		&:not(:first-of-type) {
			margin-top: ${spacings(props).cozy}px;
		}
	`,
);

const BetSummaryBetListItem = (props) => {
	const {
		t,
		amount,
		betId,
		bet_selections,
		all_bet_selections,
		bet_type,
		boxed_flag,
		className,
		dividend,
		is_exotic,
		is_fixed,
		odds,
		display_amount,
		onBetHeaderClick,
		onClickBetListItem,
		paid,
		margin,
		percentage,
		product_code,
		product_type,
		selection_string,
		status,
		size,
		currencyType,
		selectedTab,
	} = props;

	const isTote = bet_selections.some((selection) => toteProducts.includes(selection.product));

	let getSubtitleRight = () => {
		let winningsMessage;
		if (status === 'unresulted') {
			winningsMessage = t('WinPotential');
		} else if (paid) {
			winningsMessage = t('Won');
		}
		return winningsMessage;
	};

	let getSubtitleRightAmount = () => {
		let unResulted = ['resulted', 'partially-refunded', 'fully-refunded', 'cancelled'];
	
		if (unResulted.indexOf(status) > -1) {
			return null;
		}
	
		if (paid) {
			return <CurrencyDisplay amount={paid} />;
		}
	
		if (isTote) {
			return 'TBD';
		} else {
			// Calculate total potential return
			const totalReturnAmount = amount * bet_selections.reduce((acc, s) => {
				return acc * s.fixed_odds;
			}, 1);
			
			// Calculate profit by subtracting original bet amount
			const profitAmount = totalReturnAmount - amount;
			
			return (
				<CurrencyDisplay 
					amount={profitAmount}
				/>
			);
		}
	};

	let getSelectionName = (selection) => {
		let selection_name;

		if (!selection || bet_type === BET_TYPE_QUINELLA) {
			selection_name = selection_string;
		} else if (bet_type === BET_TYPE_DERIVATIVE) {
			selection_name = selection.derivative_market_name;
		} else if (selection.market_name === 'Racing') {
			selection_name = selection.selection_number + '. ' + selection.selection_name;
		} else {
			selection_name = selection.selection_name;
		}

		if (boxed_flag && bet_type !== BET_TYPE_QUINELLA) {
			selection_name = selection_name.slice(0, -8);
		}

		return selection_name;
	};

	let getEventName = (selection) => {
		let eventName;
		if (selection.race_number) {
			eventName = `${t('Race')} #${selection.race_number} - ${Format.forHumans(selection.competition_name)}`;
		} else {
			eventName = selection.event_name;
		}
		return eventName;
	};

	let exoticEventName = (selections) => {
		let eventName = 'Race ';
		let raceNumbers = [];

		for (const s of selections) {
			if (raceNumbers.includes(s.race_number)) {
				continue;
			}
			raceNumbers.push(s.race_number);
		}

		raceNumbers.forEach((n, i) => {
			eventName += `#${n}`;
			if (i != raceNumbers.length - 1) {
				eventName += ' & ';
			}
		});

		if (eventName.length > 0) {
			eventName += ` - ${Format.forHumans(selections[0].competition_name)}`;
		}

		return eventName;
	};

	let getSelectionOdds = (selection, currencyType) => {
		let selectionOdds, oddsOrCode;

		if (bet_type === 'sameracemulti') {
			return (
				<PlotElements align="end">
					<span>
						{t('Bet')}
						&nbsp;
						<CurrencyDisplay amount={amount} />
					</span>
					<span>@ ${Format.decimalPlaces(display_amount / 100, 2)}</span>
				</PlotElements>
			);
		}

		if (Array.isArray(selection)) {
			return (
				<PlotElements align="end">
					<span>
						{t('Bet')}
						&nbsp;
						<CurrencyDisplay amount={amount} />
					</span>
					<span>{`@ $${selection[0].fixed_odds}/$${selection[1].fixed_odds}`}</span>
				</PlotElements>
			);
		}

		if (is_exotic) {
			oddsOrCode = `${percentage}%`;
		} else {
			oddsOrCode = selection.fixed_odds ? Format.decimalPlaces(selection.fixed_odds, 2) : selection.product;
		}
		if (oddsOrCode) {
			const isTote = toteProducts.includes(selection.product) && !is_exotic;
			selectionOdds = (
				<PlotElements align="end">
					{bet_type !== 'multi' ? (
						currencyType === 'bonusCurrency' ? (
							<AclContext.Consumer>
								{(acl) =>
									acl.currencyConfiguration && acl.currencyConfiguration.bonusCurrencyName ? (
										<span>
											{acl.currencyConfiguration.bonusCurrencyName} {t('Bet')}
											&nbsp;
										</span>
									) : (
										<span>
											{t('BonusBet')}
											&nbsp;
										</span>
									)
								}
							</AclContext.Consumer>
						) : (
							<span>
								{t('Bet')}
								&nbsp;
							</span>
						)
					) : null}
					{bet_type !== 'multi' && <CurrencyDisplay amount={amount} />}
					<span>
						&nbsp;@
						{isTote && 'TBD'}
						{!isTote && oddsOrCode}
					</span>
				</PlotElements>
			);
		}

		return selectionOdds;
	};

	const getMarketName = (selection) => {
		let market;
		let productInfo = '';

		const getToteName = (product, eventType) =>
			TOTE_NAMES_BY_PRODUCT[product] && TOTE_NAMES_BY_PRODUCT[product][eventType]
				? TOTE_NAMES_BY_PRODUCT[product][eventType]
				: product;

		const handleExoticMarket = () =>
			`${RACING_BET_TYPE_TITLES[bet_type]} (${getToteName(selection.product, selection.event_type)})`;

		const handleDerivativeMarket = () =>
			buildDerivativeLabel(selection.derivative_market_code, all_bet_selections, selection.opponent_selections, t);

		const handleRacingMarket = () => {
			const isMulti = bet_type === 'multi';
			const toteProductName = getToteName(selection.product, selection.event_type);

			const fixedOddsOrProductCode = isMulti
				? toteProducts.includes(selection.product)
					? toteProductName
					: selection.product
				: toteProducts.includes(product_code)
				? getToteName(product_code, selection.event_type)
				: is_fixed
				? 'FIXED'
				: product_code;

			const betType = isMulti && selection.bet_type ? selection.bet_type : bet_type;

			if (betType === 'eachway') {
				return 'Each way';
			}

			if (betType && fixedOddsOrProductCode) {
				if (product_type === GOAT_PRODUCT_TYPE_BOOST) {
					return (
						<React.Fragment>
							{`${t('To')} ${t(Format.forHumans(betType))} `}
							<span
								className={css`
									color: ${COLORS.red[300]};
								`}
							>
								(BOOST)
							</span>
						</React.Fragment>
					);
				}
				return `${t('To')} ${t(Format.forHumans(betType))} (${fixedOddsOrProductCode})`;
			}

			if (betType) {
				return `${t('To')} ${t(Format.forHumans(betType))}`;
			}

			return fixedOddsOrProductCode ? `(${fixedOddsOrProductCode})` : null;
		};

		const handleDefaultMarket = () => {
			const selectionLine = selection.selection_line;
			const line = !selectionLine ? '' : selectionLine > 0 ? ` +${selectionLine}` : ` ${selectionLine}`;

			return `${selection.market_name}${line}`;
		};

		const getProductInfo = () => {
			if (bet_type === RACING_BET_TYPE_MARGIN) {
				const marginLength = selection.margin ? ` - ${t('Adds')} ${selection.margin} ${t('length')}` : '';
				return ` - GOAT Margin Butt*${marginLength}`;
			}
			return '';
		};

		if (is_exotic) {
			market = handleExoticMarket();
		} else if (bet_type === BET_TYPE_DERIVATIVE) {
			market = handleDerivativeMarket();
		} else if (selection.market_name === 'Racing') {
			market = handleRacingMarket();
		} else {
			market = handleDefaultMarket();
		}

		if (boxed_flag && bet_type !== BET_TYPE_QUINELLA) {
			market = `${t('Boxed')} ${market}`;
		}

		productInfo = getProductInfo();

		if (bet_type === 'sameracemulti') {
			market = 'To Win (SRM)';
			productInfo = '';
		}

		return (
			<span>
				{market} {productInfo}
			</span>
		);
	};

	let getWinnings = () => {
		let winnings;
		let notResulted = ['unresulted', 'pending', 'partially-refunded'];

		let localOdds;

		if (bet_type === 'sameracemulti') {
			localOdds = display_amount / 100;
		} else if (bet_type === RACING_BET_TYPE_EACHWAY) {
			localOdds = odds;
		} else {
			localOdds = bet_selections && bet_selections[0].fixed_odds;
		}

		if (is_exotic) {
			winnings = paid ? <CurrencyDisplay amount={paid - amount} /> : t('TBD');
		} else {
			if (bet_type === 'multi') {
				winnings = null;
			} else {
				if (notResulted.indexOf(status) === -1) {
					winnings = paid ? <CurrencyDisplay amount={paid - amount} /> : amount * localOdds - amount;
				} else if (!is_fixed) {
					winnings = t('TBD');
				} else {
					if (product_code === RACING_BET_TYPE_SP || product_code === RACING_BET_TYPE_TF) {
						winnings = null;
					} else {
						winnings = localOdds ? (
							<CurrencyDisplay amount={amount * localOdds - amount} />
						) : dividend ? (
							dividend + '%'
						) : null;
					}
				}
			}
		}

		return winnings;
	};

	let getLegStatus = (selection) => {
		let leg_status;
		let betType = ['win', 'place', 'loss'];
		let notResulted = ['unresulted', 'pending'];
		let resulted = ['resulted', 'partially-refunded', 'fully-refunded','cancelled', 'paid'];

		if (resulted.indexOf(status) > -1) {
			leg_status = Format.forHumans(status);
		} else if (notResulted.indexOf(status) > -1) {
			// Accepted
			leg_status = Format.forHumans('pending');
		} else if (betType.indexOf(bet_type) > -1) {
			leg_status = '';
		} else {
			leg_status = Format.forHumans(selection.leg_status);
		}
		return leg_status;
	};

	const componentClasses = cx({
		[className]: className,
	});

	return (
		<div className={componentClasses}>
			{bet_type === 'multi' ? (
				<BetSummaryBetHeader
					betSubtitleLeft={
						<PlotElements align="start">
							<span>
								{t('Bet')}
								&nbsp;
							</span>
							<CurrencyDisplay amount={amount} />
							<span>
								&nbsp;
								{t('Multi')} @{' '}
								{`${Format.decimalPlaces(
									bet_selections.reduce((acc, s) => {
										return acc * s.fixed_odds;
									}, 1),
									2,
								)}`}
							</span>
						</PlotElements>
					}
					betSubtitleRight={getSubtitleRight()}
					betSubtitleRightAmount={getSubtitleRightAmount()}
					onClick={onBetHeaderClick}
				/>
			) : null}

			<div>
				{is_exotic && (
					<StyledBetSummaryBetListItem__BetSummaryBetItem
						size={size}
						date={bet_selections && bet_selections[0].date}
						leg_status={getLegStatus(bet_selections[0])}
						eventName={exoticEventName(bet_selections)}
						market_name={getMarketName(bet_selections[0])}
						selection_name={getSelectionName()}
						selectionOdds={getSelectionOdds(bet_selections && bet_selections[0], currencyType)}
						winnings={getWinnings()}
						onBetClick={onClickBetListItem}
						competition_id={bet_selections && bet_selections[0].competition_id}
						event_id={bet_selections && bet_selections[0].event_id}
						event_type={bet_selections && bet_selections[0].event_type}
						bet_type={bet_type}
						marginButt={bet_type === RACING_BET_TYPE_MARGIN ? margin : null}
						selectedTab={selectedTab}
					/>
				)}
				{bet_selections && bet_type === 'sameracemulti' && (
					<StyledBetSummaryBetListItem__BetSummaryBetItem
						size={size}
						key={bet_selections[0].id + bet_selections[1].id + '-' + bet_selections[0].selection_id}
						date={bet_selections[0].date}
						leg_status={getLegStatus(bet_selections[0])}
						market_name={getMarketName(bet_selections[0])}
						selection_name={bet_selections
							.map(({ product, selection_number }) => `${selection_number}. ${SRMTypes[product]}`)
							.join(' | ')}
						selectionOdds={getSelectionOdds(bet_selections, currencyType)}
						winnings={getWinnings()}
						onBetClick={onClickBetListItem}
						competition_id={bet_selections[0].competition_id}
						event_id={bet_selections[0].event_id}
						event_type={bet_selections[0].event_type}
						bet_type={bet_selections[0].bet_type || bet_type}
						marginButt={bet_type === RACING_BET_TYPE_MARGIN ? margin : null}
						silk={bet_selections[0].silk}
						selectedTab={selectedTab}
					/>
				)}
				{!is_exotic &&
					bet_selections &&
					bet_type != 'eachway' &&
					bet_type != 'sameracemulti' &&
					bet_selections.map((selection) => {
						return (
							<StyledBetSummaryBetListItem__BetSummaryBetItem
								size={size}
								key={selection.id + '-' + selection.selection_id}
								date={selection.date}
								eventName={getEventName(selection)}
								leg_status={getLegStatus(selection)}
								market_name={getMarketName(selection)}
								selection_name={getSelectionName(selection)}
								selectionOdds={getSelectionOdds(selection, currencyType)}
								winnings={getWinnings()}
								onBetClick={onClickBetListItem}
								competition_id={selection.competition_id}
								event_id={selection.event_id}
								event_type={selection.event_type}
								bet_type={selection.bet_type || bet_type}
								marginButt={bet_type === RACING_BET_TYPE_MARGIN ? margin : null}
								silk={selection.silk}
								selectedTab={selectedTab}
							/>
						);
					})}

				{!is_exotic && bet_selections && bet_type == 'eachway' && (
					<StyledBetSummaryBetListItem__BetSummaryBetItem
						size={size}
						key={bet_selections[0].id + bet_selections[1].id + '-' + bet_selections[0].selection_id}
						date={bet_selections[0].date}
						eventName={getEventName(bet_selections[0])}
						leg_status={getLegStatus(bet_selections[0])}
						market_name={getMarketName(bet_selections[0])}
						selection_name={getSelectionName(bet_selections[0])}
						selectionOdds={getSelectionOdds(bet_selections, currencyType)}
						winnings={getWinnings()}
						onBetClick={onClickBetListItem}
						competition_id={bet_selections[0].competition_id}
						event_id={bet_selections[0].event_id}
						event_type={bet_selections[0].event_type}
						bet_type={bet_selections[0].bet_type || bet_type}
						marginButt={bet_type === RACING_BET_TYPE_MARGIN ? margin : null}
						silk={bet_selections[0].silk}
						selectedTab={selectedTab}
					/>
				)}
			</div>

			<Text size="-2">#{betId}</Text>
		</div>
	);
};

const betSelection = PropTypes.shape({
	selection_id: PropTypes.number,
	selection_name: PropTypes.string,
	selection_number: PropTypes.number,
	odds: PropTypes.number,
	market_name: PropTypes.string,
	event_name: PropTypes.string,
	race_number: PropTypes.number,
	competition_name: PropTypes.string,
	date: PropTypes.string,
	leg_status: PropTypes.string,
	silk: PropTypes.string,
});

BetSummaryBetListItem.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** Bet amount  */
	amount: PropTypes.number.isRequired,

	/** Bet Id  */
	betId: PropTypes.number.isRequired,

	/** Bet selections */
	bet_selections: PropTypes.arrayOf(betSelection).isRequired,

	/** List of bet selections - used for derivatives */
	all_bet_selections: PropTypes.arrayOf(betSelection).isRequired,

	/** Action for when clicking on the BetSummaryBetHeader */
	onBetHeaderClick: PropTypes.func.isRequired,

	/** Action for when clicking on the BetSummaryBetItem node */
	onClickBetListItem: PropTypes.func.isRequired,

	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
	}).isRequired,

	/** Bet type: multi, win, sports */
	bet_type: PropTypes.string,

	/** Bet's currency type */
	currencyType: PropTypes.oneOf(['bonusCurrency', 'currency']),

	/** If it's a boxed bet */
	boxed_flag: PropTypes.bool,

	/** Extra classes */
	className: PropTypes.string,

	/** Bet dividend */
	dividend: PropTypes.number,

	/** If the bet is an exotic bet or not */
	is_exotic: PropTypes.bool,

	/** If bet is fixed */
	is_fixed: PropTypes.bool,

	/** Bet odds */
	odds: PropTypes.number,

	/** Paid amount */
	paid: PropTypes.number,

	/** Margin Butt margin */
	margin: PropTypes.number,

	/** Bet percentage */
	percentage: PropTypes.number,

	/** Product code */
	product_code: PropTypes.string,

	/** Product type */
	product_type: PropTypes.string,

	/** Selection name */
	selection_string: PropTypes.string,

	/** Bet status */
	status: PropTypes.string,
};

BetSummaryBetListItem.defaultProps = {
	bet_type: 'win',
	currencyType: 'currency',
	boxed_flag: false,
	className: '',
	dividend: 0,
	is_exotic: false,
	is_fixed: false,
	odds: 0,
	paid: 0,
	margin: null,
	percentage: 0,
	product_code: null,
	product_type: null,
	selection_string: null,
	status: 'unresulted',
};

export default withNamespaces()(BetSummaryBetListItem);

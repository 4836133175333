import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SizeMe } from 'react-sizeme';
import { navigateToRaceWithType } from '../../store/GRSracingHome/GRSracingHomeActions';
import { Ticker } from '@tbh/ui-kit';
//@HW add new pusher
import RaceNextJumpV5 from '../../components/features/Racing/RaceNextJumpV5/RaceNextJumpV5'; // create new @HW
import NextToJumpRacesContainerV5Loading from './NextToJumpRacesContainerV5Loading';
import { getNextToJumpRaces } from '../../pages/Racing/RacingHome/racingSelectorsGRS';
import { fetchNextToJumpRaces_GRS, nextNtjRace } from '../../store/entities/actions/NextToJumpRaceActions';
import PusherSubscriberNTJRacesChannel from '../../components/features/Racing/PusherSubscribers/PusherSubscriberNTJRacesChannel';

const NextToJumpRacesContainerV5 = (props) => {
	const { items, className, trackOnFilterClick, navigateToRaceWithType, hideRaceNextJumpFilter, showN2J } = props;

	if (!showN2J) {
		return null;
	}

	// useEffect(() => {
	// 	const id = setInterval(() => {
	// 		props.fetchNextToJumpRaces();
	// 	}, 4 * 30000); // 2 min delays

	// 	return () => {
	// 		clearInterval(id);
	// 	};
	// }, []);

	const races = items.filter((item) => item.event_status_id === 1);

	return (
		<React.Fragment>
			<SizeMe noPlaceholder>
				{({ size }) => (
					<Ticker every={90000}>
						<PusherSubscriberNTJRacesChannel />
						{/* <PusherSubscriberNTJRacesChannelV3 /> */}
						{races.length != 0 ? (
							<RaceNextJumpV5
								className={className}
								items={races}
								onGoToRace={navigateToRaceWithType}
								trackOnFilterClick={trackOnFilterClick}
								hideRaceNextJumpFilter={hideRaceNextJumpFilter}
								size={size}
							/>
						) : (
							<NextToJumpRacesContainerV5Loading size={size} />
						)}
					</Ticker>
				)}
			</SizeMe>
		</React.Fragment>
	);
};

NextToJumpRacesContainerV5.propTypes = {
	items: PropTypes.array.isRequired,
	isRacingHome: PropTypes.bool,
	trackOnFilterClick: PropTypes.func,
	className: PropTypes.string,
	hideRaceNextJumpFilter: PropTypes.bool,

	/** Navigates to the selected race page */

	navigateToRaceWithType: PropTypes.func.isRequired,

	/** current in store (gallops, harness or geyhounds) */
	raceType: PropTypes.string,

	raceDate: PropTypes.string,

	/** Whether or not to render the pusher updater */
	includePusher: PropTypes.bool,

	selectedPage: PropTypes.string.isRequired,
	showN2J: PropTypes.bool,

};

NextToJumpRacesContainerV5.defaultProps = {
    isRacingHome: false,
    trackOnFilterClick: () => {},
    className: '',
    hideRaceNextJumpFilter: false,
    raceType: '',
    raceDate: '',
    includePusher: false,
    showN2J: true,
};
const mapStateToProps = (state) => {
	const authenticatedUser = state.application.authenticatedUser;
	const user = state.entities.users[authenticatedUser] || {};

	const settings = user.settings || {};
	return {
		items: getNextToJumpRaces(state),
		raceType: state.grsRacingHome.selectedType,
		raceDate: state.grsRacingHome.selectedDate,
		selectedPage: state.application.selectedPage,
		showN2J: settings.showN2J != undefined ? settings.showN2J : true,
	};
};
const mapDispatchToProps = (dispatch) => ({
	navigateToRaceWithType: (type, date, raceId, meetingId, meetingName, raceNumber) => {
		dispatch(navigateToRaceWithType(type, date, raceId, meetingId));
		App.startSubApp('RacingNewPage');
	},
	updateNextToRaces: (races) => dispatch(nextNtjRace(races)),
	fetchNextToJumpRaces: () => {
		dispatch(fetchNextToJumpRaces_GRS());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(NextToJumpRacesContainerV5);

// Libraries
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'react-emotion';
import { Trans, withNamespaces } from 'react-i18next';

import { spacings, Button, ButtonGroup, Input, Link, Notification, Tabs, TabsItem, Text, Panel } from '@tbh/ui-kit';

import CurrencyDisplay from '../../../Application/Currency/CurrencyDisplay/CurrencyDisplay';

// Constants
import { SET_DAILY_LIMIT, SET_WEEKLY_LIMIT, SET_MONTHLY_LIMIT } from '../Constants';

const StyledSetLimit__Notification = styled(Notification)(
	(props) => css`
		label: SetLimit__Notification;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);

const StyledSetLimit__Actions = styled(ButtonGroup)(
	(props) => css`
		label: SetLimit__Actions;

		margin-bottom: ${spacings(props).cozy}px;
	`,
);
const StyledButton = styled(Button)(
	(props) => css`
		label: StyledButton;

		padding: ${spacings(props).compact}px;
		border-radius: 4px;
		font-weight: bold;
	`,
);

const StyledSetLimit = styled('div')(
	(props) => css`
		label: SetLimit;
	`,
);

// New NumberOnlyInput component
const NumberOnlyInput = ({ name, onChange, value, label, error, min, margin, nodeLeft, ...props }) => {
	const handleChange = (name, value) => {
		const numericValue = value.replace(/[^\d]/g, '');
		onChange(name, numericValue);
	};

	const handleKeyDown = (e) => {
		// Allow only numeric input, and control keys
		if (!/[\d]/.test(e.key) && !['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'].includes(e.key)) {
			e.preventDefault();
		}
	};

	const handlePaste = (e) => {
		const pasteData = e.clipboardData.getData('text');
		if (!/^\d+$/.test(pasteData)) {
			e.preventDefault();
		}
	};

	return (
		<Input
			name={name}
			onChange={handleChange}
			value={value}
			type="text"
			inputMode="numeric"
			label={label}
			error={error}
			min={min}
			margin={margin}
			onKeyDown={handleKeyDown}
			onPaste={handlePaste}
			nodeLeft={nodeLeft}
			{...props}
		/>
	);
};

const SetLimit = (props) => {
	const {
		t,
		limitType,
		flow,
		first_name,
		daily_bet_limit,
		new_daily_bet_limit,
		weekly_bet_limit,
		new_weekly_bet_limit,
		monthly_bet_limit,
		new_monthly_bet_limit,
		onTabChange,
		onChange,
		onSetNewLimit,
		onRemoveLimit,
		goToSelfExclusion,
		moreInfo,
		openMoreInfo,
		color,
		error,
		clearMessage,
		confirmation,
		updateIsLoading,
		removeLimitLoading,
		brandName,
		isRegistration,
		handleNoLimit,
	} = props;

	const period = flow === SET_DAILY_LIMIT ? t('daily') : flow === SET_WEEKLY_LIMIT ? t('weekly') : t('monthly');

	/**
	 * hook for setting/updating the limit
	 */
	const handleSetLimit = () => {
		handleClearMessage();
		flow === SET_DAILY_LIMIT
			? onSetNewLimit('daily')
			: flow === SET_WEEKLY_LIMIT
			? onSetNewLimit('weekly')
			: onSetNewLimit('monthly');
	};

	const handleClearMessage = () => {
		clearMessage();
	};

	/**
	 * hook for removing the limit
	 */
	const handleRemoveLimit = () => {
		onRemoveLimit(flow === SET_DAILY_LIMIT ? t('daily') : flow === SET_WEEKLY_LIMIT ? t('weekly') : t('monthly'));
	};

	const limitTypeLowerCase = t(limitType.toLowerCase());

	/**
	 * text to display depending on the current screen (flow)
	 * @type {string}
	 */
	const periodUpperCase =
		flow === SET_DAILY_LIMIT ? t('Daily') : flow === SET_WEEKLY_LIMIT ? t('Weekly') : t('Monthly');

	const currentLimit =
		flow === SET_DAILY_LIMIT ? daily_bet_limit : flow === SET_WEEKLY_LIMIT ? weekly_bet_limit : monthly_bet_limit;

	const daysRemaining = currentLimit.days_remaining_to_update | 0;

	const currentNewLimit =
		flow === SET_DAILY_LIMIT
			? new_daily_bet_limit
			: flow === SET_WEEKLY_LIMIT
			? new_weekly_bet_limit
			: new_monthly_bet_limit;

	/**
	 * text to display on the `more info` footer message
	 */
	const changePeriod =
		flow === SET_DAILY_LIMIT
			? t('SetLimit__24HourDay')
			: flow === SET_WEEKLY_LIMIT
			? t('SetLimit__7Day')
			: t('SetLimit__30Day');

	/**
	 * messages to show if the user has a requested limit
	 */

	const pendingLimitMessage = (
		<div>
			<Text size="-1" paragraph>
				{t('SetLimit__PendingLimitMessage', { limitTypeLowerCase })}{' '}
				<Text tag="span" type="danger" strong>
					{currentLimit.requested_bet_limit === -1 ? (
						t('removed')
					) : (
						<CurrencyDisplay amount={currentLimit.requested_bet_limit} />
					)}
				</Text>
			</Text>
			<Text size="-1" paragraph>
				<Trans i18nKey="SetLimit__IncreaseLimit" count={daysRemaining} values={{ daysRemaining }}>
					<strong>Note:</strong> You can increase limit after {{ daysRemaining }} days.
				</Trans>
			</Text>
		</div>
	);

	const adjustLimitMessage = (
		<Text size="-1" paragraph>
			{t('SetLimit__AdjustLimitMessage', { period, limitTypeLowerCase })}
		</Text>
	);

	const limitTypeText = limitType == 'Loss' ? 'lose' : limitType == 'Deposit' ? 'deposit' : 'spend';

	return (
		<StyledSetLimit>
			<Tabs justify padding={Tabs.paddings.SPACING_COMPACT}>
				<TabsItem action={onTabChange} data={SET_DAILY_LIMIT} active={flow === SET_DAILY_LIMIT}>
					{t('Daily')}
				</TabsItem>
				<TabsItem action={onTabChange} data={SET_WEEKLY_LIMIT} active={flow === SET_WEEKLY_LIMIT}>
					{t('Weekly')}
				</TabsItem>
				<TabsItem action={onTabChange} data={SET_MONTHLY_LIMIT} active={flow === SET_MONTHLY_LIMIT}>
					{t('Monthly')}
				</TabsItem>
			</Tabs>

			<Panel padding={Panel.padding.SPACING_COZY}>
				{error || confirmation ? (
					<StyledSetLimit__Notification
						strong={Boolean(error)}
						type={confirmation ? Notification.types.COLOUR_SUCCESS : Notification.types.COLOUR_DANGER}
						message={confirmation ? confirmation : error}
						buttonAction={handleClearMessage}
					/>
				) : null}

				<Text size="-1" paragraph>
					{first_name}, your current{' '}
					<strong>
						{period} {limitTypeLowerCase} limit
					</strong>{' '}
					is:
				</Text>

				<Text type={color} size="2" align="center" paragraph strong>
					{currentLimit.bet_limit > 0 ? <CurrencyDisplay amount={currentLimit.bet_limit} /> : t('SetLimit__NotSet')}
				</Text>

				{currentLimit.requested_bet_limit ? pendingLimitMessage : adjustLimitMessage}

				<NumberOnlyInput
					name={`new_${period}_bet_limit`}
					onChange={onChange}
					value={currentNewLimit}
					label={t('SetLimit__InputLabel', { periodUpperCase, limitType })}
					error={currentNewLimit && currentNewLimit < 1 ? 'Value must be greater than $1' : null}
					min="1"
					margin="cozy"
					nodeLeft={currentNewLimit && <CurrencyDisplay imageWidth={18} type={currentNewLimit} />}
				/>

				<Text size="-1" paragraph>
					{t('SetLimit__LowerLimit', { limitTypeLowerCase })}
				</Text>
				{goToSelfExclusion ? (
					<Text size="-1" paragraph>
						<Trans i18nKey="SetLimit__BlockAccess">
							If you would like to block your access to the site entirely, you can{' '}
							<Link type="primary" action={goToSelfExclusion}>
								request self-exclusion
							</Link>
							.
						</Trans>
					</Text>
				) : null}
				<Text size="-1" paragraph strong>
					{`${brandName ? brandName + ' ' : ''}${t('SetLimit__Support')}`}
				</Text>
				{!isRegistration ? (
					<StyledSetLimit__Actions center>
						{/* <StyledButton action={handleRemoveLimit} loading={removeLimitLoading} flex>
							{t('SetLimit__RemoveLimit')}
						</StyledButton> */}

						<StyledButton
							type="primary"
							action={handleSetLimit}
							disabled={currentNewLimit <= 0}
							loading={updateIsLoading}
							flex
						>
							{t('SetLimit__UpdateLimit')}
						</StyledButton>
					</StyledSetLimit__Actions>
				) : (
					<StyledSetLimit__Actions center>
						<StyledButton type="secondary" action={handleNoLimit} loading={removeLimitLoading} flex>
							{t('SetLimit__NoLimit')}
						</StyledButton>

						<StyledButton
							type="primary"
							action={handleSetLimit}
							disabled={currentNewLimit <= 0}
							loading={updateIsLoading}
							flex
						>
							{t('SetLimit__SetLimit')}
						</StyledButton>
					</StyledSetLimit__Actions>
				)}

				<Text size="-2">
					{t('SetLimit__LoseAmount', { changePeriod, limitType: limitTypeText })}{' '}
					{moreInfo ? (
						<Link type="primary" action={openMoreInfo}>
							{t('SetLimit__MoreInfo')}
						</Link>
					) : null}
				</Text>
			</Panel>
		</StyledSetLimit>
	);
};

SetLimit.propTypes = {
	/** Translation func provided by withNamespaces HOC */
	t: PropTypes.func.isRequired,

	/** User's first name */
	first_name: PropTypes.string.isRequired,

	/** The active tab */
	flow: PropTypes.string.isRequired,

	/**
	 * type of limit that will appear in the text
	 * Examples: 'deposit', 'loss', 'spend'
	 */
	limitType: PropTypes.string.isRequired,

	/** Function to clear the error message */
	clearMessage: PropTypes.func.isRequired,

	/** Handle changes in the input */
	onChange: PropTypes.func.isRequired,

	/** Hook for removing the limit */
	onRemoveLimit: PropTypes.func.isRequired,

	/** Hook for setting the limit */
	onSetNewLimit: PropTypes.func.isRequired,

	/** Handle content change when clicking on a tab item */
	onTabChange: PropTypes.func.isRequired,

	/** Redirects to Permanent Self-Exclusion */
	goToSelfExclusion: PropTypes.func,

	/** If the `More Info` link should appear or not */
	moreInfo: PropTypes.bool,

	/** Redirects to the More Info screen */
	openMoreInfo: PropTypes.func,

	/** User's old daily limit */
	daily_bet_limit: PropTypes.shape({
		bet_limit: PropTypes.number,
		requested_bet_limit: PropTypes.number,
		days_remaining_to_update: PropTypes.number,
		update_limit: PropTypes.bool,
	}),

	/** User's old weekly limit */
	weekly_bet_limit: PropTypes.shape({
		bet_limit: PropTypes.number,
		requested_bet_limit: PropTypes.number,
		days_remaining_to_update: PropTypes.number,
		update_limit: PropTypes.bool,
	}),

	/** User's old weekly limit */
	monthly_bet_limit: PropTypes.shape({
		bet_limit: PropTypes.number,
		requested_bet_limit: PropTypes.number,
		days_remaining_to_update: PropTypes.number,
		update_limit: PropTypes.bool,
	}),

	/** User's new daily limit */
	new_daily_bet_limit: PropTypes.string,

	/** User's new weekly limit */
	new_weekly_bet_limit: PropTypes.string,

	/** User's new monthly limit */
	new_monthly_bet_limit: PropTypes.string,

	/** Color for the buttons and current limit */
	color: PropTypes.oneOf(['danger', 'success']),

	/** Error message to display */
	error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),

	/** Confirmation message */
	confirmation: PropTypes.string,

	/** If the limit update request is loading or not */
	updateIsLoading: PropTypes.bool,

	/** If the limit removal request is loading or not */
	removeLimitLoading: PropTypes.bool,

	/** Brand name */
	brandName: PropTypes.string,
};

SetLimit.defaultProps = {
	goToSelfExclusion: null,
	moreInfo: false,
	openMoreInfo: null,
	daily_bet_limit: {},
	weekly_bet_limit: {},
	monthly_bet_limit: {},
	new_daily_bet_limit: '',
	new_weekly_bet_limit: '',
	new_monthly_bet_limit: '',
	color: 'danger',
	error: null,
	confirmation: null,
	updateIsLoading: false,
	removeLimitLoading: false,
	brandName: null,
	isRegistration: false,
	handleNoLimit: () => {},
};

export default withNamespaces()(SetLimit);

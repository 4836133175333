import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';

import FeatureContext from '../../../../contexts/featureContext';

import { BettingAdd } from '@tbh/ui-kit';

class GBSNjRaceBettingAdd extends Component {
	static propTypes = {
		/** The size of the component - used for media query logic */
		size: PropTypes.shape({
			width: PropTypes.number,
		}),

		/** Contains the id of the product being bet on */
		productId: PropTypes.number.isRequired,

		/** Boolean indicating if betting is available ie. race isn't closed */
		bettingAvailable: PropTypes.bool.isRequired,

		/** Id of the selection being bet on */
		selectionId: PropTypes.number.isRequired,

		/** Contains the price or product code to display */
		price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,

		/** The type of bet to place */
		betType: PropTypes.string.isRequired,

		/** Whether or not the bet has multi bets available */
		hasMulti: PropTypes.bool,

		/** Action to add the selection to the bet prompt */
		addToSingle: PropTypes.func.isRequired,

		/** Action to add the selection to the multi bet slip */
		addToMulti: PropTypes.func,

		/** Stores the initial bet trend */
		initialTrend: PropTypes.oneOf(['positive', 'negative']),

		/** An initial price to display on mount */
		initialPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

		/** If the component needs an emphasized background color or border */
		emphasisedType: PropTypes.oneOf(['background', 'border']),

		/** Flag if the component should add a wrapper */
		useWrapper: PropTypes.bool,

		/** The number of rolls to perform when the price changes */
		priceRollups: PropTypes.number,

		/** Whether to use a small amount of flex to fit more buttons */
		small: PropTypes.bool,

		/** Extra class(es) to pass through */
		className: PropTypes.string,
	};

	static defaultProps = {
		className: null,
		bettingAvailable: true,
		addToMulti: undefined,
		initialTrend: null,
		initialPrice: null,
		emphasisedType: null,
		priceRollups: 0,
		hasMulti: false,
		useWrapper: false,
		small: false,
		size: null,
	};

	constructor(props) {
		super(props);

		this.state = {
			// 	Used to show trending indicators on the bet buttons when the prices changes
			// 	 * ie. 'positive' or 'negative'
			trending: props.initialTrend || null,

			// Used to signify if we should display a different price on mount
			displayInitialPriceOnMount: !isNaN(parseFloat(props.initialPrice)) && props.initialPrice !== props.price,

			// Used for tracking price changes
			price: props.price,
		};
	}

	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.price !== prevState.price) {
			/** Determine whether or not the price is trending and what direction */
			const priceDifference = nextProps.price - prevState.price;

			// There will be no trending when there is no price
			if (!isNaN(priceDifference)) {
				// If there is a price difference then set the trend
				const latestTrend = priceDifference > 0 ? 'positive' : priceDifference !== 0 ? 'negative' : null;

				// If the current trend is not equal to the latest trend then set a trend change
				const trendChange = prevState.trending !== latestTrend ? latestTrend : null;

				// If there is a trend change set the state of trending
				if (trendChange) {
					return {
						trending: trendChange,
						price: nextProps.price,
					};
				}

				return {
					price: nextProps.price,
				};
			}
		}

		return null;
	}

	componentDidMount() {
		if (this.state.displayInitialPriceOnMount) {
			// After mount we no longer want to display the initial price
			this.setState({ displayInitialPriceOnMount: false });
		}
	}

	/**
	 * Handles the clicking of a single bet
	 *
	 * @param e
	 */
	handleAddSingle = (e) => {
		e.stopPropagation();
		this.props.addToSingle(this.props.selectionId, this.props.productId, this.props.betType, e);
	};
	/**
	 * Handles the clicking of a multi bet
	 *
	 * @param e
	 */
	handleAddMulti = (e) => {
		e.stopPropagation();
		this.props.addToMulti(this.props.selectionId, this.props.productId, this.props.betType, e);
	};

	render() {
		const {
			className,
			useWrapper,
			bettingAvailable,
			emphasisedType,
			price,
			initialPrice,
			hasMulti,
			priceRollups,
			size,
			small,
		} = this.props;

		const componentClasses = cx({
			[className]: className,
		});

		return (
			<FeatureContext.Consumer>
				{(featureToggles) => {
					const racingButtonMultiAvailable =
						featureToggles.features.racingMultiButton.enabled && hasMulti && bettingAvailable
							? this.handleAddMulti
							: null;
					return (
						<BettingAdd
							className={componentClasses}
							size="0"
							// metric={this.state.displayInitialPriceOnMount ? initialPrice : price}
							metric={price}
							actionSingle={racingButtonMultiAvailable}
							// actionMulti={racingButtonMultiAvailable}
							trend={this.state.trending ? this.state.trending : null}
							disabled={!bettingAvailable ? true : null}
							noPadding={true}
							emphasisedType={emphasisedType}
							priceRollups={priceRollups}
							useWrapper={useWrapper}
							mediaSize={size}
							small={small}
						/>
					);
				}}
			</FeatureContext.Consumer>
		);
	}
}

export default GBSNjRaceBettingAdd;

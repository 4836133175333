import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames/bind';
import styled, { css } from 'react-emotion';
import { application, spacings, typography, ui, media, Text, Button, ArrowIcon } from '@tbh/ui-kit';
import { SizeMe } from 'react-sizeme';
import RaceSelectionDetails from './../RaceSelectionDetails/RaceSelectionDetails';
import RunnerForm from '../../RunnerForm/RunnerForm';
import { CollapsibleContent, ListItem, Checkbox } from '@tbh/ui-kit';
import RaceBettingAdd from '../../../Racing/RaceBettingAdd/RaceBettingAdd';
/**
 * Define all the selection statuses & racing type codes as defined in the racing constants file
 */
import {
	RACING_BET_TYPE_STRAIGHT_EIGHT,
	DEFAULT_EXOTIC_CHECKBOX_STATE,
	RACING_TYPE_CODES,
	SELECTION_NOT_SCRATCHED_STATUS,
	SELECTION_LATE_SCRATCHED_STATUS,
	SELECTION_STATUSES,
	RACING_BET_TYPE_MARGIN,
	RACING_NON_EXOTIC_BET_TYPE,
	RACING_ODD_GRID_BET_TYPE,
	RACING_BET_TYPE_ISSURE_BET,
	RACING_BET_TYPE_NOTTO_WIN_BET,
	RACING_BET_TYPE_TRAINER_WIN_BET,
	RACING_MORE_MARKETS_TYPE,
	RACING_SAME_RACE_MULTI_TYPE,
	RACING_BET_TYPE_EACHWAY,
	RACING_BET_TYPE_TF,
} from '../../../../../common/constants/Racing';
import { GOAT_PRODUCT_TYPE_BOOST } from '../../../../../common/constants/GoatProducts';
import RaceStrikeRate from '../RaceStrikeRate/RaceStrikeRate';
import {
	StyledRaceStrikeRate__Subtitle,
	StyledRaceStrikeRate_header,
	StyledRaceStrikeRate_percentage_header,
} from '../RaceSelectionsList';
import { toteProducts } from '../../../../../store/entities/constants/BetConstants';
import { TABLET_MAX_WIDTH } from '../../../../../common/constants/Breakpoints';
const StyledRaceSelectionsListItem = styled(ListItem)`
	${(props) =>
		css`
			label: RaceSelectionsListItem;

			list-style: none;
			padding: ${spacings(props).tight}px!important;
			&:nth-of-type(4n + 1):hover,
			&:hover {
				background: ${ui(props).color_3};
			}

			// &:nth-of-type(4n + 1) {
			// 	background: #f5f5f5;
			// }

			tr:last-child {
				border-bottom: none;
			}

			&:not(:last-of-type) {
				border-bottom: 1px solid hsl(0deg 0% 85%);
			}

			&:first-of-type {
				border-top: ${props.selection_status === SELECTION_LATE_SCRATCHED_STATUS ? '0' : '1px solid hsl(0deg 0% 85%)'};
			}

			${props.selection_status === SELECTION_LATE_SCRATCHED_STATUS &&
				css`
					&:before {
						display: block;
						position: absolute;
						background: ${ui(props).color_5};
						color: ${typography(props).color_1_alternate};
						padding: ${spacings(props).tight}px;
						width: 30px;
						height: 30px;
						border-radius: 20px;
						font-size: ${typography(props)['size_-2']};
						line-height: 24px;
						text-align: center;
						content: 'LS';
					}
				`};
		`};
`;

const StyledRaceSelectionsListItem__CollapsibleExoticSection = styled(CollapsibleContent)`
	${(props) =>
		css`
			label: RaceSelectionsListItem__CollapsibleExoticSection;

			&:nth-of-type(odd) {
				background: ${ui(props).color_2};
			}
			padding: ${spacings(props).tight}px 0;
		`};
`;

const StyledRaceSelectionsListItem__Wrapper = styled('div')(
	(props) =>
		css`
			label: RaceSelectionsListItem__Wrapper;

			// Set a min-height so the trends don't overlap
			min-height: ${spacings(props).roomy}px;
			display: flex;
			align-items: flex-start;
			position: relative;
			justify-content: end;
			flex-wrap: wrap;
			align-items: center;
		`,
	media(
		(props) =>
			css`
				flex-wrap: ${props.open ? 'wrap' : 'nowrap'};
			`,
		TABLET_MAX_WIDTH,
	),
);

const ListItemWrapper = styled('div')(
	(props) => css`
		label: RaceSelectionsListItem_selection_details;

		display: flex;
		list-style: none;
		flex: 1 1 auto;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	`,
	media(
		css`
			flex-direction: row;
		`,
		TABLET_MAX_WIDTH,
	),
);

const StyledRaceSelectionsListItem_selection_details = styled(ListItem)(
	(props) =>
		css`
			label: RaceSelectionsListItem_selection_details;
			display: flex;
			list-style: none;
		`,
);

const StyledRaceSelectionsListItem__ExtraColumns = styled('div')(
	(props) => css`
		label: RaceSelectionsListItem__ExtraColumns;

		display: none;
		// flex: 0 0 80px;
		// width: 80px;
		font-size: ${typography(props)['size_-4']}px;
		line-height: 1.2;
		text-align: end;
		background-color: transparent;
	`,
	media(
		css`
			display: block;
		`,
		TABLET_MAX_WIDTH,
	),
);

const Styledliability__lable__B1 = styled('div')(
	(props) => css`
		label: Styledliability__lable__B1;
		background: #fad701;
		border-radius: 0rem 0rem 0.125rem 0.125rem;
		color: #000000;
		display: block;
		line-height: 0.825rem;
		text-align: center;
		font-size: 0.7rem;
		width: 4.4rem;
	`,
);

const StyledPricesWithLiability = styled('div')(
	(props) => css`
		label: StyledPricesWithLiability;
		position: relative !important;
		width: 100%;
	`,
);

const StyledPrices = styled('div')(
	(props) => css`
		display: flex;
		gap: ${spacings(props).tight}px;
		width: ${props.buttons * 52}px;
	`,
);

const StyledPrices1 = styled('div')(
	(props) => css`
		display: flex;
		gap: ${spacings(props).tight}px;
		width: ${props.buttons * 52}px;
	`,
);

const ButtonsWrappers = styled('div')(
	(props) => css`
		display: flex;
		height: 100%;
		align-items: center;
		width: fit-content;
	`,
);

const StyledRaceSelectionsListItem__Deductions = styled(Text)`
	${(props) =>
		css`
			label: RaceSelectionsListItem__Deductions;
			margin-right: ${spacings(props).compact}px;
		`};
`;

const StyledRaceBettingAdd = styled(RaceBettingAdd)(
	(props) => css`
	label: RaceBettingAdd;
	// flex: 0 0 ${application(props).racing.odds_button_width}px;
	// width: 70px;
	width: 100%;
	text-align: center;
	position: relative;
	height: 36px;
	border-style: solid;
	transition: 0.0001s ease;
	border-radius: 4px;
	background-color:#e3e3e3;
	flex: 1!important;
	max-width: 100% !important;
	border: 1px solid #d5d5d5;
	align-items: center;
	// Remove inside span hover it should be handled by the parent
	span {
		&:hover {
			background-color: transparent!important;
		};
	}
	white-space: nowrap;
	
		${props.emphasisedType === 'background' &&
			`
			span {
				color: black!important;

				&:hover {
					color: white!important;
				};
			}
		`}

		${props.sameRaceMultiActive &&
			`
			background-color: #850000!important;

			span {
				color: white!important;
			};

			&:hover {
				background-color: #b30000!important;
			}
		`}

		${props.scratched &&
			css`
				display: none;
			`};

		${props.betType === RACING_BET_TYPE_MARGIN &&
			css`
				margin: 0;
			`};
`,
	media(
		(props) =>
			css`
	// flex: 0 0 ${application(props).racing.odds_button_width_small}px;
	// max-width: ${application(props).racing.odds_button_width_small}px;
	`,
		320,
	),
);

const StyledRaceSelectionsListItem__Checkbox = styled(Checkbox)`
	${(props) =>
		css`
			label: RaceSelectionsListItem__Checkbox;

			flex: 0 0 28px;
			max-width: 28px;
			border-radius: 4px;
			margin-left: ${spacings(props).tight}px;
		`};
`;

const StyledShowOldPriceWhenPriceBump = styled('div')(
	(props) => css`
label: StyledShowOldPriceWhenPriceBump;

background: #4123231a;
color: #070B19;
border: 1px solid #f9f9f99c;
width: 45px;
text-transform: uppercase;
padding: ${spacings(props).compact}px;
//padding-right: ${spacings(props).tight}px;
`,
);

const StyledRaceFlucs = styled('div')(
	(props) => css`
		padding: ${spacings(props).compact}px;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: ${spacings(props).tight}px;
		flex-wrap: wrap;
		max-width: 100%;
	`,
	// media(props =>
	// 	css`
	// 		max-width:  ${props.showhotStatsOpen? '100%' : '100%'};
	// 	`,
	// 	TABLET_MAX_WIDTH,
	// ),
);

const OpenRaceFlucs = styled('div')(
	(props) => css`
		padding: ${spacings(props).tight}px;
		div {
			color: #fff !important;
		}
		background-color: #000000;
		border-radius: ${spacings(props).tight}px;
	`,
);

const StyledHotSelection_Fav_Icon = styled(Text)`
	${(props) =>
		css`
			color: #000000;
			background-color: #ffc107;
			border-radius: 2px;
			text-align: center;
			font-size: 8px;
			position: absolute;
			bottom: 0px;
			width: 100%;
			border: 2px solid #ffc107;
			font-weight: bold;
			text-transform: uppercase;
			line-height: 1;
		`}
`;

const HotStrikeRateGroup = styled('div')(`
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	gap: 4px;
	width: 216px;
	padding-right: 8px;
`);
function removeDuplicatePrices(prices) {
	return prices.filter((price, index, array) => {
		// Keep the price if it's not equal to the next one
		return index === array.length - 1 || price !== array[index + 1];
	});
}
const RaceSelectionsListItem = React.memo((props) => {
	const {
		t,
		comment,
		selectionBetButtons,
		checkBoxesDisplayed,
		checkBoxStatus,
		runner,
		formOpened,
		toggleForm,
		id,
		selection_status,
		number,
		name,
		silk,
		jockey,
		barrier,
		weight,
		allocation,
		bettingAvailable,
		last_starts_string,
		type_code,
		displayedFlucs,
		apn_fluctuations,
		topbetta_fluctuations,
		addToSingle,
		addToMulti,
		handleCheckboxChange,
		className,
		win_deductions,
		place_deduction,
		goatLength,
		trainer,
		isMobileWidth,
		betType,
		boxed,
		size,
		ShowModal,
		showPlaceHotStats,
		selectionPrice,
		jockey_horse_percentage,
		jockey_percentage,
		trainer_percentage,
		jockey_trainer_percentage,
		hot_stats,
		hotSelection,
		showTote,
		showhotStatsOpen,
		showFlucs_Chart,
		selection_fluctuations,
		raceNumber,
		price,
		test,
		testt,
		sel,
		sppedmapOpen,
		scratching_time,
		user,
		enabled_cache_out,
		handleAddToBackBook,
		MettingName,
		StartDate,
		backBookResponse,
		selectedGoatProduct,
		showSelectWinFixedOldPrice,
		selectionsSameRaceMulti,
		handleSameRaceMultiSelections,
		country,
		prize_money,
	} = props;

	const componentClasses = cx({
		[className]: className,
	});

	// showing old win fix price. when click price bump
	const selectionPriceBup = showSelectWinFixedOldPrice.find((item) => item.id === id);
	// flucs array
	const allflucs = removeDuplicatePrices(selection_fluctuations.map((e) => e.price));

	// const last4Flucs = allflucs.slice(-4).map((e) => e);
	const show_fluctuation = allflucs
		.slice(-3)
		.map((e) => e)
		.join(',');
	// const show_fluctuation = (selection_fluctuations.slice(-3)).map(e => e.price).join(',');// old verion

	const ReduceSelectionButtons = (selectionBetButtons) => {
		let reducedSelectionButtons = [];
		selectionBetButtons.forEach((item, i) => {
			// tem msm id mas tem types diferentes
			const sameProductTypeAndId = reducedSelectionButtons.find(
				(selection) => selection.product_id === item.product_id && selection.bet_type === item.bet_type,
			);
			if (!sameProductTypeAndId) {
				let onlyOneObject = selectionBetButtons.filter(
					(selection) => selection.product_id === item.product_id && selection.bet_type === item.bet_type,
				)[0];

				reducedSelectionButtons.push(onlyOneObject);
			}
		});

		// let newSelectionBetButtons = selectionBetButtons.filter((item, i) => selectionBetButtons.indexOf(item) === i);;
		return reducedSelectionButtons;
	};

	const reducedSelectionButtons = ReduceSelectionButtons(selectionBetButtons);

	/**
	 * Build an array of checkboxes to need to be displayed for each item
	 *
	 * @param checkBoxesDisplayed
	 * @param checkBoxStatus
	 * @returns {Array}
	 */
	const buildCheckboxes = (checkBoxesDisplayed, checkBoxStatus) => {
		let checkBoxArray = [];
		/**
		 * Loop through the checkBoxesDisplayed object eg. {1: true, 2: true, 3: false, 4: false}
		 * and for each checkbox that will be displayed add that checkbox to the selection list item
		 */
		Object.keys(checkBoxesDisplayed).forEach((key) => {
			if (checkBoxesDisplayed[key]) {
				checkBoxArray.push(
					<StyledRaceSelectionsListItem__Checkbox
						key={key}
						name={'checkbox_' + key}
						checked={checkBoxStatus[key]}
						action={toggleCheckbox}
						data={key}
						size="large"
					/>,
				);
			}
		});

		return checkBoxArray;
	};

	/**
	 * Handles the toggling of each checkbox
	 *
	 * @param e
	 * @param checkboxIndex
	 */
	const toggleCheckbox = (e, checkboxIndex) => {
		handleCheckboxChange(checkboxIndex, id);
	};

	let ShowModle = (fuData, id, number, silk, raceNumber, last_starts_string, trainer, jockey, weight) => {
		ShowModal(fuData, id, number, silk, raceNumber, last_starts_string, trainer, jockey, weight);
	};

	const fuDataGraph = selection_fluctuations.map(function(flucs) {
		return { fixTime: flucs.time, fixed_odds: Number(flucs.price) };
	});

	const isJockHot = type_code === 'G' || type_code === 'H' || hot_stats === null ? '' : hot_stats.is_jockey_hot;
	const isTrainerHot = type_code === 'G' || type_code === 'H' || hot_stats === null ? '' : hot_stats.is_trainer_hot;

	const get_price = (betButton) => {
		if (selectionBetButtons[3].liability === 0) {
			if (selectionBetButtons[2].liability === 0) {
				if (selectionBetButtons[1].liability === 0) {
					return betButton.product_id === 19
						? selectionBetButtons[0].price
						: betButton.product_id === 20
						? selectionBetButtons[1].price
						: betButton.product_id === 21
						? selectionBetButtons[2].price
						: 0;
				}
				return betButton.product_id === 19
					? selectionBetButtons[1].price
					: betButton.product_id === 20
					? selectionBetButtons[0].price
					: betButton.product_id === 21
					? selectionBetButtons[3].price
					: 0;
			}
			if (selectionBetButtons[1].liability === 0) {
				return betButton.product_id === 19
					? selectionBetButtons[2].price
					: betButton.product_id === 20
					? selectionBetButtons[0].price
					: betButton.product_id === 21
					? selectionBetButtons[3].price
					: 0;
			}
			return betButton.product_id === 19
				? selectionBetButtons[2].price
				: betButton.product_id === 20
				? selectionBetButtons[0].price
				: betButton.product_id === 21
				? selectionBetButtons[0].price
				: 0;
		}
		if (selectionBetButtons[2].liability === 0) {
			if (selectionBetButtons[1].liability === 0) {
				return betButton.product_id === 19
					? selectionBetButtons[3].price
					: betButton.product_id === 20
					? selectionBetButtons[0].price
					: betButton.product_id === 21
					? selectionBetButtons[1].price
					: 0;
			}
			return betButton.product_id === 19
				? selectionBetButtons[3].price
				: betButton.product_id === 20
				? selectionBetButtons[1].price
				: betButton.product_id === 21
				? selectionBetButtons[0].price
				: 0;
		}
		if (selectionBetButtons[1].liability === 0) {
			return betButton.product_id === 19
				? selectionBetButtons[3].price
				: betButton.product_id === 20
				? selectionBetButtons[2].price
				: betButton.product_id === 21
				? selectionBetButtons[0].price
				: 0;
		}
		return betButton.price;
	};

	const get_product_id = (betButton) => {
		if (selectionBetButtons[3].liability === 0) {
			if (selectionBetButtons[2].liability === 0) {
				if (selectionBetButtons[1].liability === 0) {
					return betButton.product_id === 19
						? selectionBetButtons[0].product_id
						: betButton.product_id === 20
						? selectionBetButtons[1].product_id
						: betButton.product_id === 21
						? selectionBetButtons[2].product_id
						: 0;
				}
				return betButton.product_id === 19
					? selectionBetButtons[1].product_id
					: betButton.product_id === 20
					? selectionBetButtons[0].product_id
					: betButton.product_id === 21
					? selectionBetButtons[3].product_id
					: 0;
			}
			if (selectionBetButtons[1].liability === 0) {
				return betButton.product_id === 19
					? selectionBetButtons[2].product_id
					: betButton.product_id === 20
					? selectionBetButtons[0].product_id
					: betButton.product_id === 21
					? selectionBetButtons[3].product_id
					: 0;
			}
			return betButton.product_id === 19
				? selectionBetButtons[2].product_id
				: betButton.product_id === 20
				? selectionBetButtons[1].product_id
				: betButton.product_id === 21
				? selectionBetButtons[0].product_id
				: 0;
		}
		if (selectionBetButtons[2].liability === 0) {
			if (selectionBetButtons[1].liability === 0) {
				return betButton.product_id === 19
					? selectionBetButtons[3].product_id
					: betButton.product_id === 20
					? selectionBetButtons[0].product_id
					: betButton.product_id === 21
					? selectionBetButtons[1].product_id
					: 0;
			}
			return betButton.product_id === 19
				? selectionBetButtons[3].product_id
				: betButton.product_id === 20
				? selectionBetButtons[1].product_id
				: betButton.product_id === 21
				? selectionBetButtons[0].product_id
				: 0;
		}
		if (selectionBetButtons[1].liability === 0) {
			return betButton.product_id === 19
				? selectionBetButtons[3].product_id
				: betButton.product_id === 20
				? selectionBetButtons[2].product_id
				: betButton.product_id === 21
				? selectionBetButtons[0].product_id
				: 0;
		}
		return betButton.product_id;
	};
	const get_libility = (betButton) => {
		if (selectionBetButtons[3].liability === 0) {
			if (selectionBetButtons[2].liability === 0) {
				if (selectionBetButtons[1].liability === 0) {
					return betButton.product_id === 19
						? selectionBetButtons[0].liability
						: betButton.product_id === 20
						? selectionBetButtons[1].liability
						: betButton.product_id === 21
						? selectionBetButtons[2].liability
						: 0;
				}
				return betButton.product_id === 19
					? selectionBetButtons[1].liability
					: betButton.product_id === 20
					? selectionBetButtons[0].liability
					: betButton.product_id === 21
					? selectionBetButtons[3].liability
					: 0;
			}
			if (selectionBetButtons[1].liability === 0) {
				return betButton.product_id === 19
					? selectionBetButtons[2].liability
					: betButton.product_id === 20
					? selectionBetButtons[0].liability
					: betButton.product_id === 21
					? selectionBetButtons[3].liability
					: 0;
			}
			return betButton.product_id === 19
				? selectionBetButtons[2].liability
				: betButton.product_id === 20
				? selectionBetButtons[1].liability
				: betButton.product_id === 21
				? selectionBetButtons[0].liability
				: 0;
		}
		if (selectionBetButtons[2].liability === 0) {
			if (selectionBetButtons[1].liability === 0) {
				return betButton.product_id === 19
					? selectionBetButtons[3].liability
					: betButton.product_id === 20
					? selectionBetButtons[0].liability
					: betButton.product_id === 21
					? selectionBetButtons[1].liability
					: 0;
			}
			return betButton.product_id === 19
				? selectionBetButtons[3].liability
				: betButton.product_id === 20
				? selectionBetButtons[1].liability
				: betButton.product_id === 21
				? selectionBetButtons[0].liability
				: 0;
		}
		if (selectionBetButtons[1].liability === 0) {
			return betButton.product_id === 19
				? selectionBetButtons[3].liability
				: betButton.product_id === 20
				? selectionBetButtons[2].liability
				: betButton.product_id === 21
				? selectionBetButtons[0].liability
				: 0;
		}
		return betButton.liability;
	};

	/**
	 * We will have a property (possibly ‘product_type’) that identifies if its bump or butt
	 */

	const selectionAndProductIsSelectedRaceMulti = (selection) => {
		if (!selectionsSameRaceMulti) return false;

		return !!selectionsSameRaceMulti.find(
			(selectionInBet) => selectionInBet.id === id && selectionInBet.product_id === selection.product_id,
		);
	};

	const isAvailable = (betButton) => {
		// In case is EachWay accept with EW price;

		if (!bettingAvailable) {
			return false;
		}

		if (
			betButton.product_type === RACING_BET_TYPE_EACHWAY ||
			toteProducts.includes(betButton.product_code) ||
			betButton.product_code === RACING_BET_TYPE_TF
		) {
			return true;
		}

		// eslint-disable-next-line
		let validate = parseInt(betButton.price, 10) && betButton.productAvailable;

		let validateType =
			betType === RACING_NON_EXOTIC_BET_TYPE ||
			betType === RACING_ODD_GRID_BET_TYPE ||
			betType === RACING_BET_TYPE_ISSURE_BET ||
			betType === RACING_BET_TYPE_NOTTO_WIN_BET ||
			betType === RACING_BET_TYPE_TRAINER_WIN_BET ||
			betType === RACING_SAME_RACE_MULTI_TYPE ||
			betType === RACING_MORE_MARKETS_TYPE;
		if (betType === RACING_SAME_RACE_MULTI_TYPE) {
			return validate && validateType;
			// && productIsAndIsRaceMulti(betButton)
			// && selectionIsSelectedRaceMulti(betButton)
			// && selectionAndProductIsSelectedRaceMulti(betButton);
		} else {
			return validate && validateType;
		}
	};

	const betButtonStyle = (betButton) => {
		let style = '';

		if (betButton.product_id === 19) {
			style += 'background: #a6d8ff;height: 35px;';
		}

		return css`
			${style}
		`;
	};

	const renderDeductions = () => {
		if (selection_status == SELECTION_NOT_SCRATCHED_STATUS) return;

		return (
			<div
				className={css`
					display: flex;
					width: 100%;
					justify-content: flex-end;
					align-items: center;
					margin-top: 8px;
				`}
			>
				<StyledRaceSelectionsListItem__Deductions align="center" size="-4" type="danger">
					<Text
						size="-5"
						type="light"
						style={{
							color: win_deductions <= 0 && 'transparent',
						}}
					>
						Win Deduction
					</Text>
					<div
						style={{
							color: win_deductions <= 0 && 'transparent',
						}}
					>
						${(win_deductions / 100).toFixed(2)}
					</div>
				</StyledRaceSelectionsListItem__Deductions>

				<StyledRaceSelectionsListItem__Deductions align="center" size="-4" type="danger">
					<Text
						size="-5"
						type="light"
						style={{
							color: place_deduction <= 0 && 'transparent',
						}}
					>
						Place Deduction
					</Text>
					<div
						style={{
							color: place_deduction <= 0 && 'transparent',
						}}
					>
						${(place_deduction / 100).toFixed(2)}
					</div>
				</StyledRaceSelectionsListItem__Deductions>
			</div>
		);
	};
	return (
		<React.Fragment>
			<StyledRaceSelectionsListItem
				id={id}
				className={componentClasses}
				//   onClick={toggleForm}
				// onClick={(e) => e.preventDefault()}
			>
				<StyledRaceSelectionsListItem__Wrapper open={showhotStatsOpen} size={size}>
					<ListItemWrapper
						style={{
							flexDirection: showhotStatsOpen ? 'column' : '',
						}}
						size={size}
						scratched={selection_status != SELECTION_NOT_SCRATCHED_STATUS}
					>
						<StyledRaceSelectionsListItem_selection_details id={id} onClick={toggleForm} size={size}>
							<RaceSelectionDetails
								number={number}
								name={name}
								silk={silk}
								jockey={jockey}
								trainer={trainer}
								barrier={barrier}
								weight={weight}
								allocation={allocation}
								last_starts_string={last_starts_string}
								selection_status={selection_status}
								type_code={type_code}
								size={size}
								hotSelection={hotSelection}
								is_jockey_hot={isJockHot}
								is_trainer_hot={isTrainerHot}
								id={id}
								betType={betType}
								price={price}
								tes={test}
								filter_trainer={testt}
								scratching_time={scratching_time}
								onClickTogleForm={toggleForm}
							/>
						</StyledRaceSelectionsListItem_selection_details>
						{selection_status === SELECTION_NOT_SCRATCHED_STATUS &&
							showFlucs_Chart &&
							selection_fluctuations.length > 0 &&
							!isMobileWidth &&
							!showhotStatsOpen && (
								<StyledRaceFlucs size={size} showhotStatsOpen={showhotStatsOpen}>
									<OpenRaceFlucs>
										<Text
											className={css`
												font-size: 9px !important;
											`}
											type="alternative"
										>
											Open
										</Text>
									</OpenRaceFlucs>

									{allflucs.map((item, index) => (
										<Text
											key={index + 'flucs' + item}
											size="-4"
											type="light"
											className={css`
												${item > allflucs[index - 1] ? 'color: green' : 'color: red'}
												${index === 0 && 'color: black;font-weight: 700;'}
											`}
										>
											{item}{' '}
											{index === 0 || item > allflucs[index - 1] ? (
												<ArrowIcon
													size="-5"
													direction="up"
													className={css`
														top: -3px;
													`}
												/>
											) : (
												<ArrowIcon size="-5" color="blue" direction="down" />
											)}
										</Text>
									))}
								</StyledRaceFlucs>
							)}
					</ListItemWrapper>

					{showFlucs_Chart && selection_fluctuations.length > 0 && betType !== 'OddGrid' > 0 && (
						<StyledRaceSelectionsListItem__ExtraColumns selection_status={selection_status} size={size}>
							{selection_status !== SELECTION_NOT_SCRATCHED_STATUS || betType === 'OddGrid' ? null : (
								<Button
									action={() =>
										ShowModle(fuDataGraph, name, number, silk, raceNumber, last_starts_string, trainer, jockey, weight)
									}
									name={selection_status}
									className={css`
										// background-color: transparent;
										text-align: end;
										box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20 %);
										transition: 0.3s;
										background-color: hsl(330deg 9 % 96 %);
										margin-right: 1 %;
									`}
								>
									<i className="fa fa-bar-chart fa-lg" aria-hidden="true" />
									<Text
										size="-4"
										type="light"
										align="right"
										className={css`
											display: none;
										`}
									>
										{show_fluctuation}
									</Text>
								</Button>
							)}
						</StyledRaceSelectionsListItem__ExtraColumns>
					)}

					{!isMobileWidth && showhotStatsOpen && betType !== RACING_ODD_GRID_BET_TYPE && (
						<HotStrikeRateGroup>
							<RaceStrikeRate
								selection_status={selection_status}
								jockey_horse_percentage={hot_stats.jockey_horse_w}
								jockey_percentage={<span>{hot_stats.jockey_w === '0' ? '0' : hot_stats.jockey_w}</span>}
								trainer_percentage={<span>{hot_stats.trainer_w === '0' ? '0' : hot_stats.trainer_w}</span>}
								jockey_trainer_percentage={
									<span>{hot_stats.jockey_trainer_w === '0' ? '0' : hot_stats.jockey_trainer_w}</span>
								}
								size={size}
							/>
							<RaceStrikeRate
								selection_status={selection_status}
								jockey_horse_percentage={hot_stats === null ? '' : hot_stats.jockey_horse_p}
								jockey_percentage={
									hot_stats === null ? '' : <span>{hot_stats.jockey_p === '0' ? '0' : hot_stats.jockey_p}</span>
								}
								trainer_percentage={
									hot_stats === null ? '' : <span>{hot_stats.trainer_p === '0' ? '0' : hot_stats.trainer_p}</span>
								}
								jockey_trainer_percentage={
									hot_stats === null ? (
										''
									) : (
										<span>{hot_stats.jockey_trainer_p === '0' ? '0' : hot_stats.jockey_trainer_p}</span>
									)
								}
								size={size}
							/>
						</HotStrikeRateGroup>
					)}

					{!isNaN(parseFloat(goatLength)) && selection_status === SELECTION_NOT_SCRATCHED_STATUS && (
						<StyledRaceSelectionsListItem__ExtraColumns selection_status={selection_status} size={size}>
							+{goatLength}L
						</StyledRaceSelectionsListItem__ExtraColumns>
					)}

					{showSelectWinFixedOldPrice && showSelectWinFixedOldPrice.length > 0 && selectionPriceBup && (
						<StyledShowOldPriceWhenPriceBump>
							<Text
								size="-3"
								className={css`
									text-decoration: line - through;
								`}
							>
								{selectionPriceBup.prices && selectionPriceBup.prices.win_odds.toFixed(2)}
							</Text>
						</StyledShowOldPriceWhenPriceBump>
					)}

					<ButtonsWrappers betType={betType} size={size}>
						<StyledPrices betType={betType} size={size} buttons={reducedSelectionButtons.length}>
							{betType !== 'OddGrid'
								? reducedSelectionButtons.map((betButton, index) => (
										<StyledPricesWithLiability
											key={`${betButton.index}_${betButton.product_id}_${betButton.product_code}_${betButton.bet_type} `}
										>
											<StyledRaceBettingAdd
												key={`${betButton.index}_${betButton.product_id}_${betButton.product_code}_${betButton.bet_type} `}
												small={isMobileWidth ? reducedSelectionButtons.length > 2 : reducedSelectionButtons.length > 4}
												price={
													betType === RACING_ODD_GRID_BET_TYPE ? (
														<span>
															<b>{betButton.price}</b>
															<p
																className={css`
																	font-size: 0.65rem;
																`}
															>
																{' '}
																{betButton.betButton.product_code}
															</p>
															<p
																className={css`
																	font-size: 0.65rem;
																`}
															>
																{' '}
																$ {betButton.liability}
															</p>
														</span>
													) : (
														betButton.price
													)
												}
												initialPrice={betButton.initialPrice}
												betType={betButton.bet_type}
												productId={betButton.product_id}
												selectionId={id}
												hasMulti={betButton.hasMulti}
												addToSingle={addToSingle}
												liability={`${
													betType === RACING_ODD_GRID_BET_TYPE && betButton.productAvailable
														? '$ ' + betButton.liability
														: ''
												} `}
												checkLibility={
													betType === RACING_ODD_GRID_BET_TYPE
														? betButton.liability === 0
															? reducedSelectionButtons[0].liability
															: betButton.liability
														: null
												}
												addToMulti={addToMulti}
												bettingAvailable={isAvailable(betButton)} // newly added && betType ===RACING_NON_EXOTIC_BET_TYPE
												priceRollups={betButton.priceRollups}
												emphasisedType={
													betButton.bet_type === RACING_BET_TYPE_MARGIN &&
													selection_status === SELECTION_NOT_SCRATCHED_STATUS
														? 'background'
														: betButton.product_type === GOAT_PRODUCT_TYPE_BOOST
														? 'background'
														: null
												}
												useWrapper={
													betButton.bet_type === RACING_BET_TYPE_MARGIN &&
													selection_status === SELECTION_NOT_SCRATCHED_STATUS
												}
												scratched={selection_status !== SELECTION_NOT_SCRATCHED_STATUS}
												displayedFlucs={displayedFlucs}
												showTote={showTote}
												size={size}
												className={betButtonStyle(betButton)}
												// selectionsSameRaceMulti={selectionsSameRaceMulti}
												// handleSameRaceMultiSelections={handleSameRaceMultiSelections}
												sameRaceMultiActive={selectionAndProductIsSelectedRaceMulti(betButton)}
												addSameRaceMulti={handleSameRaceMultiSelections}
												enabled_cache_out={enabled_cache_out}
											/>
											{hotSelection == id && betButton.bet_type === 'win' && betButton.product_id === 16 && (
												<StyledHotSelection_Fav_Icon count={betButton.product_id === 16}>
													Fav
												</StyledHotSelection_Fav_Icon>
											)}
										</StyledPricesWithLiability>
								  ))
								: reducedSelectionButtons
										.filter(
											(reducedSelectionButtons) =>
												reducedSelectionButtons.product_id === 19 ||
												reducedSelectionButtons.product_id === 20 ||
												reducedSelectionButtons.product_id === 21,
										)
										.map((betButton) => (
											<StyledPricesWithLiability
												key={`${betButton.index}_${betButton.product_id}_${betButton.product_code}_${betButton.bet_type} `}
											>
												<StyledRaceBettingAdd
													key={`${betButton.index}_${betButton.product_id}_${betButton.product_code}_${betButton.bet_type} `}
													small={
														isMobileWidth ? reducedSelectionButtons.length > 2 : reducedSelectionButtons.length > 4
													}
													price={
														<span>
															<b>{get_price(betButton)}</b>
															<p
																className={css`
																	font-size: 0.65rem;
																`}
															>
																{' '}
																$ {get_libility(betButton)}
															</p>
														</span>
													}
													initialPrice={betButton.initialPrice}
													betType={betButton.bet_type}
													productId={get_product_id(betButton)}
													selectionId={id}
													hasMulti={betButton.hasMulti}
													addToSingle={addToSingle}
													checkLibility={get_libility(betButton)}
													liability={`${
														betType === RACING_ODD_GRID_BET_TYPE && betButton.productAvailable
															? '$ ' + betButton.liability
															: ''
													} `}
													addToMulti={addToMulti}
													bettingAvailable={isAvailable(betButton)} // newly added && betType ===RACING_NON_EXOTIC_BET_TYPE
													priceRollups={betButton.priceRollups}
													emphasisedType={
														betButton.bet_type === RACING_BET_TYPE_MARGIN &&
														selection_status === SELECTION_NOT_SCRATCHED_STATUS
															? 'background'
															: betButton.product_type === GOAT_PRODUCT_TYPE_BOOST
															? 'background'
															: null
													}
													useWrapper={
														betButton.bet_type === RACING_BET_TYPE_MARGIN &&
														selection_status === SELECTION_NOT_SCRATCHED_STATUS
													}
													scratched={selection_status !== SELECTION_NOT_SCRATCHED_STATUS}
													displayedFlucs={displayedFlucs}
													showTote={showTote}
													size={size}
													className={
														betButton.product_id === 19
															? css`
																	background: #a6d8ff;
																	height: 35px;
															  `
															: null
													}
													enabled_cache_out={enabled_cache_out}
												/>
											</StyledPricesWithLiability>
										))}
							{isMobileWidth && renderDeductions()}
						</StyledPrices>
						{!isMobileWidth && renderDeductions()}
						{bettingAvailable && (
							<div
								className={css`
									display: flex;
									justify-content: flex - end;
								`}
							>
								{/*only show checkboxes for non scratched runners*/}
								{selection_status === SELECTION_NOT_SCRATCHED_STATUS &&
									(betType !== RACING_BET_TYPE_STRAIGHT_EIGHT ||
										boxed ||
										(betType === RACING_BET_TYPE_STRAIGHT_EIGHT && !isMobileWidth)) &&
									buildCheckboxes(checkBoxesDisplayed, checkBoxStatus)}
							</div>
						)}
					</ButtonsWrappers>
					{/* {selection_status != SELECTION_NOT_SCRATCHED_STATUS && (
						<div
							className={css`
								display: flex;
								width: 50%;
								justify-content: end;
								align-items: flex-end;
							`}
						>
							<StyledRaceSelectionsListItem__Deductions align="center" size="-4" type="danger">
								<Text
									size="-5"
									type="light"
									style={{
										color: win_deductions <= 0 && 'transparent',
									}}
								>
									Win Deduction
								</Text>
								<div
									style={{
										color: win_deductions <= 0 && 'transparent',
									}}
								>
									${(win_deductions / 100).toFixed(2)}
								</div>
							</StyledRaceSelectionsListItem__Deductions>

							<StyledRaceSelectionsListItem__Deductions align="center" size="-4" type="danger">
								<Text
									size="-5"
									type="light"
									style={{
										color: place_deduction <= 0 && 'transparent',
									}}
								>
									Place Deduction
								</Text>
								<div
									style={{
										color: place_deduction <= 0 && 'transparent',
									}}
								>
									${(place_deduction / 100).toFixed(2)}
								</div>
							</StyledRaceSelectionsListItem__Deductions>
						</div>
					)} */}

					{selection_status === SELECTION_NOT_SCRATCHED_STATUS &&
						showFlucs_Chart &&
						selection_fluctuations.length > 0 &&
						(isMobileWidth || showhotStatsOpen) && (
							<StyledRaceFlucs size={size} style={{ width: '100%' }} showhotStatsOpen={showhotStatsOpen}>
								<OpenRaceFlucs>
									<Text
										className={css`
											font-size: 9px !important;
										`}
										type="alternative"
									>
										Open
									</Text>
								</OpenRaceFlucs>

								{allflucs.map((item, index) => (
									<Text
										key={index + 'flucs' + item}
										size="-4"
										type="light"
										className={css`
											${item > allflucs[index - 1] ? 'color: green' : 'color: red'}
											${index === 0 && 'color: black;font-weight: 700;'}
										`}
									>
										{item}{' '}
										{index === 0 || item > allflucs[index - 1] ? (
											<ArrowIcon size="-4" direction="up" />
										) : (
											<ArrowIcon size="-4" color="blue" direction="down" />
										)}
									</Text>
								))}
							</StyledRaceFlucs>
						)}

					{isMobileWidth && showhotStatsOpen && (
						<div
							className={css`
								display: flex;
								flex-direction: row;
								gap: 4px;
								width: 100%;
							`}
						>
							<div
								className={css`
									display: flex;
									flex-direction: column;
								`}
							>
								<div
									className={css`
										display: flex;
										flex-direction: row;
										justify-content: flex-start;
										align-items: center;
									`}
								>
									<Text
										strong
										size="-3"
										align="center"
										className={css`
											width: 36px;
										`}
									>
										Win
									</Text>
									<RaceStrikeRate
										className={css`
											padding: 2px !important;
										`}
										isMobileWidth={isMobileWidth}
										selection_status={selection_status}
										jockey_horse_percentage={hot_stats.jockey_horse_w}
										jockey_percentage={<span>{hot_stats.jockey_w === '0' ? '0' : hot_stats.jockey_w}</span>}
										trainer_percentage={<span>{hot_stats.trainer_w === '0' ? '0' : hot_stats.trainer_w}</span>}
										jockey_trainer_percentage={
											<span>{hot_stats.jockey_trainer_w === '0' ? '0' : hot_stats.jockey_trainer_w}</span>
										}
										size={size}
									/>
								</div>
								<StyledRaceStrikeRate_header
									className={css`
										padding-left: 42px;
									`}
								>
									<StyledRaceStrikeRate_percentage_header>
										<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_JH')}</StyledRaceStrikeRate__Subtitle>
									</StyledRaceStrikeRate_percentage_header>
									<StyledRaceStrikeRate_percentage_header>
										<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_J')}</StyledRaceStrikeRate__Subtitle>
									</StyledRaceStrikeRate_percentage_header>

									<StyledRaceStrikeRate_percentage_header>
										<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_T')}</StyledRaceStrikeRate__Subtitle>
									</StyledRaceStrikeRate_percentage_header>

									<StyledRaceStrikeRate_percentage_header>
										<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_JT')}</StyledRaceStrikeRate__Subtitle>
									</StyledRaceStrikeRate_percentage_header>
								</StyledRaceStrikeRate_header>
							</div>

							<div
								className={css`
									display: flex;
									flex-direction: column;
								`}
							>
								<div
									className={css`
										display: flex;
										flex-direction: row;
										justify-content: flex-start;
										align-items: center;
									`}
								>
									<Text
										strong
										size="-3"
										align="center"
										className={css`
											width: 36px;
										`}
									>
										Place
									</Text>
									<RaceStrikeRate
										className={css`
											padding: 2px !important;
										`}
										isMobileWidth={isMobileWidth}
										selection_status={selection_status}
										jockey_horse_percentage={hot_stats === null ? '' : hot_stats.jockey_horse_p}
										jockey_percentage={
											hot_stats === null ? '' : <span>{hot_stats.jockey_p === '0' ? '0' : hot_stats.jockey_p}</span>
										}
										trainer_percentage={
											hot_stats === null ? '' : <span>{hot_stats.trainer_p === '0' ? '0' : hot_stats.trainer_p}</span>
										}
										jockey_trainer_percentage={
											hot_stats === null ? (
												''
											) : (
												<span>{hot_stats.jockey_trainer_p === '0' ? '0' : hot_stats.jockey_trainer_p}</span>
											)
										}
										size={size}
									/>
								</div>
								<StyledRaceStrikeRate_header
									className={css`
										padding-left: 42px;
									`}
								>
									<StyledRaceStrikeRate_percentage_header>
										<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_JH')}</StyledRaceStrikeRate__Subtitle>
									</StyledRaceStrikeRate_percentage_header>
									<StyledRaceStrikeRate_percentage_header>
										<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_J')}</StyledRaceStrikeRate__Subtitle>
									</StyledRaceStrikeRate_percentage_header>

									<StyledRaceStrikeRate_percentage_header>
										<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_T')}</StyledRaceStrikeRate__Subtitle>
									</StyledRaceStrikeRate_percentage_header>

									<StyledRaceStrikeRate_percentage_header>
										<StyledRaceStrikeRate__Subtitle>{t('RaceCard__StrikeRate_JT')}</StyledRaceStrikeRate__Subtitle>
									</StyledRaceStrikeRate_percentage_header>
								</StyledRaceStrikeRate_header>
							</div>
						</div>
					)}
				</StyledRaceSelectionsListItem__Wrapper>

				{/* Don't render the form unless the form is opened. This is to optimize the mount speed for the race selections list item*/}
				{formOpened && (
					<CollapsibleContent
						isCollapsed={!formOpened}
						className={css`
							flex: 1 0 100 %;
						`}
					>
						{/* Disabled Runner Scrached Form Date*/}
						{/* Removal of the exclusion of the collapsible in others forms in case it is need again just && (betType === RACING_NON_EXOTIC_BET_TYPE || betType === RACING_ODD_GRID_BET_TYPE) */}
						{selection_status === SELECTION_NOT_SCRATCHED_STATUS && sppedmapOpen !== true
							? runner && (
									<SizeMe>
										{({ size }) => (
											<RunnerForm
												{...runner}
												MettingName={MettingName}
												runner={runner}
												comment={comment}
												size={size}
												user={user}
												type_code={type_code}
												raceNumber={raceNumber}
												StartDate={StartDate}
												country={country}
												prize_money={prize_money}
												handleAddToBackBook={handleAddToBackBook}
												backBookResponse={backBookResponse}
												isMobileWidth={isMobileWidth}
											/>
										)}
									</SizeMe>
							  )
							: null}
					</CollapsibleContent>
				)}
			</StyledRaceSelectionsListItem>

			{/* <ListItem>
				<StyledRaceSelectionsListItem__CollapsibleExoticSection
					isCollapsed={
						betType !== RACING_BET_TYPE_STRAIGHT_EIGHT ||
						boxed ||
						(betType === RACING_BET_TYPE_STRAIGHT_EIGHT && !isMobileWidth)
					}
					className={css`
						flex: 1 0 100 %;
					`}
				>
					<Tabs emphasised justify>
						{Object.keys(checkBoxesDisplayed).map((key) => (
							<TabsItem
								key={key}
								active={checkBoxStatus[key]}
								action={toggleCheckboxTab}
								data={key}
								disabled={selection_status !== SELECTION_NOT_SCRATCHED_STATUS}
								pointer
							>
								{numeral(key).format('0o')}
							</TabsItem>
						))}
					</Tabs>
				</StyledRaceSelectionsListItem__CollapsibleExoticSection>
			</ListItem> */}
		</React.Fragment>
	);
});

RaceSelectionsListItem.propTypes = {
	/** The size of the component - used for media query logic */
	size: PropTypes.shape({
		width: PropTypes.number,
		height: PropTypes.number,
	}).isRequired,

	/** Action to handle the selection being selected for a single bet */
	addToSingle: PropTypes.func.isRequired,

	/** Action to handle the selection being selected for a multi bet */
	addToMulti: PropTypes.func.isRequired,

	/** Boolean indicating if betting is available ie. race isn't closed */
	bettingAvailable: PropTypes.bool.isRequired,

	/** Function to handle the toggling of state for each checkbox */
	handleCheckboxChange: PropTypes.func.isRequired,

	/** Function to handle the toggling of state for the selection item's form */
	toggleForm: PropTypes.func.isRequired,

	/** The currently selected bet type */
	betType: PropTypes.string,

	/**
	 * This is a code for the type of race it is
	 * ie. T - Thoroughbred | G - Greyhounds | H - Harness
	 */
	type_code: PropTypes.oneOf(RACING_TYPE_CODES).isRequired,

	/**
	 * This will be an array of bet buttons that will be displayed for each selection
	 * The order of display will be from left to right as given by the array.
	 */
	selectionBetButtons: PropTypes.arrayOf(
		PropTypes.shape({
			/** The ID of the product */
			product_id: PropTypes.number.isRequired,
			/** The code of the product */
			product_code: PropTypes.string.isRequired,
			/** The type of bet */
			bet_type: PropTypes.string.isRequired,
			/** The odds/price for the bet */
			price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			/** If a multi bet is available for this product */
			hasMulti: PropTypes.bool,
			/** If the product is available to be bet on */
			productAvailable: PropTypes.bool,

			liability: PropTypes.string.isRequired,
		}),
	).isRequired,

	/** The following props are race selection details which are pretty much what the name suggests! */
	/** Selections race number */
	id: PropTypes.number.isRequired,

	/** Selections race number */
	number: PropTypes.number.isRequired,

	/** Selections name */
	name: PropTypes.string.isRequired,

	/** Whether or not the component is being viewed in small context */
	isMobileWidth: PropTypes.bool,

	/** Runner selection comment */
	comment: PropTypes.string,

	/** Selections race silk */
	silk: PropTypes.string,

	/** Selections race jockey or driver */
	jockey: PropTypes.string,

	/** Selections trainer */
	trainer: PropTypes.string,

	jockey_horse_p: PropTypes.string,

	jockey_horse_w: PropTypes.string,

	is_jockey_hot: PropTypes.string,

	jockey_p: PropTypes.string,

	jockey_w: PropTypes.string,

	jockey_trainer_p: PropTypes.string,

	jockey_trainer_w: PropTypes.string,

	trainer_p: PropTypes.string,

	trainer_w: PropTypes.string,

	is_trainer_hot: PropTypes.string,

	showPlaceHotStats: PropTypes.bool,

	/** Selections race barrier number */
	barrier: PropTypes.number,

	/** Selections race weight or handicap */
	weight: PropTypes.number,

	/** A string of the selections last race starts placings (including spells) */
	last_starts_string: PropTypes.string,

	/** A string of flucs */
	apn_fluctuations: PropTypes.string,

	/** A string of flucs */
	topbetta_fluctuations: PropTypes.string,

	/** Win deductions in cents */
	win_deductions: PropTypes.number,

	/** Place deductions in cents */
	place_deduction: PropTypes.number,

	/** If the currently selected bet type is boxed */
	boxed: PropTypes.bool,

	/**
	 * This status is used to determine if the race selection has been scratched or late scratched
	 * and if so will render with a new class to visually convey this to the user
	 */
	selection_status: PropTypes.oneOf(SELECTION_STATUSES),

	/** Runner contains the selections form stats and last starts */
	runner: PropTypes.shape(),

	hot_stats: PropTypes.shape(),

	/** Which fluctuations to display, or don't display if null */
	displayedFlucs: PropTypes.oneOf([null, 'apn_fluctuations', 'topbetta_fluctuations']),

	/** Define whether the runner form is open and showing or collapsed */
	formOpened: PropTypes.bool,

	/** This is an array of which checkboxes should be displayed */
	checkBoxesDisplayed: PropTypes.shape({
		1: PropTypes.bool,
		2: PropTypes.bool,
		3: PropTypes.bool,
		4: PropTypes.bool,
		5: PropTypes.bool,
		6: PropTypes.bool,
		7: PropTypes.bool,
		8: PropTypes.bool,
	}),

	/** This holds an object containing the status of each selection checkbox (ie. if checked or not) */
	checkBoxStatus: PropTypes.shape({
		1: PropTypes.bool,
		2: PropTypes.bool,
		3: PropTypes.bool,
		4: PropTypes.bool,
		5: PropTypes.bool,
		6: PropTypes.bool,
		7: PropTypes.bool,
		8: PropTypes.bool,
	}),

	/** Length value */
	goatLength: PropTypes.number,

	/** Extra class(es) to pass through to the component */
	className: PropTypes.string,

	jockey_horse_percentage: PropTypes.string,

	jockey_percentage: PropTypes.string,

	trainer_percentage: PropTypes.string,

	jockey_trainer_percentage: PropTypes.string,

	hotSelection: PropTypes.number,

	/** Do we need to open hot selection form collapsible panels */
	showhotStatsOpen: PropTypes.bool,

	showFlucs_Chart: PropTypes.bool,

	selection_fluctuations: PropTypes.array,

	showVerifyExplanation: PropTypes.bool,

	selectedGoatProduct: PropTypes.string,

	showSelectWinFixedOldPrice: PropTypes.array,
};

RaceSelectionsListItem.defaultProps = {
	betType: '',
	boxed: false,
	isMobileWidth: false,
	comment: null,
	silk: null,
	jockey: null,
	trainer: null,
	jockey_horse_p: null,
	jockey_horse_w: null,
	jockey_p: null,
	jockey_w: null,
	jockey_trainer_p: null,
	jockey_trainer_w: null,
	showPlaceHotStats: false,
	trainer_p: null,
	trainer_w: null,
	is_jockey_hot: null,
	is_trainer_hot: null,
	barrier: null,
	weight: null,
	last_starts_string: null,
	apn_fluctuations: null,
	topbetta_fluctuations: null,
	selection_status: null,
	win_deductions: 0,
	place_deduction: 0,
	runner: null,
	hot_stats: null,
	fluctuations: null,
	displayedFlucs: null,
	formOpened: false,
	checkBoxesDisplayed: DEFAULT_EXOTIC_CHECKBOX_STATE,
	checkBoxStatus: DEFAULT_EXOTIC_CHECKBOX_STATE,
	selectionBetButtons: [
		{
			price: null,
			hasMulti: false,
			productAvailable: true,
		},
	],
	goatLength: null,
	className: null,

	jockey_horse_percentage: null,
	jockey_percentage: null,
	trainer_percentage: null,
	jockey_trainer_percentage: null,
	hotSelection: null,
	showhotStatsOpen: false,
	showFlucs_Chart: false,
	selection_fluctuations: [],
	showVerifyExplanation: false,
	selectedGoatProduct: null,
	showSelectWinFixedOldPrice: [],
};
export default RaceSelectionsListItem;
